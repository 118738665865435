import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { PAGE_SIZE } from "../../constants/constants";

class Pagination extends Component {
  render() {
    const { totalSize, handlePageClick, currentPage, currentSize } = this.props;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <ReactPaginate
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          breakLabel="..."
          nextLabel="&raquo;"
          previousLabel="&laquo;"
          forcePage= {Math.min(currentPage, totalSize - 1)}
          onPageChange={selectedItem => {
            const adjustedPageNumber = selectedItem.selected + 1;
            handlePageClick(adjustedPageNumber);
          }}
          pageRangeDisplayed={PAGE_SIZE}
          pageCount={Math.ceil(totalSize / currentSize)}
          renderOnZeroPageCount={null}
        />
      </div>
    );
  }
}

export default Pagination;
