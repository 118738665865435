import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { countries } from "countries-list";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { formKey, settlementSubFormKey } from "constants/constants";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";

const validationSchema = Yup.object().shape({
  userName: Yup.string().required("Required"),
  userPassword: Yup.string().required("Required"),
  referrenceNote: Yup.string().required("Required"),
});

const initialValues = {};

class Ircc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      initialValues: {
        userName: "",
        userPassword: "",
        referrenceNote: "",
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SETTLEMENT &&
          note.subForm === settlementSubFormKey.IRCC &&
          (!latestNote || new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    this.setState({
      initialValues: {
        userName: clientData?.settlement?.irccPortal?.username || "",
        userPassword: clientData?.settlement?.irccPortal?.password || "",
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddSettlementIrccInfo = async data => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/settlement/ircc/${clientData?.id}`,
        data
      );
      if (response?.status) {
        toast.success(
          response?.message || "IRCC information stored successfully!"
        );
        handleGetClient();
        toggle("4");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error handleAddSettlementIrccInfo: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      irccPortalInfo: {
        username: values?.userName,
        password: values?.userPassword,
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddSettlementIrccInfo(data);
  };

  render() {
    const { initialValues, showPassword } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>IRCC Portal</h4>
                        </Col>
                        <Col md="9">
                          <div>
                            <div className="d-flex mb-3">
                              <div className="col-md-5 me-3">
                                <Label for="userName" className="form-label">
                                  Username
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  id="userName"
                                  name="userName"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.userName && touched?.userName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                />
                                <ErrorMessage
                                  name="userName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              {/* <div className="col-md-5 me-3">
                                <Label
                                  for="userPassword"
                                  className="form-label"
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  id="userPassword"
                                  name="userPassword"
                                  type="password"
                                  className={
                                    "form-control" +
                                    (errors?.userPassword &&
                                    touched?.userPassword
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                />
                                <ErrorMessage
                                  name="userPassword"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div> */}
                              <div className="col-md-4 me-3">
                                <Label
                                  for="userPassword"
                                  className="form-label"
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Field
                                    id="userPassword"
                                    name="userPassword"
                                    type={showPassword ? "text" : "password"}
                                    className={
                                      "form-control" +
                                      (errors?.userPassword &&
                                      touched?.userPassword
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                  />
                                  <button
                                    className="btn btn-light "
                                    type="button"
                                    id="password-addon"
                                    onClick={() =>
                                      this.setState({
                                        showPassword: !showPassword,
                                      })
                                    }
                                  >
                                    {showPassword ? (
                                      <i className="mdi mdi-eye-outline"></i>
                                    ) : (
                                      <i className="mdi mdi-eye-off-outline"></i>
                                    )}
                                  </button>
                                  <ErrorMessage
                                    name="userPassword"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                              max={new Date().toLocaleDateString("en-CA", {
                                timeZone: "America/Toronto",
                              })}
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Ircc));
