import React, { Component } from "react";
import {
  getDataFromSessionStorage,
  parseJwt,
} from "../../helpers/common_helpers";
// import Api from "../../services/profile";
import { withRouter } from "react-router-dom";
import { axiosGet } from "helpers/api_helpers";

export const UserContext = React.createContext({});

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      userData: "",
      isLoading: true,
      departments: [],
    };
  }

  getUser = async (id, callback) => {
    try {
      const users = await axiosGet(`users/${id}`);

      if (users?.status) {
        this.setState({ userData: users.data });
        if (callback) callback(users.data); // Call the callback function with user data
      }

      this.setState({ isLoading: false });
    } catch (error) {
      console.error("error while getting user :", error);
      this.setState({ error, isLoading: false });
    }
  };

  getUserinfo = async () => {
    try {
      const token = getDataFromSessionStorage("accessToken");

      if (token) {
        const userinfo = parseJwt(token);
        this.setState({ user: userinfo });
        await this.getUser(userinfo?.sub);
        this.handleGetAllDepartments();
        // this.setState({ userData: info?.data?.user });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (err) {
      if (err === "Please authenticate") {
        sessionStorage.clear();
        this.props.history.push("/login");
      }
    }
  };

  handleGetAllDepartments = async () => {
    try {
      const response = await axiosGet(`/department`);
      if (response?.status) {
        this.setState({
          departments: response?.data?.results,
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("error at handleGetAllOpts :", error);
    }
  };

  componentDidMount() {
    this.getUserinfo();
  }

  render() {
    const { user, userData, isLoading, departments } = this.state;
    return (
      <UserContext.Provider
        value={{
          user,
          getUser: this.getUser,
          getUserinfo: this.getUserinfo,
          getDepartments: this.handleGetAllDepartments,
          setUser: newUser => this.setState({ user: newUser }),
          userData,
          departments,
          setUserdata: newUserData => this.setState({ userData: newUserData }),
        }}
      >
        {isLoading ? <></> : this.props.children}
      </UserContext.Provider>
    );
  }
}

export default withRouter(UserProvider);
