import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { countries } from "countries-list";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { formKey, shelterSubFormKey } from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import formatDateToYYYYMMDD from "utils/formatDate";

const validationSchema = Yup.object().shape({
  arrivalFunds: Yup.number().required("Required"),
  roomAssignment: Yup.string().required("Required"),
  mailBoxAssignment: Yup.string().required("Required"),
  basicOrientation: Yup.boolean().required("Required"),
  moveInDate: Yup.date().required("Required"),
  moveOutDate: Yup.date().required("Required"),
  referenceNote: Yup.string().optional(),
});

const initialValues = {};

class Intake extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        arrivalFunds: "",
        roomAssignment: "",
        mailBoxAssignment: "",
        basicOrientation: false,
        moveInDate: "",
        moveOutDate: "",
        referenceNote: "",
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;

    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SHELTER &&
          note.subForm === shelterSubFormKey.INTAKE &&
          (!latestNote || new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    this.setState({
      initialValues: {
        arrivalFunds: clientData?.shelter?.intake?.arrivalFunds || "",
        roomAssignment: clientData?.shelter?.intake?.roomAssignment || "",
        mailBoxAssignment: clientData?.shelter?.intake?.mailBoxAssignment || "",
        basicOrientation: clientData?.shelter?.intake?.basicOrientation || false,
        moveInDate: formatDateToYYYYMMDD(clientData?.shelter?.intake?.moveInDate) || "",
        moveOutDate: formatDateToYYYYMMDD(clientData?.shelter?.intake?.moveOutDate) || "",
        referenceNote: refNote || "",
      },
    });
  };

  handleAddShelterIntakeData = async intakeInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } = this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/intake/${clientData?.id}`,
        intakeInfo
      );
      if (response?.status) {
        toast.success(response?.message || "Intake information stored successfully!");
        handleGetClient();
        toggle("2");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };
  
  handleSubmit = async values => {
    const { clientData } = this.props;
    const data = {
      intakeInfo: {
        arrivalFunds: values?.arrivalFunds,
        roomAssignment: values?.roomAssignment,
        mailBoxAssignment: values?.mailBoxAssignment,
        basicOrientation: values?.basicOrientation,
        moveInDate: values?.moveInDate,
        moveOutDate: values?.moveOutDate,
        followUpStatus: clientData?.shelter?.intake?.followUpStatus || "",
        departureStatus: clientData?.shelter?.intake?.departureStatus || ""
      },
      ...(values?.referenceNote && { referenceNotes: values?.referenceNote })
    };
    
    this.handleAddShelterIntakeData(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Intake Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="arrivalFunds" className="form-label">
                                Arrival/Assignment Funds
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="arrivalFunds"
                                name="arrivalFunds"
                                type="number"
                                className={
                                  "form-control" +
                                  (errors?.arrivalFunds && touched?.arrivalFunds
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name="arrivalFunds"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label
                                for="roomAssignment"
                                className="form-label"
                              >
                                Room Assignment
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="roomAssignment"
                                name="roomAssignment"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.roomAssignment &&
                                  touched?.roomAssignment
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Room Assignment"
                              />
                              <ErrorMessage
                                name="roomAssignment"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label
                                for="mailBoxAssignment"
                                className="form-label"
                              >
                                Mailbox Assignment
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="mailBoxAssignment"
                                name="mailBoxAssignment"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.mailBoxAssignment &&
                                  touched?.mailBoxAssignment
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Mailbox Assignment"
                              />
                              <ErrorMessage
                                name="mailBoxAssignment"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label
                                for="basicOrientation"
                                className="form-label"
                              >
                                Basic Orientation
                              </Label>
                              <div
                                aria-labelledby="basicOrientation"
                                className="form-check mt-2"
                              >
                                <label className="me-5">
                                  <Field
                                    id="basicOrientation"
                                    name="basicOrientation"
                                    type="checkbox"
                                    className={
                                      "form-check-input" +
                                      (errors?.basicOrientation &&
                                      touched?.basicOrientation
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  Basic Orientation
                                </label>
                              </div>
                              <ErrorMessage
                                name="basicOrientation"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="moveInDate" className="form-label">
                                Move in Date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="moveInDate"
                                name="moveInDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.moveInDate && touched?.moveInDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter move-in date"
                              />
                              <ErrorMessage
                                name="moveInDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label for="moveOutDate" className="form-label">
                                Move out date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="moveOutDate"
                                name="moveOutDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.moveOutDate && touched?.moveOutDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter move-out date"
                              />
                              <ErrorMessage
                                name="moveInDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referenceNote" className="form-label">
                              Reference Note
                            </Label>
                            <Field
                              id="referenceNote"
                              name="referenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referenceNote && touched?.referenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Intake));
