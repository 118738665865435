import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { countries } from "countries-list";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { formKey, shelterSubFormKey } from "constants/constants";
import formatDateToYYYYMMDD from "utils/formatDate";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  initialFood: Yup.number().required("Required"),
  clothing: Yup.date().required("Required"),
  referredDate: Yup.date().required("Required"),
  referredTo: Yup.string().required("Required"),
  referrenceNote: Yup.string().optional(),
  giftCards: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().required("Required"),
      date: Yup.date().required("Required"),
    })
  ),
  foodBanks: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().required("Required"),
      date: Yup.date().required("Required"),
    })
  ),
});

class BasicNeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        initialFood: "",
        clothing: "",
        referredDate: "",
        referredTo: "",
        referrenceNote: "",
        giftCards: [{ amount: "", date: "" }],
        foodBanks: [{ amount: "", date: "" }],
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;

    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SHELTER &&
          note.subForm === shelterSubFormKey.BASIC_NEEDS &&
          (!latestNote || new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    this.setState({
      initialValues: {
        initialFood: clientData?.shelter?.basicNeeds?.initialFood || 0,
        clothing: formatDateToYYYYMMDD(clientData?.shelter?.basicNeeds?.clothing) || "",
        referredDate:
          formatDateToYYYYMMDD(clientData?.shelter?.basicNeeds?.referredDate) ||
          "",
        referredTo: clientData?.shelter?.basicNeeds?.referredTo || "",
        giftCards:
          clientData?.shelter?.basicNeeds?.giftCards?.length > 0 ?
          clientData?.shelter?.basicNeeds?.giftCards?.map(giftCard => {
            return {
              amount: giftCard?.amount || "",
              date: formatDateToYYYYMMDD(giftCard?.date) || "",
            };
          }) : [{ amount: "", date: "" }],
        foodBanks:
          clientData?.shelter?.basicNeeds?.foodBanks?.length > 0 ?
          clientData?.shelter?.basicNeeds?.foodBanks?.map(foodBank => {
            return {
              amount: foodBank?.amount || "",
              date: formatDateToYYYYMMDD(foodBank?.date) || "",
            };
          }) : [{ amount: "", date: "" }],
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddBasicNeedsData = async basicNeedsInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/basicNeeds/${clientData?.id}`,
        basicNeedsInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Basic needs information stored successfully!"
        );
        handleGetClient();
        toggle("3");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      basicNeedsInfo: {
        initialFood: values?.initialFood,
        clothing: values?.clothing,
        referredDate: values?.referredDate,
        referredTo: values?.referredTo,
        giftCards: values?.giftCards || [],
        foodBanks: values?.foodBanks || [],
      },
      ...(values?.referrenceNote && { referenceNotes: values?.referrenceNote })
    };
    this.handleAddBasicNeedsData(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched, values, setFieldValue, dirty }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Food & Clothes</h4>
                        </Col>
                        <Col md="9">
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="initialFood" className="form-label">
                                Initial Food Amount
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="initialFood"
                                name="initialFood"
                                type="number"
                                className={
                                  "form-control" +
                                  (errors?.initialFood && touched?.initialFood
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name="initialFood"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label for="clothing" className="form-label">
                                Clothing
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="clothing"
                                name="clothing"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.clothing && touched?.clothing
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                max={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="clothing"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <FieldArray name="giftCards">
                              {({ insert, remove, push }) => (
                                <div>
                                  {values?.giftCards?.length > 0 &&
                                    values?.giftCards?.map(
                                      (giftCard, index) => (
                                        <div key={index} className="d-flex">
                                          <div className="col-md-4 mb-3 me-3">
                                            <label
                                              htmlFor={`giftCardAmount${index}`}
                                              className="form-label"
                                            >
                                              Gift Card Amount
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              id={`giftCardAmount${index}`}
                                              name={`giftCards.${index}.amount`}
                                              type="number"
                                              className={
                                                "form-control" +
                                                (errors?.giftCards &&
                                                errors?.giftCards[index] &&
                                                errors?.giftCards[index]
                                                  ?.amount &&
                                                touched?.giftCards &&
                                                touched?.giftCards[index] &&
                                                touched?.giftCards[index]
                                                  ?.amount
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              name={`giftCards.${index}.amount`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>

                                          <div className="col-md-4 mb-3">
                                            <label
                                              htmlFor={`giftCardDate${index}`}
                                              className="form-label"
                                            >
                                              Date
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              id={`giftCardDate${index}`}
                                              name={`giftCards.${index}.date`}
                                              placeholder="Enter date"
                                              type="date"
                                              className={
                                                "form-control" +
                                                (errors?.giftCards &&
                                                errors?.giftCards[index] &&
                                                errors?.giftCards[index]
                                                  ?.date &&
                                                touched?.giftCards &&
                                                touched?.giftCards[index] &&
                                                touched?.giftCards[index]?.date
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name={`giftCards.${index}.date`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                          <div className="d-flex align-items-center ms-3 mt-2">
                                            <button
                                              type="button"
                                              className="btn btn-danger p-1"
                                              onClick={() => remove(index)}
                                            >
                                              <i className="fs-5 bx bx-trash-alt"></i>
                                            </button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      push({ amount: "", date: "" })
                                    }
                                  >
                                    Add Gift Card
                                  </button>
                                </div>
                              )}
                            </FieldArray>
                          </div>

                          <div className="mb-3">
                            <FieldArray name="foodBanks">
                              {({ insert, remove, push }) => (
                                <div>
                                  {values?.foodBanks?.length > 0 &&
                                    values?.foodBanks?.map(
                                      (giftCard, index) => (
                                        <div key={index} className="d-flex">
                                          <div className="col-md-4 mb-3 me-3">
                                            <label
                                              htmlFor={`foodBankAmount${index}`}
                                              className="form-label"
                                            >
                                              Food Bank Trip Amount
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              id={`foodBankAmount${index}`}
                                              name={`foodBanks.${index}.amount`}
                                              type="number"
                                              className={
                                                "form-control" +
                                                (errors?.foodBanks &&
                                                errors?.foodBanks[index] &&
                                                errors?.foodBanks[index]
                                                  ?.amount &&
                                                touched?.foodBanks &&
                                                touched?.foodBanks[index] &&
                                                touched?.foodBanks[index]
                                                  ?.amount
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              name={`foodBanks.${index}.amount`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>

                                          <div className="col-md-4 mb-3">
                                            <label
                                              htmlFor={`foodBankDate${index}`}
                                              className="form-label"
                                            >
                                              Date
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              id={`foodBankDate${index}`}
                                              name={`foodBanks.${index}.date`}
                                              placeholder="Enter date"
                                              type="date"
                                              className={
                                                "form-control" +
                                                (errors?.foodBanks &&
                                                errors?.foodBanks[index] &&
                                                errors?.foodBanks[index]
                                                  ?.date &&
                                                touched?.foodBanks &&
                                                touched?.foodBanks[index] &&
                                                touched?.foodBanks[index]?.date
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name={`foodBanks.${index}.date`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                          <div className="d-flex align-items-center mt-2 ms-3">
                                            <button
                                              type="button"
                                              className="btn btn-danger p-1"
                                              onClick={() => remove(index)}
                                            >
                                              <i className="fs-5 bx bx-trash-alt"></i>
                                            </button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      push({ amount: "", date: "" })
                                    }
                                  >
                                    Add Food Bank
                                  </button>
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </Col>
                      </Row>
                      <hr />

                      <Row>
                        <Col md="3">
                          <h4>Medical Assesment</h4>
                        </Col>
                        <Col md="9">
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="referredDate" className="form-label">
                                Referred Date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="referredDate"
                                name="referredDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.referredDate && touched?.referredDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                max={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="referredDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label for="referredTo" className="form-label">
                                Referred To
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="referredTo"
                                name="referredTo"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.referredTo && touched?.referredTo
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                max={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="referredTo"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Reference Note
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                              max={new Date().toLocaleDateString("en-CA", {
                                timeZone: "America/Toronto",
                              })}
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BasicNeeds));
