import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { setShowPageLoader } from "store/actions";
import { connect } from "react-redux";
import Referrals from "./Referrals";
import Workshops from "./Workshops";
import FollowUps from "./FollowUps";
import DayCampProgram from "./DayCampProgram";
import ChristmasIntake from "./ChritmasIntake";
import { axiosGet } from "helpers/api_helpers";

class OrientationForm extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      open: "1",
      clientData: {},
    };
  }

  componentDidMount = () => {
    this.handleGetClientData();
  };

  toggle = id => {
    if (this.state.open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  };

  handleGetClientData = async () => {
    const { match, showPageLoader } = this.props;
    const clientId = match?.params?.clientId;

    try {
      showPageLoader(true);
      if (clientId) {
        const response = await axiosGet(`/add-client/screening/${clientId}`);
        if (response?.status) {
          const getClientData = response?.data;
          this.setState({ clientData: getClientData }, () => {});
        }
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("handleGetScreeningData API error", error);
    }
  };

  render() {
    //meta title
    document.title = "Form | Orientation";

    const { clientData } = this.state;
    const clientId = this.props.match?.params?.clientId;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Form" breadcrumbItem="Orientation" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-end mb-3">
                      <Link to="/department/orientation">
                        <button className="btn btn-primary">Back</button>
                      </Link>
                    </div>
                    {clientId && Object.values(clientData).length ? (
                    <div>
                      <Accordion
                        flush
                        open={[this.state.open]}
                        toggle={this.toggle}
                      >
                        <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                          <AccordionHeader targetId="1">Workshops</AccordionHeader>
                          <AccordionBody accordionId="1">
                            <Workshops 
                            clientData={clientData}
                            handleGetClient={this.handleGetClientData}
                            toggle={id => this.toggle(id)}
                            />
                          </AccordionBody>
                        </AccordionItem>
                        {/* <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                          <AccordionHeader targetId="2">Follow-ups</AccordionHeader>
                          <AccordionBody accordionId="2">
                            <FollowUps />
                          </AccordionBody>
                        </AccordionItem> */}
                        <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                          <AccordionHeader targetId="2">Referrals</AccordionHeader>
                          <AccordionBody accordionId="2">
                            <Referrals 
                            clientData={clientData}
                            handleGetClient={this.handleGetClientData}
                            toggle={id => this.toggle(id)}
                            />
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                          <AccordionHeader targetId="3">Day Camp Program</AccordionHeader>
                          <AccordionBody accordionId="3">
                            <DayCampProgram 
                            clientData={clientData}
                            handleGetClient={this.handleGetClientData}
                            toggle={id => this.toggle(id)}
                            />
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                          <AccordionHeader targetId="4">Christmas Intake</AccordionHeader>
                          <AccordionBody accordionId="4">
                            <ChristmasIntake 
                              clientData={clientData}
                            handleGetClient={this.handleGetClientData}
                            toggle={id => this.toggle(id)}
                            />
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </div>
                     ): null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

const mapStateToProps = ({ user }) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrientationForm));
