import React, { Component } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import { formKey, orientationSubFormKey, sizeMeasureOptions } from "constants/constants";
import { setShowPageLoader } from "store/actions";
import { connect } from "react-redux";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";

const validationSchema = Yup.object().shape({
  familyMembers: Yup.array().of(
    Yup.object().shape({
      shirtSize: Yup.number().required("Required"),
      pantSize: Yup.number().required("Required"),
      interests: Yup.string().required("Required"),
    })
  ),
  addressLine1: Yup.string().required("Required"),
  province: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  postalCode: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

class ChristmasIntake extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
  }

  componentDidMount = async () => {
    const { clientData } = this.props;

    try {
      const intakeData = clientData?.intakeForm?.personalInformation;
      const generalMembers = clientData?.screeningForm?.generalInformation?.members || [];

      if (intakeData) {
        const leadClientData = {
          uci: intakeData.uci,
          name: intakeData.leadMemberName
        };
  
        let memberInfo = intakeData?.members?.map(member => ({
          uci: member.uci,
          name: `${member.firstName} ${member.middleName} ${member.lastName}`
        }));
  
        // Update memberInfo by matching uci with generalMembers
        if (generalMembers.length !== memberInfo.length) {
          memberInfo = memberInfo.filter(member =>
            generalMembers.some(genMember => genMember.uci === member.uci)
          );
        }
        const allMember = [leadClientData, ...memberInfo]
   
        this.setState({ allMembers: allMember });
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };


  handleAddOrientationChristmasIntake = async christmasIntakeInfo => {
    const {
      clientData,
      setShowPageLoader,
      toggle,
      handleGetClient
    } = this.props
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/orientation/christmasIntake/${clientData?.id}`,
        christmasIntakeInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Legal aid information stored successfully!"
        );
        handleGetClient();
        toggle("4");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      christmasIntakeInfo: {
        members: values.familyMembers.map(member => ({
          uci: member.uci,
          name: member.name,
          shirtSize: member.shirtSize,
          shirtSizeMeasure: member.shirtSizeMeasure,
          pantSize: member.pantSize,
          pantSizeMeasure: member.pantSizeMeasure,
          interests: member.interests,
        })),
        address: {
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          province: values.province,
          city: values.city,
          country: values.country,
          postalCode: values.postalCode,
        },
        phoneNumber: values.phoneNumber,
        email: values.email,
        shareInfo: values.shareInfo,
        deliverItems: values.deliverItems
      },
      referenceNotes: values.referenceNote
    };
    this.handleAddOrientationChristmasIntake(data);
  }
  mergeMemberData = (orientationData, allMembers) => {
    const mergedMembers = allMembers.map(memberData => {
      const orientationDataMembers = orientationData.find(member => member.uci === memberData.uci);
      return {
        uci: memberData.uci,
        name: memberData.name,
        shirtSize: orientationDataMembers?.shirtSize || "",
        shirtSizeMeasure: orientationDataMembers?.shirtSizeMeasure || "",
        pantSize: orientationDataMembers?.pantSize || "",
        pantSizeMeasure: orientationDataMembers?.pantSizeMeasure || "",
        interests: orientationDataMembers?.interests || "",
      }
    });
    return mergedMembers
  }
  render() {
    const { allMembers } = this.state

    const { clientData } = this.props
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.ORIENTATION &&
          note.subForm === orientationSubFormKey.CHRISTMASINTAKE &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const orientationData = clientData?.orientation?.christmasIntake;
    const intakeData = clientData?.intakeForm?.personalInformation;
   
    const initialValues = {
      familyMembers: orientationData?.members ? this.mergeMemberData(orientationData.members, allMembers)
        : allMembers.map(member => ({
          uci: member.uci,
          name: member.name,
          shirtSize: "",
          pantSize: "",
          interests: "",
          shirtSizeMeasure: "",
          pantSizeMeasure: "",
        })),
      addressLine1: intakeData?.mailAddress?.addressLine1 || "",
      addressLine2: intakeData?.mailAddress?.addressLine2 || "",
      province: intakeData?.mailAddress?.province || "",
      city: intakeData?.mailAddress?.city || "",
      country: intakeData?.mailAddress?.country || "",
      postalCode: intakeData?.mailAddress?.postalCode || "",
      phoneNumber: intakeData?.phoneNumber || "",
      email: intakeData?.email || "",
      shareInfo: orientationData?.shareInfo?.toString() || "",
      deliverItems: orientationData?.deliverItems?.toString() || "",
      referenceNote: refNote || ""
    }


    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                    <Card><CardBody>
                <Row>
                  <Col md="3">
                    <h4>Chritmas Intake Details</h4>
                  </Col>
                  <Col md="9">
                      <FieldArray name="familyMembers">
                        {({ insert, remove, push }) => (
                          <div>
                            {values?.familyMembers?.length > 0 &&
                              values?.familyMembers?.map((member, index) => (
                                <div key={`${index}-${member.uci}`} className="mb-3">
                                  <h5 className="mt-3">{member.name}</h5>

                                  <Row>
                                    <Col md="3">
                                      <Label for={`familyMembers.${index}.shirtSize`}>
                                        Shirt Size
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Field
                                        id={`familyMembers.${index}.shirtSize`}
                                        name={`familyMembers.${index}.shirtSize`}
                                        type="number"
                                        className={
                                          "form-control" +
                                          (errors.familyMembers &&
                                            errors.familyMembers[index] &&
                                            errors.familyMembers[index].shirtSize &&
                                            touched.familyMembers &&
                                            touched.familyMembers[index] &&
                                            touched.familyMembers[index].shirtSize
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`familyMembers.${index}.shirtSize`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                    <Col md="3">
                                      <Label for={`familyMembers.${index}.shirtSizeMeasure`}>
                                        Shirt Size Measure
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Select
                                        id={`familyMembers.${index}.shirtSizeMeasure`}
                                        name={`familyMembers.${index}.shirtSizeMeasure`}
                                        value={sizeMeasureOptions.find(option => option.value === values.familyMembers[index].shirtSizeMeasure)}
                                        options={sizeMeasureOptions}
                                        onChange={(option) => setFieldValue(`familyMembers.${index}.shirtSizeMeasure`, option.value)}
                                        className={
                                          "react-select-container" +
                                          (errors.familyMembers &&
                                            errors.familyMembers[index] &&
                                            errors.familyMembers[index].shirtSizeMeasure &&
                                            touched.familyMembers &&
                                            touched.familyMembers[index] &&
                                            touched.familyMembers[index].shirtSizeMeasure
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`familyMembers.${index}.shirtSizeMeasure`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                    <Col md="3">
                                      <Label for={`familyMembers.${index}.pantSize`}>
                                        Pant Size
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Field
                                        id={`familyMembers.${index}.pantSize`}
                                        name={`familyMembers.${index}.pantSize`}
                                        type="number"
                                        className={
                                          "form-control" +
                                          (errors.familyMembers &&
                                            errors.familyMembers[index] &&
                                            errors.familyMembers[index].pantSize &&
                                            touched.familyMembers &&
                                            touched.familyMembers[index] &&
                                            touched.familyMembers[index].pantSize
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`familyMembers.${index}.pantSize`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                    <Col md="3">
                                      <Label for={`familyMembers.${index}.pantSizeMeasure`}>
                                        Pant Size Measure
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Select
                                        id={`familyMembers.${index}.pantSizeMeasure`}
                                        name={`familyMembers.${index}.pantSizeMeasure`}
                                        options={sizeMeasureOptions}
                                        value={sizeMeasureOptions.find(option => option.value === values.familyMembers[index].pantSizeMeasure)}
                                        onChange={(option) => setFieldValue(`familyMembers.${index}.pantSizeMeasure`, option.value)}
                                        className={
                                          "react-select-container" +
                                          (errors.familyMembers &&
                                            errors.familyMembers[index] &&
                                            errors.familyMembers[index].pantSizeMeasure &&
                                            touched.familyMembers &&
                                            touched.familyMembers[index] &&
                                            touched.familyMembers[index].pantSizeMeasure
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`familyMembers.${index}.pantSizeMeasure`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                    <Col md="12">
                                      <Label for={`familyMembers.${index}.interests`}>
                                        Interests
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Field
                                        id={`familyMembers.${index}.interests`}
                                        name={`familyMembers.${index}.interests`}
                                        as="textarea"
                                        className={
                                          "form-control" +
                                          (errors.familyMembers &&
                                            errors.familyMembers[index] &&
                                            errors.familyMembers[index].interests &&
                                            touched.familyMembers &&
                                            touched.familyMembers[index] &&
                                            touched.familyMembers[index].interests
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`familyMembers.${index}.interests`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                          </div>
                        )}
                      </FieldArray>
                    
                   
                      <Row className="mb-3">
                        <Col md={12}>
                          <Label>
                            May we share your contact info with the sponsor?<span className="text-danger">*</span>
                          </Label>
                          <div role="group" aria-labelledby="shareInfo">
                            <Label className="d-flex align-items-center fw-normal">
                              <Field
                                type="radio"
                                name="shareInfo"
                                value="true"
                                className="me-1"
                              />{" "}
                              Yes
                            </Label>{" "}
                            <Label className="d-flex align-items-center fw-normal">
                              <Field
                                type="radio"
                                name="shareInfo"
                                value="false"
                                className="me-1"
                              />{" "}
                              No
                            </Label>
                          </div>
                          <ErrorMessage
                            name="shareInfo"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={12}>
                          <Label>
                            May your sponsor deliver items directly to you?<span className="text-danger">*</span>
                          </Label>
                          <div role="group" aria-labelledby="deliverItems">
                            <Label className="d-flex align-items-center fw-normal">
                              <Field
                                type="radio"
                                name="deliverItems"
                                value="true"
                                className="me-1"
                              />{" "}
                              Yes
                            </Label>{" "}
                            <Label className="d-flex align-items-center fw-normal">
                              <Field
                                type="radio"
                                name="deliverItems"
                                value="false"
                                className="me-1"
                              />{" "}
                              No
                            </Label>
                          </div>
                          <ErrorMessage
                            name="deliverItems"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <h5>Verify Address</h5>
                        <Col md="6">
                          <Label for="addressLine1">Address Line 1</Label>
                          <Field
                            id="addressLine1"
                            name="addressLine1"
                            type="text"
                            className={
                              "form-control" +
                              (errors.addressLine1 && touched.addressLine1
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="addressLine1"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col md="6">
                          <Label for="addressLine2">Address Line 2</Label>
                          <Field
                            id="addressLine2"
                            name="addressLine2"
                            type="text"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="4">
                          <Label for="province">Province</Label>
                          <Field
                            id="province"
                            name="province"
                            type="text"
                            className={
                              "form-control" +
                              (errors.province && touched.province
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="province"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col md="4">
                          <Label for="city">City</Label>
                          <Field
                            id="city"
                            name="city"
                            type="text"
                            className={
                              "form-control" +
                              (errors.city && touched.city ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col md="4">
                          <Label for="country">Country</Label>
                          <Field
                            id="country"
                            name="country"
                            type="text"
                            className={
                              "form-control" +
                              (errors.country && touched.country
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <Label for="postalCode">Postal Code</Label>
                          <Field
                            id="postalCode"
                            name="postalCode"
                            type="text"
                            className={
                              "form-control" +
                              (errors.postalCode && touched.postalCode
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="postalCode"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                  </Col>
                </Row>
                    </CardBody></Card>
                <Card><CardBody>
                  <Row className="mb-3">
                    <Col md="3"><h4>Contact Information</h4></Col>
                    <Col md="9">
                      <Row>
                        <Col>
                          <Label for="phoneNumber">Phone Number</Label>
                          <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.phoneNumber}
                                onChange={phoneNumber => {
                                  setFieldValue("phoneNumber", phoneNumber);
                                }}
                                className={
                                  errors.phoneNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />

                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col>
                          <Label for="email">Email</Label>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            className={
                              "form-control" +
                              (errors.email && touched.email ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody></Card>
                <Card><CardBody>
                  <Row>
                    <Col md="3">
                      <h4>Reference Note</h4>
                    </Col>
                    <Col md="9">
                      <div className="mb-3">
                        <Field
                          id="referenceNote"
                          name="referenceNote"
                          as="textarea"
                          style={{ resize: "none" }}
                          className={
                            "form-control" +
                            (errors?.referenceNote &&
                              touched?.referenceNote
                              ? " is-invalid"
                              : "")
                          }
                          cols={100}
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </CardBody></Card>

              </Form>

            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChristmasIntake));
