import { TOGGLE_GENERAL_INFO ,
TOGGLE_IMMIGRATION_INFO,
TOGGLE_INQUIRY_INFO,TOGGLE_PERSONAL_INFO,TOGGLE_INTAKE_IMMIGRATION_INFO,TOGGLE_INTAKE_HOUSING_SECTION,TOGGLE_INTAKE_HEALTH_INFO,TOGGLE_INTAKE_EDUCATIONAL_BACKGROUND,TOGGLE_INTAKE_EMPLOYMENT_HISTORY } from "./actionType"

export const toggleGeneralInfo = () => ({
  type: TOGGLE_GENERAL_INFO
});

export const toggleImmigrationInfo = () => ({
  type: TOGGLE_IMMIGRATION_INFO
});

export const toggleInquiryInfo = () => ({
  type: TOGGLE_INQUIRY_INFO
});
export const togglePersonalInfo = () => ({
  type: TOGGLE_PERSONAL_INFO
});
export const toggleIntakeImmigrationInfo = () => ({
  type: TOGGLE_INTAKE_IMMIGRATION_INFO
});
export const toggleIntakeHealthInfo = () => ({
  type: TOGGLE_INTAKE_HEALTH_INFO
});
export const toggleIntakeEducationalBackground = () => ({
  type: TOGGLE_INTAKE_EDUCATIONAL_BACKGROUND
});
export const toggleIntakeEmploymentHistory = () => ({
  type: TOGGLE_INTAKE_EMPLOYMENT_HISTORY
});
export const toggleIntakeHousingSection = () => ({
  type: TOGGLE_INTAKE_HOUSING_SECTION

});



