import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { countries } from "countries-list";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { formKey, settlementSubFormKey } from "constants/constants";

const validationSchema = Yup.object().shape({
  referrenceNote: Yup.string().required("Required"),
  legalAidRequired: Yup.string().required("Required"),
  userName: Yup.string().when("legalAidRequired", {
    is: "yes",
    then: Yup.string().required("Required"),
  }),
  userPassword: Yup.string().when("legalAidRequired", {
    is: "yes",
    then: Yup.string().required("Required"),
  }),
  clt: Yup.string().when("legalAidRequired", {
    is: "yes",
    then: Yup.string().required("Required"),
  }),
  ce: Yup.string().when("legalAidRequired", {
    is: "yes",
    then: Yup.string().required("Required"),
  }),
});

class LegalAid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      initialValues: {
        legalAidRequired: "",
        userName: "",
        userPassword: "",
        clt: "",
        ce: "",
        referrenceNote: "",
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SETTLEMENT &&
          note.subForm === settlementSubFormKey.LEGALAID &&
          (!latestNote || new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    this.setState({
      initialValues: {
        legalAidRequired: clientData?.settlement?.legalAid?.application || "",
        userName: clientData?.settlement?.legalAid?.username || "",
        userPassword: clientData?.settlement?.legalAid?.password || "",
        clt:
          clientData?.settlement?.legalAid?.clt ||
          clientData?.intakeImmigrationForm?.CLT ||
          "",
        ce:
          clientData?.settlement?.legalAid?.ce ||
          clientData?.intakeImmigrationForm?.CE ||
          "",
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddSettlementLegalAidDate = async legalAidInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/settlement/legalAid/${clientData?.id}`,
        legalAidInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Legal aid information stored successfully!"
        );
        handleGetClient();
        toggle("2");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      legalAid: {
        application: values?.legalAidRequired,
        ...(values?.userName && { username: values.userName }),
        ...(values?.userPassword && { password: values.userPassword }),
        ...(values?.clt && { clt: values.clt }),
        ...(values?.ce && { ce: values.ce }),
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddSettlementLegalAidDate(data);
  };

  render() {
    const { initialValues, showPassword } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Legal Aid</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-9 mb-3 me-3 d-flex">
                            <label
                              htmlFor="legalAidRequired"
                              className="form-label me-3"
                            >
                              Legal aid required?
                              <span className="text-danger"> *</span>
                            </label>
                            <div
                              role="group"
                              aria-labelledby="legalAidRequired"
                              className="form-check"
                            >
                              <label className="me-5">
                                <Field
                                  id="legalAidRequired"
                                  name="legalAidRequired"
                                  type="radio"
                                  value="yes"
                                  className="form-check-input"
                                />
                                Yes
                              </label>
                              <label>
                                <Field
                                  id="legalAidRequired"
                                  name="legalAidRequired"
                                  type="radio"
                                  value="no"
                                  className="form-check-input"
                                />
                                No
                              </label>
                            </div>
                            <ErrorMessage
                              name="legalAidRequired"
                              component="div"
                              className={
                                values?.legalAidRequired
                                  ? "invalid-feedback"
                                  : "text-danger ms-4"
                              }
                            />
                          </div>
                          {values?.legalAidRequired === "yes" ? (
                            <div>
                              <div className="d-flex mb-3">
                                <div className="col-md-4 me-3">
                                  <Label for="userName" className="form-label">
                                    Username
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    id="userName"
                                    name="userName"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors?.userName && touched?.userName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                  />
                                  <ErrorMessage
                                    name="userName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-md-4 me-3">
                                  <Label
                                    for="userPassword"
                                    className="form-label"
                                  >
                                    Password
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Field
                                      id="userPassword"
                                      name="userPassword"
                                      type={showPassword ? "text" : "password"}
                                      className={
                                        "form-control" +
                                        (errors?.userPassword &&
                                        touched?.userPassword
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder=""
                                    />
                                    <button
                                      className="btn btn-light "
                                      type="button"
                                      id="password-addon"
                                      onClick={() =>
                                        this.setState({
                                          showPassword: !showPassword,
                                        })
                                      }
                                    >
                                      {showPassword ? (
                                        <i className="mdi mdi-eye-outline"></i>
                                      ) : (
                                        <i className="mdi mdi-eye-off-outline"></i>
                                      )}
                                    </button>
                                    <ErrorMessage
                                      name="userPassword"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div className="col-md-4 me-3">
                                  <Label for="clt" className="form-label">
                                    CLT#
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    id="clt"
                                    name="clt"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors?.clt && touched?.clt
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                  />
                                  <ErrorMessage
                                    name="clt"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-md-4 me-3">
                                  <Label for="ce" className="form-label">
                                    CE#
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    id="ce"
                                    name="ce"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors?.ce && touched?.ce
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                  />
                                  <ErrorMessage
                                    name="ce"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                              max={new Date().toLocaleDateString("en-CA", {
                                timeZone: "America/Toronto",
                              })}
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LegalAid));
