import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { departments, LIMIT } from "constants/constants";
import { axiosGet } from "helpers/api_helpers";
import emptyContacts from "../../assets/images/emptyContact.png";
import { setShowPageLoader } from "store/actions";
import { connect } from "react-redux";
// /assets/images/emptyContact.png
class Shettlement extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      shettlementClients: [],
      shettlementClient: "",
      contactListColumns: [
        {
          text: "UCI",
          dataField: "screeningForm.generalInformation.uci",
          sort: true,
        },
        {
          text: "Name",
          dataField: "intakeForm.personalInformation.leadMemberName",
          sort: true,
          formatter: (cellContent, row) => {
            return (
              <span>{`${row?.screeningForm?.generalInformation?.firstName} ${row?.screeningForm?.generalInformation?.middleName} ${row?.screeningForm?.generalInformation?.lastName}`}</span>
            );
          },
        },
        {
          text: "Email",
          dataField: "screeningForm.generalInformation.email",
          sort: true,
        },
        {
          text: "Phone No",
          dataField: "screeningForm.generalInformation.phoneNumber",
          sort: true,
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, client) => (
            <div className="d-flex gap-3">
              <Link
                className="text-success"
                to={`/department/settlement/${client.id}`}
              >
                <i
                  className="mdi mdi-clipboard-edit font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  id="edit"
                  // onClick={() => this.handleUserClick(opt)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
  }

  componentDidMount = () => {
    this.handleGetShettlementDepartmentClients();
  };

  /* Insert,Update Delete data */

  handleGetShettlementDepartmentClients = async () => {
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const response = await axiosGet(
        `/department?page=1&limit=${LIMIT}&name=${departments.Settlement}&populate=clients`
      );
      if (response?.status) {
        this.setState({
          shettlementClients: response?.data?.results?.[0]?.clients || [],
        });
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("error at handleGetShettlementDepartmentClients :", error);
    }
  };

  render() {
    //meta title
    document.title = "Department | Settlement";

    const { shettlementClients } = this.state;
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: shettlementClients.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Department" breadcrumbItem="Settlement" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.contactListColumns}
                      data={shettlementClients}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.contactListColumns}
                          data={shettlementClients}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                {shettlementClients.length ? (
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitprops.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {/* <Col
                                  sm="8"
                                  className="d-flex justify-content-end"
                                >
                                  <div className="text-sm-end me-3">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleUserClicks}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Add New Opt
                                    </Button>
                                  </div>
                                </Col> */}
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={emptyContacts}
                                      alt="empty-conatct"
                                      className={`${
                                        shettlementClients.length === 0
                                          ? ""
                                          : "display-none"
                                      }`}
                                    ></img>
                                  </div>
                                  <div
                                    className={`table-responsive ${
                                      shettlementClients.length === 0
                                        ? "display-none"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex pagination pagination-rounded justify-content-end mb-2">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

const mapStateToProps = ({ user }) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shettlement));

// export default Shettlement;
