export const ADD_NOTIFICATION = "ADD_NOTIFICATION"

export const SET_SELECTED_NOTIFICATION = "SET_SELECTED_NOTIFICATION"

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"

export const UPDATE_NOTIFICATION_DROPDOWN ="UPDATE_NOTIFICATION_DROPDOWN"

export const UPDATE_NOTIFICATION_DASHBOARD = "UPDATE_NOTIFICATION_DASHBOARD"

export const UPDATE_UNREAD_COUNT = "UPDATE_UNREAD_COUNT";