import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
  showToast,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import { axiosPost } from "helpers/api_helpers";

import Dropzone from "react-dropzone";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmPassword: false,
      showPassword: false,
      selectedFiles: [],
    };
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  handleStates = (key, value) => {
    this.setState({ [key]: value });
  };

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: [files?.[0]] });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleRegister = async values => {
    try {
      const { name, password, phoneNumber, email } = values;

      let uploadFileObject = {};

      // upload profile image
      if (this.state.selectedFiles.length) {
        const formData = new FormData();
        formData.append("file", this.state.selectedFiles[0]);
        const response = await axiosPost("/upload", formData);
        if (response?.status) {
          uploadFileObject = response?.data;
        }
      }

      const response = await axiosPost(`/auth/register`, {
        name,
        password,
        phoneNumber: JSON.stringify(phoneNumber),
        email,
        ...uploadFileObject,
      });
      if (response?.status) {
        this.props.showToast({
          type: "success",
          message: response?.message || "Change password successfully",
          title: "Register Success",
          duration: 3000,
        });
        this.props.history.push("/login");
      } else {
        console.error("ChangePassword API error: ", response?.message);
        this.props.apiError(response?.message || SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error("ChangePassword error: ", error);
      this.props.apiError(response?.message || SOMETHING_WENT_WRONG);
    }
  };

  render() {
    const { showConfirmPassword, showPassword } = this.state;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Free Register</h5>
                          <p>Get your free Skote account now.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          name: (this.state && this.state.name) || "",
                          email: (this.state && this.state.email) || "",
                          password: (this.state && this.state.password) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Please Enter Your Name"),
                          phoneNumber: Yup.string().required(
                            "Please Enter Your Phone Number"
                          ),
                          email: Yup.string().required(
                            "Please Enter Your Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Password"
                          ),
                          confirmPassword: Yup.string()
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            )
                            .required("Please Enter Confirm Password"),
                        })}
                        onSubmit={this.handleRegister}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="name" className="form-label">
                                Name
                              </Label>
                              <Field
                                name="name"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.name && touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="phoneNumber" className="form-label">
                                Phone Number
                              </Label>
                              <Field
                                name="phoneNumber"
                                type="number"
                                className={
                                  "form-control" +
                                  (errors.phoneNumber && touched.phoneNumber
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3 mt-4">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    this.handleStates(
                                      "showPassword",
                                      !showPassword
                                    )
                                  }
                                >
                                  {showPassword ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Label
                                for="confirmPassword"
                                className="form-label"
                              >
                                Confirm Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="confirmPassword"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.confirmPassword &&
                                    touched.confirmPassword
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    this.handleStates(
                                      "showConfirmPassword",
                                      !showConfirmPassword
                                    )
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                <ErrorMessage
                                  name="confirmPassword"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div>
                              <Label
                                for="confirmPassword"
                                className="form-label"
                              >
                                Upload profile image
                              </Label>
                              <Dropzone
                                onDrop={acceptedFiles =>
                                  this.handleAcceptedFiles(acceptedFiles)
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {this.state.selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold me-4"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                      <i className="mdi mdi-window-close drop-image-close"></i>
                                    </Card>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Register
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <p className="mb-0">
                                By registering you agree to the Skote{" "}
                                <Link to="#" className="text-primary">
                                  Terms of Use
                                </Link>
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-medium text-primary">
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} MathewHouse. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger" /> 
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const { user, registrationError, loading } = state.Account;
  const { error } = state.Login;
  return { user, registrationError, loading, error };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
  showToast,
})(Register);
