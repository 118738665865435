import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
  showToast,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import { axiosPatch, axiosPost } from "helpers/api_helpers";

import Dropzone from "react-dropzone";
import { userRoles } from "constants/user";

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: sessionStorage.getItem("authUser")
        ? JSON.parse(sessionStorage.getItem("authUser"))
        : {},
      showConfirmPassword: false,
      showPassword: false,
      loading: false,
      selectedFiles: [],
    };
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  handleStates = (key, value) => {
    this.setState({ [key]: value });
  };

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: [files?.[0]] });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleCreateModal = async values => {
    try {
      const { name, password, phoneNumber, email } = values;

      this.handleStates("loading", true);
      let uploadFileObject = {};

      // upload profile image
      if (this.state.selectedFiles.length) {
        const formData = new FormData();
        formData.append("file", this.state.selectedFiles[0]);
        const response = await axiosPost("/upload", formData);
        if (response?.status) {
          uploadFileObject = response?.data;
        }
        this.handleStates("selectedFiles", []);
      }

      if (this.props?.isEdit) {
        const response = await axiosPatch(`/users/${this.props?.user?.id}`, {
          name,
          phoneNumber:
            typeof phoneNumber === "number"
              ? JSON.stringify(phoneNumber)
              : phoneNumber,
          email,
          updatedBy: this.state.authUser?.id,
          ...uploadFileObject,
        });
        if (response?.status) {
          this.props?.toggle();
          this.props.showToast({
            type: "success",
            message: response?.message || "User Updated successfully",
            title: "User",
            duration: 3000,
          });
        } else {
          console.error("ChangePassword API error: ", response?.message);
          this.props.apiError(response?.message || SOMETHING_WENT_WRONG);
        }
        this.handleStates("loading", false);
        return;
      }
      const response = await axiosPost(`/users`, {
        name,
        password,
        phoneNumber: JSON.stringify(phoneNumber),
        email,
        ...uploadFileObject,
        createdBy: this.state.authUser?.id,
        role: userRoles.USER,
      });
      if (response?.status) {
        this.props?.toggle();
        this.props.showToast({
          type: "success",
          message: response?.message || "User Created successfully",
          title: "User",
          duration: 3000,
        });
      } else {
        console.error("handleCreateModal API error: ", response?.message);
        this.props.apiError(response?.message || SOMETHING_WENT_WRONG);
      }
      this.handleStates("loading", false);
    } catch (error) {
      console.error("handleCreateModal error: ", error);
      this.props.apiError(response?.message || SOMETHING_WENT_WRONG);
      this.handleStates("loading", false);
    }
  };

  render() {
    const { showConfirmPassword, showPassword, loading } = this.state;
    const { modal, isEdit = false, user } = this.props;

    return (
      <Modal
        isOpen={modal}
        className="add-user-modal"
        toggle={this.props?.toggle}
      >
        {loading ? <Spinner color="primary" className="modal-loader" /> : <></>}
        <ModalHeader toggle={this.props?.toggle} tag="h4">
          {!isEdit ? "Add User" : "Edit User"}
        </ModalHeader>
        <ModalBody>
          <Card className="overflow-hidden">
            <CardBody className="pt-0">
              <div className="p-2">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: (user && user?.name) || "",
                    email: (user && user?.email) || "",
                    phoneNumber: (user && user?.phoneNumber) || "",
                    password: "",
                    confirmPassword: "",
                    isEdit,
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Please Enter Your Name"),
                    phoneNumber: Yup.string().required(
                      "Please Enter Your Phone Number"
                    ),
                    email: Yup.string().required("Please Enter Your Email"),
                    password: Yup.string().when("isEdit", {
                      is: false,
                      then: Yup.string().required("Please Enter Password"),
                      otherwise: Yup.string(),
                    }),
                    confirmPassword: Yup.string().when("isEdit", {
                      is: false,
                      then: Yup.string()
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Please Enter Confirm Password"),
                      otherwise: Yup.string(),
                    }),
                  })}
                  onSubmit={this.handleCreateModal}
                >
                  {({ errors, status, touched }) => (
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label for="name" className="form-label">
                          Name
                        </Label>
                        <Field
                          name="name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="phoneNumber" className="form-label">
                          Phone Number
                        </Label>
                        <Field
                          name="phoneNumber"
                          type="number"
                          className={
                            "form-control" +
                            (errors.phoneNumber && touched.phoneNumber
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="email" className="form-label">
                          Email
                        </Label>
                        <Field
                          name="email"
                          type="email"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      {!isEdit ? (
                        <>
                          <div className="mb-3 mt-4">
                            <Label for="password" className="form-label">
                              Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Field
                                name="password"
                                type={showPassword ? "text" : "password"}
                                autoComplete="true"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                                onClick={() =>
                                  this.handleStates(
                                    "showPassword",
                                    !showPassword
                                  )
                                }
                              >
                                {showPassword ? (
                                  <i className="mdi mdi-eye-outline"></i>
                                ) : (
                                  <i className="mdi mdi-eye-off-outline"></i>
                                )}
                              </button>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label for="confirmPassword" className="form-label">
                              Confirm Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Field
                                name="confirmPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                autoComplete="true"
                                className={
                                  "form-control" +
                                  (errors.confirmPassword &&
                                  touched.confirmPassword
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                                onClick={() =>
                                  this.handleStates(
                                    "showConfirmPassword",
                                    !showConfirmPassword
                                  )
                                }
                              >
                                {showConfirmPassword ? (
                                  <i className="mdi mdi-eye-outline"></i>
                                ) : (
                                  <i className="mdi mdi-eye-off-outline"></i>
                                )}
                              </button>
                              <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div>
                        <Label for="confirmPassword" className="form-label">
                          Upload profile image
                        </Label>
                        <Dropzone
                          onDrop={acceptedFiles =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold me-4"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                                <i className="mdi mdi-window-close drop-image-close"></i>
                              </Card>
                            );
                          })}
                        </div>
                      </div>

                      <div className="mt-4 d-grid d-flex justify-content-end">
                        <button
                          className="btn btn-primary btn-block me-2"
                          type="submit"
                        >
                          {!isEdit ? "Create" : "Update"}
                        </button>
                        <button
                          className="btn btn-secondary btn-block ms-2"
                          onClick={this.props?.toggle}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  modal: PropTypes.bool,
  isEdit: PropTypes.bool,
  toggle: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
  showToast,
})(EditModal);
