import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import { InputGroup, FormControl } from "react-bootstrap";
import { axiosGet, axiosPatch } from "helpers/api_helpers";
import {
  PAGE,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
  screeningFormStatus,
  userRoles,
  capitalizeFirstLetter,
} from "constants/constants";
import { UserContext } from "components/UserProvider/UserProvider";
import moment from "moment";
import Breadcrumbs from "components/Common/Breadcrumb";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setShowPageLoader } from "store/actions";
import Pagination from "components/pagination/Pagination";
import Limit from "components/pagination/Limit";
import emptyContacts from "../../assets/images/emptyContact.png";

class ReferralDepartment extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      updatedClients: new Set(),
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      searchTerm: "",
    };
    this.timer = null;
  }

  componentDidMount = async () => {
    await this.fetchClients();
  };

  fetchClients = async () => {
    const { showPageLoader, clientId } = this.props;
    try {
      showPageLoader(true);
      const { userData } = this.context;
      let response, clients;

      if (clientId) {
        // Fetch specific client data
        response = await axiosGet(`/add-client/screening/${clientId}`);
        const client = response.data;
        clients = [
          {
            ...client,
            dropdownOptions: [],
            referral: client?.referralDepartments
              ? client.referralDepartments.map(dep => ({
                  name: dep.departmentName,
                }))
              : [],
          },
        ];
      } else {
        // Fetch all clients data
        response = await axiosGet(
          `/add-client/screening?screeningApprovalStatus=${
            userData.role === userRoles.ADMIN
              ? screeningFormStatus.APPROVED
              : `${screeningFormStatus.APPROVED}`
          }&page=${this.state.page}&limit=${this.state.size}&searchString=${
            this.state.searchTerm
          }`
        );

        clients = response.data.results.map(client => ({
          ...client,
          dropdownOptions: [],
          referral: client?.referralDepartments
            ? client.referralDepartments.map(dep => ({
                name: dep.departmentName,
              }))
            : [],
        }));
      }

      this.setState(
        { clients, totalSize: response?.data?.totalResults || 1 },
        () => {
          this.fetchDepartmentsForAllClients();
        }
      );
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error(error);
    }
  };

  fetchDepartmentsForAllClients = async () => {
    try {
      const departmentResponses = await Promise.all(
        this.state.clients.map(client => axiosGet("/department"))
      );
      const updatedClients = this.state.clients.map((client, index) => ({
        ...client,
        dropdownOptions: departmentResponses[index].data.results.map(
          department => ({
            value: department.name,
            label: department.name,
          })
        ),
      }));
      this.setState({ clients: updatedClients });
    } catch (error) {
      console.error(error);
    }
  };

  handleReferralChange = (clientId, selectedOptions) => {
    const clients = [...this.state.clients];
    const clientIndex = clients.findIndex(client => client._id === clientId);
    if (clientIndex === -1) {
      console.error(`Client with ID ${clientId} not found.`);
      return;
    }

    clients[clientIndex].referral = selectedOptions.map(option => ({
      name: option.value,
    }));
    this.setState({ clients });
  };

  handleSaveAndNext = async clientId => {
    let client;
    if(this.props.clientId){
      client = this.state.clients[0]
    } else {
      client = this.state.clients.find(client => client._id === clientId);
    }
    if (!client) {
      console.error(`Client with ID ${clientId} not found.`);
      return;
    }

    const referralDepartments = client.referral.map(referral => ({
      departmentName: referral.name,
    }));

    await this.updateClientReferralDepartments(clientId, referralDepartments);
  };

  updateClientReferralDepartments = async (clientId, referralDepartments) => {
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const response = await axiosPatch(`/add-client/referral/${clientId}`, {
        referralDepartments,
      });
      if (response.status) {
        toast.success("Client referred to departments successfully");
        const updatedClients = new Set(this.state.updatedClients);
        updatedClients.add(clientId);
        this.setState({ updatedClients });
        await this.fetchClients();
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      toast.error("Error at Referral department:", error);
    }
  };

  handlePageClick = selectedPage => {
    this.setState({ page: selectedPage }, () => {
      this.fetchClients();
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.fetchClients();
    });
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchClients();
    }, WAITING_TIMING);
  };

  searchClients = event => {
    const { value } = event.target;
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  render() {
    const { clients, totalSize, size, page, searchTerm } = this.state;
    const { clientId } = this.props;
    const { userData } = this.context;
    const isAdmin = userData.role === userRoles.ADMIN;
    const defaultSorted = [
      {
        dataField: "updatedAt",
        order: "desc",
      },
    ];
    const columns = [
      {
        text: "Client Name",
        dataField: "screeningForm.generalInformation.firstName",
        sort: true,
        formatter: (cellContent, client) => {
          const middleName =
            client?.screeningForm?.generalInformation?.middleName;
          const lastName = client?.screeningForm?.generalInformation?.lastName;
          return `${capitalizeFirstLetter(cellContent)} ${capitalizeFirstLetter(
            middleName
          )} ${capitalizeFirstLetter(lastName)}`;
        },
      },
      {
        text: "Referred To",
        dataField: "referral",
        sort: false,
        formatter: (cellContent, client) => (
          <Form>
            <FormGroup>
              <Select
                isMulti
                value={
                  client.referral
                    ? client.referral.map(option => ({
                        value: option.name,
                        label: option.name,
                      }))
                    : []
                }
                onChange={selectedOptions =>
                  this.handleReferralChange(client._id, selectedOptions)
                }
                options={client.dropdownOptions}
                isDisabled={!isAdmin}
              />
            </FormGroup>
          </Form>
        ),
      },
      {
        text: "Timestamp",
        dataField: "updatedAt",
        sort: true,
        formatter: cell => moment(cell).format("MM-DD-YYYY HH:mm:ss"),
      },
      {
        text: "Action",
        dataField: "Action",
        isDummyField: true,
        formatter: (cellContent, client) => (
          <Button
            color="primary"
            onClick={() => this.handleSaveAndNext(client._id || clientId)}
            disabled={!isAdmin}
          >
            Save
          </Button>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className={`${!clientId && "page-content"}`}>
          <Container fluid={true}>
            {!clientId && (
              <Breadcrumbs
                title="Referral List"
                breadcrumbItem="Referral List"
              />
            )}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {clients.length && !clientId ? (
                      <Col sm="3">
                        <div className="app-search d-none d-lg-block">
                          <div className="position-relative">
                            <FormControl
                              className="search-form-control"
                              placeholder="Search"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              value={searchTerm}
                              onChange={this.searchClients}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  this.searchClients(e);
                                }
                              }}
                            />
                            <span className="search-icon bx bx-search-alt" />
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    <div className="d-flex justify-content-center">
                      <img
                        src={emptyContacts}
                        alt="empty-contact"
                        className={`${
                          clients.length === 0 ? "" : "display-none"
                        }`}
                      />
                    </div>
                    <div
                      className={`${!clientId && 'table-responsive'} table-striped ${
                        clients.length === 0 ? "display-none" : ""
                      }`}
                    >
                      <BootstrapTable
                        keyField="_id"
                        data={clients}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        bordered={false}
                        striped={false}
                        responsive
                      />
                      {!clientId && (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={size} // Use selectedLimit for current size
                          />
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ReferralDepartment.propTypes = {
  showPageLoader: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(null, mapDispatchToProps)(ReferralDepartment);
