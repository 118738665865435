import React from "react";
import { Component } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { countries } from "countries-list";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import {
  gender,
  languages,
  martialStatus,
  relation,
  canadaEntry,
  nationalitiesList,
  orgName,
  formKey,
  subFormKeyIntake,
  status,
  formStatus,
} from "../../constants/constants";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  setShowPageLoader,
  togglePersonalInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHealthInfo,
  toggleIntakeHousingSection,
  toggleIntakeImmigrationInfo,
} from "store/actions";
import moment from "moment/moment";
import { PhoneInput } from "react-international-phone";
import formatDateToYYYYMMDD from "utils/formatDate";

const validatePositiveNumber = value => {
  let error;
  if (value === undefined || value === null || value === "") {
    error = "Required";
  } else if (isNaN(value) || Number(value) <= 0) {
    error = "Please enter a positive number";
  }
  return error;
};

const formatUCI = uci => {
  if (uci.length < 8 || uci.length > 10 || uci.length == 9) {
    return uci;
  }
  // Remove hyphens and commas using replace()
  const cleanedUci = uci.replace(/[-,]/g, "");

  // Format based on cleanedUci length (using a switch statement for clarity)
  switch (cleanedUci.length) {
    case 8:
      return `${cleanedUci.slice(0, 4)}-${cleanedUci.slice(4)}`;
    case 10:
      return `${cleanedUci.slice(0, 2)}-${cleanedUci.slice(
        2,
        6
      )}-${cleanedUci.slice(6)}`;
    default:
      return uci; // Unreachable but added for completeness
  }
};
const countryOptions = Object.keys(countries).map(countryCode => ({
  value: countryCode,
  label: countries[countryCode].name,
}));

const calculateAge = dateOfBirthString => {
  const dateOfBirth = new Date(dateOfBirthString);
  const today = new Date();
  const age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDiff = today.getMonth() - dateOfBirth.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())
  ) {
    return age - 1;
  }
  return age;
};

class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
      url: "",
    };
    this.memberRefs = [];
  }
  componentDidMount = async () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
        email: clientData.email,
        phoneNumber: clientData.phoneNumber,
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };

  handleFileUpload = async (event, setFieldValue, fieldName) => {
    const file = event.target.files?.[0];

    let url = "";
    setFieldValue(fieldName, event.target.value);
    this.setState({ uploadedFile: file }, async () => {
      url = await this.handleFileAddition();
      // Set the field value with the obtained URL
      setFieldValue(fieldName, url);
    });
    return url;
  };

  handleFileAddition = async () => {
    try {
      const { uploadedFile } = this.state;

      const formData = new FormData();
      formData.append("file", uploadedFile);

      // Upload image
      const uploadResponse = await axiosPost(`upload`, formData);
      let url = uploadResponse?.data?.destination;
      url = url.replace(/["“”]/g, ""); // to remove ngrok "" in url
      this.setState({ url: url });
      return url;
    } catch (error) {
      console.error("error at handleFileAddition:", error);
    }
  };

  handleSubmit = async values => {
    // const clientId = "6621026b0a2650f264dddbb7";
    const {
      clientId,
      togglePersonalInfo,
      toggleIntakeImmigrationInfo,
      personalInfo,
      intakeImmigrationInfo,
      getClientData,
      setShowPageLoader,
    } = this.props;
    if (clientId) {
      try {
        setShowPageLoader(true);
        const requestBody = {
          type: {
            is_completed: true,
            form_key: formKey.INTAKE,
          },
          personalInformation: {
            type: {
              subForm_key: subFormKeyIntake.PERSONAL,
              formStatus: status.COMPLETED,
            },
            uci: values.uci,
            leadMemberName: values.leadMemberName,
            emergencyName: values.emergencyName,
            phoneNumber: values.phoneNumber,
            contactNumber: values.contactNumber,
            emergencyContactNumber: values.emergencyContactNumber,
            altPhoneNumber: values.altPhoneNumber,
            selectedOptions: values.selectedOptions,
            email: values.email,
            emergencyEmail: values.emergencyEmail,
            refPhoneNumber: values.refPhoneNumber,
            refEmail: values.refEmail,
            photo: values.photo,
            genderType: values.genderType,
            emergencyRelation: values.emergencyRelation,
            postalCode: values.postalCode,
            dateOfBirth: values.dateOfBirth,
            homeCountry: values.homeCountry,
            nationality: values.nationality,
            citizenship: values.citizenship,
            homeCountryAddress: values.homeCountryAddress,
            mailAddress: values.mailAddress,
            emergencyMailAddress: values.emergencyMailAddress,
            emergencyHomeCountryAddress: values.emergencyHomeCountryAddress,
            speaking: values.speaking,
            writing: values.writing,
            reading: values.reading,
            listening: values.listening,
            canadaEntry: values.canadaEntry,
            canadaEntryOtherText: values.canadaEntryOtherText,
            motherTongue: values.motherTongue,
            motherTongueOtherText: values.motherTongueOtherText,
            canadaDate: values.canadaDate,
            particularReligion: values.particularReligion,
            religionText: values.religionText,
            martialStatus: values.martialStatus,
            martialStatusOtherText: values.martialStatusOtherText,
            martialStatusYears: values.martialStatusYears,
            otherText: values.otherText,
            members: values.members.map(member => ({
              uci: member.uci,
              firstName: member.firstName,
              lastName: member.lastName,
              middleName: member.middleName,
              gender: member.gender,
              relation: member.relation,
              dateOfBirth: member.dateOfBirth,
              parentName: member.parentName,
              parentNameOtherText: member.parentNameOtherText,
              memberSelect: member.memberSelect,
              memberSelectOtherText: member.memberSelectOtherText,
              memberMotherTongue: member.memberMotherTongue,
              memberMotherTongueOtherText: member.memberMotherTongueOtherText,
              particularReligion: member.particularReligion,
              religionText: member.religionText,
              email: member.email,
              phoneNumber: member.phoneNumber,
              photo: member.photo,
            })),

            signFormForMembers: values.signFormForMembers.map(member => ({
              uci: member.uci,
              memberName: member.memberName,
              houseOrientation: member.houseOrientation,
              choresCompleted: member.choresCompleted,
              irbChange: member.irbChange,
              rulesSigned: member.rulesSigned,
              mhStaffInitials_rulesSigned: member.mhStaffInitials_rulesSigned,
              signedDate_rulesSigned: member.signedDate_rulesSigned,
              mhStaffInitials_choresCompleted:
                member.mhStaffInitials_choresCompleted,
              signedDate_choresCompleted: member.signedDate_choresCompleted,
              mhStaffInitials_houseOrientation:
                member.mhStaffInitials_houseOrientation,
              signedDate_houseOrientation: member.signedDate_houseOrientation,
              mhStaffInitials_irbChange: member.mhStaffInitials_irbChange,
              signedDate_irbChange: member.signedDate_irbChange,
            })),
            singleSelectedOption: values.singleSelectedOption,
            singleSelectedOptionOtherText: values.singleSelectedOptionOtherText,
          },
        };
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/personal`,
          {
            requestBody: requestBody,
            referenceNotes: values?.referenceNotes,
          }
        );
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          if (personalInfo) {
            togglePersonalInfo();
          }
          if (!intakeImmigrationInfo) {
            toggleIntakeImmigrationInfo();
          }
        } else {
          setShowPageLoader(false);
          toast.error("handleSubmit API error", response?.message);
        }
      } catch (error) {
        setShowPageLoader(false);
        toast.error("handleSubmit API error", error);
      }
    }
  };

  mergeMemberData = (intakeDataMembers, allMembers) => {
    const mergedMembers = allMembers.map(member => {
      const intakeMember = intakeDataMembers.find(
        intakeMember => intakeMember.uci === member.uci
      );
      return {
        uci: member?.uci || "",
        memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
        houseOrientation: intakeMember?.houseOrientation || "",
        mhStaffInitials_houseOrientation:
        intakeMember?.mhStaffInitials_houseOrientation || "",
        signedDate_houseOrientation: formatDateToYYYYMMDD(intakeMember?.signedDate_houseOrientation) || "",
        choresCompleted: intakeMember?.choresCompleted || "",
        mhStaffInitials_choresCompleted:
        intakeMember?.mhStaffInitials_choresCompleted || "",
        signedDate_choresCompleted: formatDateToYYYYMMDD(intakeMember?.signedDate_choresCompleted) || "",
        irbChange: intakeMember?.irbChange || "",
        mhStaffInitials_irbChange: intakeMember?.mhStaffInitials_irbChange || "",
        signedDate_irbChange: formatDateToYYYYMMDD(intakeMember?.signedDate_irbChange) || "",
        rulesSigned: intakeMember?.rulesSigned || "",
        mhStaffInitials_rulesSigned: intakeMember?.mhStaffInitials_rulesSigned || "",
        signedDate_rulesSigned: formatDateToYYYYMMDD(intakeMember?.signedDate_rulesSigned) || "",
      };
    });

    return mergedMembers;
  };

  memberData = (intakeDataMembers, memberData) => {
    const mergedMembers = memberData?.map(member => {
      const intakeMember = intakeDataMembers.find(
        intakeMember => intakeMember.uci === member.uci
      );
      return{
      uci: member?.uci || "",
      firstName: member?.firstName || "",
      lastName: member?.lastName || "",
      middleName: member?.middleName || "",
      gender: member?.gender || "",
      relation: member?.relation || "",
      dateOfBirth: moment(member?.dateOfBirth).format("YYYY-MM-DD") || "",
      parentName: intakeMember?.parentName || "",
      parentNameOtherText: intakeMember?.parentNameOtherText || "",
      memberSelect: intakeMember?.memberSelect
        ? intakeMember?.memberSelect.map(obj => {
            // Get all keys except the last one
            const keys = Object.keys(obj).slice(0, -1);
            // Construct a new object with all key-value pairs except the last one
            const newObj = {};
            keys.forEach(key => {
              newObj[key] = obj[key];
            });
            return newObj;
          })
        : [],
      memberSelectOtherText: intakeMember?.memberSelectOtherText || "",
      memberMotherTongue: intakeMember?.memberMotherTongue || "",
      memberMotherTongueOtherText: intakeMember?.memberMotherTongueOtherText || "",
      particularReligion: intakeMember?.particularReligion || "",
      religionText: intakeMember?.religionText || "",
      email: intakeMember?.email || "",
      phoneNumber: intakeMember?.phoneNumber || "",
      photo: intakeMember?.photo?.replace(/["“”]/g, "") || "",
      }
    });

    return mergedMembers
  }

  // memberData = (clientData, intakeData) => {
  //   const getMemberData = member => ({
  //     uci: member.uci,
  //     firstName: member.firstName,
  //     lastName: member.lastName,
  //     middleName: member.middleName,
  //     gender: member.gender || "",
  //     relation: member?.relation || "",
  //     dateOfBirth: moment(member.dateOfBirth).format("YYYY-MM-DD") || "",
  //     parentName: member.parentName || "",
  //     parentNameOtherText: member.parentNameOtherText || "",
  //     memberSelect: member.memberSelect
  //       ? member.memberSelect.map(obj => {
  //           // Get all keys except the last one
  //           const keys = Object.keys(obj).slice(0, -1);
  //           // Construct a new object with all key-value pairs except the last one
  //           const newObj = {};
  //           keys.forEach(key => {
  //             newObj[key] = obj[key];
  //           });
  //           return newObj;
  //         })
  //       : [],
  //     memberSelectOtherText: member.memberSelectOtherText || "",
  //     memberMotherTongue: member.memberMotherTongue || "",
  //     memberMotherTongueOtherText: member.memberMotherTongueOtherText || "",
  //     particularReligion: member.particularReligion || "",
  //     religionText: member.religionText || "",
  //     email: member.email || "",
  //     phoneNumber: member.phoneNumber || "",
  //     photo: member?.photo?.replace(/["“”]/g, "") || "",
  //   });
  
  //   if (clientData.members.length !== intakeData?.members?.length) {
  //     return clientData.members.map(getMemberData);
  //   }
  
  //   return intakeData.members.map(getMemberData);
  // };

  render() {
    const { allMembers } = this.state;
    const { clientData, immigrationData, intakeData, referenceNote } =
      this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.INTAKE &&
          note.subForm === subFormKeyIntake.PERSONAL &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const languagesObjects = clientData?.languages?.map(language => ({
      value: language,
      label: language.charAt(0).toUpperCase() + language.slice(1),
    }));
    const modifiedArray = intakeData?.selectedOptions.map(obj => {
      // Get all keys except the last one
      let keys = Object.keys(obj).slice(0, -1);
      // Construct a new object with all key-value pairs except the last one
      let newObj = {};
      keys.forEach(key => {
        newObj[key] = obj[key];
      });
      return newObj;
    });

    const initialValues = {
      uci: intakeData?.uci || clientData.uci || "",
      leadMemberName:
        intakeData?.leadMemberName ||
        `${clientData.firstName} ${clientData.middleName} ${clientData.lastName}` ||
        "",
      emergencyName: intakeData?.emergencyName || "",
      phoneNumber: intakeData?.phoneNumber || clientData?.phoneNumber || "",
      contactNumber: intakeData?.contactNumber || clientData?.phoneNumber || "",
      emergencyContactNumber: intakeData?.emergencyContactNumber || "",
      altPhoneNumber: intakeData?.altPhoneNumber || "",
      email: intakeData?.email || clientData?.email || "",
      emergencyEmail: intakeData?.emergencyEmail || "",
      refPhoneNumber:
        intakeData?.refPhoneNumber || clientData.phoneNumber || "",
      refEmail: intakeData?.refEmail || clientData.email || "",
      genderType: intakeData?.genderType || clientData.gender || "",
      emergencyRelation: intakeData?.emergencyRelation || "",
      postalCode: intakeData?.postalCode || "",
      photo: intakeData?.photo?.replace(/["“”]/g, "") || "",
      dateOfBirth:
        moment(intakeData?.dateOfBirth).format("YYYY-MM-DD") ||
        moment(clientData.dateOfBirth).format("YYYY-MM-DD") ||
        "",
      homeCountry:
        intakeData?.homeCountry || (clientData && clientData.homeCountry)
          ? clientData.homeCountry
          : "",
      nationality: intakeData?.nationality || "",
      citizenship: intakeData?.citizenship || "",
      homeCountryAddress: intakeData?.homeCountryAddress || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      mailAddress: intakeData?.mailAddress || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      emergencyHomeCountryAddress: intakeData?.emergencyHomeCountryAddress || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      emergencyMailAddress: intakeData?.emergencyMailAddress || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      speaking: intakeData?.speaking || "",
      writing: intakeData?.writing || "",
      reading: intakeData?.reading || "",
      listening: intakeData?.listening || "",
      canadaEntry:
        intakeData?.canadaEntry || immigrationData?.canadaEntry || "",
      canadaEntryOtherText:
        intakeData?.canadaEntryOtherText ||
        immigrationData?.canadaEntryOtherText ||
        "",
      motherTongue: intakeData?.motherTongue || "",
      motherTongueOtherText: intakeData?.motherTongueOtherText || "",
      canadaDate:
        moment(intakeData?.canadaDate).format("YYYY-MM-DD") ||
        moment(immigrationData?.canadaDate).format("YYYY-MM-DD") ||
        "",
      particularReligion: intakeData?.particularReligion || "",
      religionText: intakeData?.religionText || "",
      martialStatus: intakeData?.martialStatus || "",
      martialStatusOtherText: intakeData?.martialStatusOtherText || "",
      martialStatusYears: intakeData?.martialStatusYears || "",
      referenceNotes: refNote || "",
      otherText: intakeData?.otherText || clientData?.languageOtherText || "",
      selectedOptions: modifiedArray || languagesObjects || [],

      singleSelectedOption:
        intakeData?.singleSelectedOption || clientData?.referralName || "",
      singleSelectedOptionOtherText:
        intakeData?.singleSelectedOptionOtherText ||
        clientData?.referralNameOtherText ||
        "",
      members: (intakeData?.members?.length >0 && this.memberData(intakeData?.members,clientData?.members)) || 
      clientData?.members?.map(member => ({
      uci: member?.uci || "",
      firstName: member?.firstName || "",
      lastName: member?.lastName || "",
      middleName: member?.middleName || "",
      gender: member?.gender || "",
      relation: member?.relation || "",
      dateOfBirth: moment(member.dateOfBirth).format("YYYY-MM-DD") || "",
      parentName: "",
      parentNameOtherText: "",
      memberSelect: [],
      memberSelectOtherText:"",
      memberMotherTongue:"",
      memberMotherTongueOtherText: "",
      particularReligion: "",
      religionText:"",
      email: "",
      phoneNumber: "",
      photo: "",
      }))
      ,
       signFormForMembers:
        (intakeData?.signFormForMembers.length > 0 &&
          this.mergeMemberData(intakeData.signFormForMembers, allMembers)) ||
        allMembers.map(member => ({
          uci: member.uci,
          memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
          houseOrientation: "",
          mhStaffInitials_houseOrientation: "",
          signedDate_houseOrientation: "",
          choresCompleted: "",
          mhStaffInitials_choresCompleted: "",
          signedDate_choresCompleted: "",
          irbChange: "",
          mhStaffInitials_irbChange: "",
          signedDate_irbChange: "",
          rulesSigned: "",
          mhStaffInitials_rulesSigned: "",
          signedDate_rulesSigned: "",
        })),
    };
    const phoneRegExp =
      /^\+?\d{1,4}?[-.\s]?\(?(?:\d{2,5}|\d{4})\)?[-.\s]?\d\d\d?[-.\s]?\d\d\d?[-.\s]?\d\d\d$/;
    const validationSchema = Yup.object().shape({
      uci: Yup.string()
        .matches(/^\d{8}$|^\d{10}$/, "UCI must be either 8 or 10 digits")
        .required("Please Enter Your UCI"),
      leadMemberName: Yup.string().required("Please enter your name"),
      emergencyName: Yup.string().required("Please enter the name"),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, "Invalid phone number format")
        .required("Please enter your phone number"),
      contactNumber: Yup.string()
        .matches(phoneRegExp, "Invalid phone number format")
        .required("Please enter your phone number"),
      emergencyContactNumber: Yup.string()
        .matches(phoneRegExp, "Invalid phone number format")
        .required("Please enter your phone number"),
      altPhoneNumber: Yup.string("Please enter your alternate phone number"),
      email: Yup.string()
        .email("It must be a valid email")
        .max(255)
        .required("Please enter your email"),
      emergencyEmail: Yup.string()
        .email("It must be a valid email")
        .max(255)
        .required("Please enter your email"),
      refPhoneNumber: Yup.string()
        .matches(phoneRegExp, "Invalid phone number format")
        .required("Please Enter Phone Number"),
      refEmail: Yup.string()
        .email("It must be a valid email")
        .max(255)
        .required("Please enter your email"),
      referenceNotes: Yup.string("Notes should be in form of string."),
      genderType: Yup.string().required("Please select a gender"),
      dateOfBirth: Yup.date().required("Please choose your birth date"),
      homeCountry: Yup.string().required("Please select a country"),

      martialStatusOtherText: Yup.string().when("martialStatus", {
        is: "Other",
        then: Yup.string().required("Please enter the other value"),
        otherwise: Yup.string().notRequired(),
      }),
      homeCountryAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      mailAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      canadaEntry: Yup.string().required("Please select an entry point"),
      canadaEntryOtherText: Yup.string().when("canadaEntry", {
        is: "Other",
        then: Yup.string().required("Please enter the other value"),
        otherwise: Yup.string().notRequired(),
      }),
      motherTongue: Yup.string().required("Please select an entry point"),
      motherTongueOtherText: Yup.string().when("motherTongue", {
        is: "Other",
        then: Yup.string().required("Please enter the other value"),
        otherwise: Yup.string().notRequired(),
      }),
      emergencyMailAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      emergencyHomeCountryAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      nationality: Yup.string().required("Please select a nationality"),
      citizenship: Yup.string().required("Please select a citizenship"),
      martialStatus: Yup.string().required("Please select an entry point"),
      emergencyRelation: Yup.string().required("Please select a relation"),
      selectedOptions: Yup.array().min(1, "Please select at least one option"),
      restrictionsDropdown: Yup.array().min(
        1,
        "Please select at least one option"
      ),
      otherText: Yup.string().when("selectedOptions", {
        is: selectedOptions =>
          selectedOptions.some(lang => lang.value === "Other"),
        then: Yup.string().required("Please enter other language text"),
        otherwise: Yup.string().nullable(true), // Optional when "Other" not selected
      }),

      canadaDate: Yup.date().required("Please enter the date"),
      martialStatusYears: Yup.string().when("martialStatus", {
        is: "married",
        then: Yup.string().required("Please mention years of marriage"),
      }),
      singleSelectedOption: Yup.string().required(
        "Please select a organization"
      ),
      singleSelectedOptionOtherText: Yup.string().when("singleSelectedOption", {
        is: "Other",
        then: Yup.string().required("Please enter the other value"),
        otherwise: Yup.string().notRequired(),
      }),
      speaking: Yup.number().required("Please enter your speaking score"),
      reading: Yup.number().required("Please enter your reading score"),
      writing: Yup.number().required("Please enter your writing score"),
      listening: Yup.number().required("Please enter your listening score"),
      postalCode: Yup.string().required("Please enter your postal code"),
      signFormForMembers: Yup.array().of(
        Yup.object().shape({
          mhStaffInitials_rulesSigned: Yup.string().required(
            "Please enter your MH staff initials"
          ),
          rulesSigned: Yup.boolean().isFalse("You must agree to the rules"),
          signedDate_rulesSigned: Yup.date().required(
            "Please choose your date"
          ),
          mhStaffInitials_irbChange: Yup.string().required(
            "Please enter your MH staff initials"
          ),
          signedDate_irbChange: Yup.date().required("Please Choose your date"),
          mhStaffInitials_houseOrientation: Yup.string().required(
            "Please enter your MH staff initials"
          ),
          signedDate_houseOrientation: Yup.date().required(
            "Please choose your date"
          ),
          mhStaffInitials_choresCompleted: Yup.string().required(
            "Please enter your MH staff initials"
          ),
          signedDate_choresCompleted: Yup.date().required(
            "Please choose your date"
          ),
        })
      ),
      particularReligion: Yup.string().required("Religion is required"),
      religionText: Yup.string().when("particularReligion", {
        is: "Yes",
        then: Yup.string().required("Religion is required"),
        otherwise: Yup.string(),
      }),

      members: Yup.array().of(
        Yup.object().shape({
          relation: Yup.string().required("Relationship is required"),
          uci: Yup.string()
            // Allow 8 to 10 digits
            .min(8, "UCI must be between 8 and 10 digits")
            .max(10, "UCI must be between 8 and 10 digits")
            // Allow only numbers
            .matches(/^\d+$/, "UCI must consist only of numbers")
            .required("Please enter your UCI Id"),
          firstName: Yup.string().required("First name is required"),
          middleName: Yup.string(),
          lastName: Yup.string().required("Last name is required"),
          email: Yup.string().email("It Must be a valid Email").max(255),
          phoneNumber: Yup.string("Phone number must be 10 length"),
          gender: Yup.string().required("Gender is required"),
          dateOfBirth: Yup.date().required("Date of birth is required"),
          parentName: Yup.string().when("dateOfBirth", {
            is: dateOfBirth => calculateAge(dateOfBirth) < 18,
            then: Yup.string().required("Parent/Guardian name is required"),
          }),
          parentNameOtherText: Yup.string().when("parentName", {
            is: "Other",
            then: Yup.string().required("Please enter the other value"),
            otherwise: Yup.string().notRequired(),
          }),

          memberSelect: Yup.array().min(
            1,
            "Please select at least one language"
          ), // Required
          memberSelectOtherText: Yup.string().when("memberSelect", {
            is: memberSelect =>
              memberSelect.some(lang => lang.value === "Other"),
            then: Yup.string().required("Please enter other language text"),
            otherwise: Yup.string().nullable(true), // Optional when "Other" not selected
          }),
          memberMotherTongue: Yup.string().required(
            "Please select an entry point"
          ),
          memberMotherTongueOtherText: Yup.string().when("memberMotherTongue", {
            is: "Other",
            then: Yup.string().required("Please enter the other value"),
            otherwise: Yup.string().notRequired(),
          }),
          particularReligion: Yup.string().required("Religion is required"),
          religionText: Yup.string().when("particularReligion", {
            is: "Yes",
            then: Yup.string().required("Religion text is required"),
            otherwise: Yup.string(),
          }),
        })
      ),
    });

    return (
      <React.Fragment>
        <div className="personal-info">
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({
                  errors,
                  status,
                  touched,
                  formik,
                  values,
                  setFieldValue,
                  dirty,
                }) => (
                  <Form>
                    <div className="personal-info">
                      <Row>
                        <Col md="3">
                          <h4 className="mb-3">Lead Client Information</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-8 mb-3">
                            <Label for="uci" className="form-label">
                              UCI#<span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="uci"
                              type="text"
                              className={
                                " form-control" +
                                (errors.uci && touched.uci ? " is-invalid" : "")
                              }
                              value={formatUCI(values.uci)}
                              onChange={e => {
                                const inputValue = e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                ); // Remove non-digit characters
                                setFieldValue("uci", inputValue);
                              }}
                              placeholder="Enter UCI Id"
                            />
                            <ErrorMessage
                              name="uci"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="d-flex gap-3 align-items-start">
                            <div className="col-md-5 mb-3">
                              <Label
                                for="leadMemberName"
                                className="form-label"
                              >
                                Lead member name
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="leadMemberName"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.leadMemberName &&
                                  touched.leadMemberName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter name"
                              />
                              <ErrorMessage
                                name="leadMemberName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-5">
                              <Label for="photo" className="form-label">
                                Photo
                              </Label>
                              <input
                                type="file"
                                name="photo"
                                className="form-control"
                                id="inputGroupFile01"
                                onChange={event => {
                                  const url = this.handleFileUpload(
                                    event,
                                    setFieldValue,
                                    `photo`
                                  );
                                  setFieldValue("photo", url);
                                }}
                              />
                            </div>
                          </div>
                          <Row className="mb-3">
                            <div className="col-md-10 ml-6">
                              <img
                                src={values?.photo}
                                alt="Profile Photo"
                                width={100}
                                height={100}
                              ></img>
                            </div>
                          </Row>
                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3">
                              <Label for="genderType" className="form-label">
                                Gender<span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="genderType"
                                name="genderType"
                                options={gender}
                                value={gender.find(
                                  option => option.value === values.genderType
                                )}
                                onChange={option => {
                                  setFieldValue("genderType", option.value);
                                }}
                                onBlur={() => {
                                  // This ensures touched is set when the select is blurred
                                  if (!touched.genderType) {
                                    setFieldValue(
                                      "genderType",
                                      values.genderType
                                    );
                                  }
                                }}
                                className={
                                  errors.genderType && touched.genderType
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Select gender"
                              />

                              <ErrorMessage
                                name="genderType"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-5 mb-3">
                              <Label for="dateOfBirth" className="form-label">
                                Date of birth
                                <span className="text-danger">*</span>
                              </Label>

                              <Field
                                name="dateOfBirth"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.dateOfBirth && touched.dateOfBirth
                                    ? " is-invalid"
                                    : "")
                                }
                                max={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                                placeholder="Enter date of birth"
                              />
                              <ErrorMessage
                                name="dateOfBirth"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <Row className="width-hearing">
                            <div className="col-md-5 mb-3">
                              <Label for="homeCountry" className="form-label">
                                Home country
                                <span className="text-danger">*</span>
                              </Label>

                              <Select
                                id="homeCountry"
                                name="homeCountry"
                                options={countryOptions}
                                value={countryOptions.find(
                                  option => option.label === values.homeCountry
                                )}
                                onChange={selectedOption => {
                                  setFieldValue(
                                    "homeCountry",
                                    selectedOption.value
                                  );
                                }}
                                className={
                                  errors.homeCountry && touched.homeCountry
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Select home country"
                              />
                              <ErrorMessage
                                name="homeCountry"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>

                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3">
                              <Label
                                for="selectedOptions"
                                className="form-label"
                              >
                                Languages spoken
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="selectedOptions"
                                value={values.selectedOptions}
                                isMulti={true}
                                onChange={value => {
                                  // Update Formik field value after state update
                                  setFieldValue(
                                    "selectedOptions",
                                    value.map(option => option)
                                  );
                                }}
                                className={
                                  "form-control" + errors?.selectedOptions &&
                                  touched?.selectedOptions
                                    ? " is-invalid"
                                    : ""
                                }
                                options={languages}
                              />
                              <ErrorMessage
                                name="selectedOptions"
                                component="div"
                                className="invalid-feedback"
                              />
                              {values?.selectedOptions.some(
                                lang => lang.value === "Other"
                              ) && (
                                <>
                                  <Label
                                    for="otherText"
                                    className="form-label mt-3"
                                  >
                                    Other Text
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    type="text"
                                    name="otherText"
                                    onChange={e => {
                                      setFieldValue(
                                        "otherText",
                                        e.target.value
                                      );
                                    }}
                                    value={values?.otherText}
                                    className={
                                      "form-control" +
                                      (errors.otherText && touched.otherText
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Enter other text"
                                  />
                                  <ErrorMessage
                                    name="otherText"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </>
                              )}
                            </div>
                            <div className="col-md-5 mb-3">
                              <SingleSelectWithOther
                                name="motherTongue"
                                label="Select mother tongue language"
                                options={languages}
                                values={values}
                                value={values.motherTongue}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other mother tongue"
                              />
                            </div>
                          </div>
                          <Row>
                            <Label className="form-label">
                              Do you practice a particular religion?
                              <span className="text-danger">*</span>
                            </Label>
                            <Col md="3">
                              <fieldset
                                className={
                                  errors.particularReligion &&
                                  touched.particularReligion
                                    ? " is-invalid"
                                    : ""
                                }
                              >
                                <Row>
                                  <Col>
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="particularReligion"
                                        value="Yes"
                                        className="me-1"
                                      />
                                      Yes
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="particularReligion"
                                        value="No"
                                        onClick={() => {
                                          setFieldValue("religionText", "");
                                          setFieldValue(
                                            "particularReligion",
                                            "No"
                                          );
                                        }}
                                        className="me-1"
                                      />
                                      No
                                    </Label>
                                  </Col>
                                </Row>
                              </fieldset>
                              <ErrorMessage
                                name="particularReligion"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </Col>
                          </Row>
                          <Row>
                            {values.particularReligion === "Yes" && (
                              <div className="col-md-5 mt-3">
                                <Label
                                  for="religionText"
                                  className="form-label fw-normal"
                                >
                                  Please enter your particular religion
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="religionText"
                                  type="text"
                                  className={
                                    " form-control width-hearing" +
                                    (errors.religionText && touched.religionText
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your religion"
                                />
                                <ErrorMessage
                                  name="religionText"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            )}
                          </Row>
                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3 mt-3">
                              <Label for="nationality" className="form-label">
                                Nationality
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="nationality"
                                name="nationality"
                                options={nationalitiesList}
                                value={nationalitiesList.find(
                                  option => option.value === values.nationality
                                )}
                                onChange={selectedOption => {
                                  setFieldValue(
                                    "nationality",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={() => {
                                  // This ensures touched is set when the select is blurred
                                  if (!touched.nationality) {
                                    setFieldValue(
                                      "nationality",
                                      values.nationality
                                    );
                                  }
                                }}
                                className={
                                  errors.nationality && touched.nationality
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Select nationality"
                              />
                              <ErrorMessage
                                name="nationality"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-5 mb-3 mt-3">
                              <Label for="citizenship" className="form-label">
                                Citizenship
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="citizenship"
                                name="citizenship"
                                options={nationalitiesList}
                                value={nationalitiesList.find(
                                  option => option.value === values.citizenship
                                )}
                                onChange={selectedOption => {
                                  setFieldValue(
                                    "citizenship",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={() => {
                                  // This ensures touched is set when the select is blurred
                                  if (!touched.citizenship) {
                                    setFieldValue(
                                      "citizenship",
                                      values.citizenship
                                    );
                                  }
                                }}
                                className={
                                  errors.citizenship && touched.citizenship
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Select citizenship"
                              />
                              <ErrorMessage
                                name="citizenship"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="d-flex gap-3 align-items-start">
                            <div className="col-md-5 mb-3">
                              <SingleSelectWithOther
                                name="martialStatus"
                                label="Martial status"
                                options={martialStatus}
                                values={values}
                                value={values.martialStatus}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other text"
                              />
                            </div>

                            {values.martialStatus === "married" && (
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="martialStatusYears"
                                  className="form-label"
                                >
                                  Mention years of marriage
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="martialStatusYears"
                                  label="Mention years of marriage"
                                  type="number"
                                  min="0"
                                  max="90"
                                  validate={validatePositiveNumber}
                                  className={
                                    " form-control" +
                                    (errors.martialStatusYears &&
                                    touched.martialStatusYears
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Mention years of marriage"
                                />
                                <ErrorMessage
                                  name="martialStatusYears"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            )}
                          </div>
                          <Row>
                            <div className="d-flex col-md-10">
                              <h6>
                                {" "}
                                English proficiency (According to the ESL
                                Assessment Scale 1-8)
                              </h6>
                              <span className="text-danger">*</span>
                            </div>
                          </Row>
                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3">
                              <Label
                                for="speaking"
                                className="form-label fw-normal"
                              >
                                Speaking<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="speaking"
                                type="number"
                                min="1"
                                max="8"
                                className={
                                  " form-control" +
                                  (errors.speaking && touched.speaking
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter speaking score"
                              />
                              <ErrorMessage
                                name="speaking"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-5 mb-3">
                              <Label
                                for="writing"
                                className="form-label fw-normal"
                              >
                                Writing<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="writing"
                                type="number"
                                min="1"
                                max="8"
                                className={
                                  " form-control" +
                                  (errors.writing && touched.writing
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter writing score"
                              />
                              <ErrorMessage
                                name="writing"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3">
                              <Label
                                for="reading"
                                className="form-label fw-normal"
                              >
                                Reading<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="reading"
                                type="number"
                                min="1"
                                max="8"
                                className={
                                  " form-control" +
                                  (errors.reading && touched.reading
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter reading score"
                              />
                              <ErrorMessage
                                name="reading"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-5 mb-3">
                              <Label
                                for="listening"
                                className="form-label fw-normal"
                              >
                                Listening<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="listening"
                                type="number"
                                min="1"
                                max="8"
                                className={
                                  " form-control" +
                                  (errors.listening && touched.listening
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter listening score"
                              />
                              <ErrorMessage
                                name="listening"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <Row className="width-hearing">
                            <div className="col-md-5 mb-3">
                              <Label for="postalCode" className="form-label">
                                Postal code
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="postalCode"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.postalCode && touched.postalCode
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your postal code"
                              />
                              <ErrorMessage
                                name="postalCode"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3">
                              <Label for="phoneNumber" className="form-label">
                                Phone number
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                name="phoneNumber"
                                type="tel"
                                className={
                                  "form-control" +
                                  (errors.phoneNumber && touched.phoneNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your phone number"
                              /> */}
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.phoneNumber}
                                onChange={phoneNumber => {
                                  setFieldValue("phoneNumber", phoneNumber);
                                }}
                                className={
                                  errors.phoneNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />

                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-5 mb-3">
                              <Label
                                for="altPhoneNumber"
                                className="form-label"
                              >
                                Alternate phone number
                              </Label>
                              {/* <Field
                                name="altPhoneNumber"
                                type="text"
                                pattern="[0-9]{10}"
                                className={
                                  "form-control" +
                                  (errors.altPhoneNumber &&
                                  touched.altPhoneNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your alternate phone number"
                              /> */}
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.altPhoneNumber}
                                onChange={altPhoneNumber => {
                                  setFieldValue(
                                    "altPhoneNumber",
                                    altPhoneNumber
                                  );
                                }}
                                className={
                                  errors.altPhoneNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="altPhoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <Label
                                for="homeCountryAddress"
                                className="form-label"
                              >
                                Home country address
                              </Label>
                            </div>
                            <div className="d-flex gap-3">
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="homeCountryAddress.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="homeCountryAddress.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.homeCountryAddress?.addressLine1 &&
                                    touched?.homeCountryAddress?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="homeCountryAddress.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="homeCountryAddress.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="homeCountryAddress.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.homeCountryAddress?.addressLine2 &&
                                    touched?.homeCountryAddress?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="homeCountryAddress.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-3">
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="homeCountryAddress.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="homeCountryAddress.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.homeCountryAddress?.province &&
                                    touched?.homeCountryAddress?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="homeCountryAddress.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="homeCountryAddress.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="homeCountryAddress.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.homeCountryAddress?.city &&
                                    touched?.homeCountryAddress?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="homeCountryAddress.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3">
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="homeCountryAddress.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="homeCountryAddress.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.homeCountryAddress?.country &&
                                    touched?.homeCountryAddress?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="homeCountryAddress.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="homeCountryAddress.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="homeCountryAddress.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.homeCountryAddress?.postalCode &&
                                    touched?.homeCountryAddress?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your postal code"
                                />
                                <ErrorMessage
                                  name="homeCountryAddress.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <Label for="mailAddress" className="form-label">
                                Mail Address
                              </Label>
                            </div>
                            <div className="d-flex gap-3">
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="mailAddress.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="mailAddress.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.mailAddress?.addressLine1 &&
                                    touched?.mailAddress?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="mailAddress.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="mailAddress.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="mailAddress.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.mailAddress?.addressLine2 &&
                                    touched?.mailAddress?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="mailAddress.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-3">
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="mailAddress.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="mailAddress.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.mailAddress?.province &&
                                    touched?.mailAddress?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="mailAddress.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="mailAddress.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="mailAddress.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.mailAddress?.city &&
                                    touched?.mailAddress?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="mailAddress.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3">
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="mailAddress.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="mailAddress.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.mailAddress?.country &&
                                    touched?.mailAddress?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="mailAddress.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-5 mb-3">
                                <Label
                                  for="mailAddress.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="mailAddress.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.mailAddress?.postalCode &&
                                    touched?.mailAddress?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your postal code"
                                />
                                <ErrorMessage
                                  name="mailAddress.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Family Member Details</h4>
                        </Col>
                        <Col md="9">
                          {values.members.map((member, index) => (
                            <div
                              key={`${index}-${member.uci}`}
                              className="mb-4"
                            >
                              <div className="d-flex align-items-center">
                                <div className="d-flex col-md-6 mb-3 align-items-center justify-content-between">
                                  <h4 className="mb-0">
                                    {member.firstName} {member.middleName}{" "}
                                    {member.lastName}
                                  </h4>
                                </div>
                              </div>
                              <div>
                                <Row>
                                  <Col md="7">
                                    <Label
                                      for={`members[${index}].uci`}
                                      className="form-label"
                                    >
                                      UCI#
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Field
                                      name={`members[${index}].uci`}
                                      type="text"
                                      className={
                                        " form-control" +
                                        (errors.members?.[index]?.uci &&
                                        touched.members?.[index]?.uci
                                          ? " is-invalid"
                                          : "")
                                      }
                                      value={formatUCI(
                                        values?.members?.[index]?.uci
                                      )}
                                      onChange={e => {
                                        const inputValue =
                                          e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
                                        setFieldValue(
                                          `members[${index}].uci`,
                                          inputValue
                                        );
                                      }}
                                      placeholder="Enter member's UCI Id"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].uci`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="5">
                                    <div className="">
                                      <Label
                                        for={`members[${index}].photo`}
                                        className="form-label"
                                      >
                                        Photo
                                      </Label>
                                      <input
                                        type="file"
                                        name={`members[${index}].photo`}
                                        className="form-control"
                                        id="inputGroupFile01"
                                        onChange={event => {
                                          const url = this.handleFileUpload(
                                            event,
                                            setFieldValue,
                                            `members[${index}].photo`
                                          );
                                          setFieldValue(
                                            `members[${index}].photo`,
                                            url
                                          );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <div className="col-md-10 ml-6">
                                    <img
                                      src={member?.photo}
                                      alt="Profile Photo"
                                      width={100}
                                      height={100}
                                    ></img>
                                  </div>
                                </Row>
                                <Row className="mt-3">
                                  <Col md="4">
                                    <Label
                                      for={`members[${index}].firstName`}
                                      className="form-label"
                                    >
                                      First name
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Field
                                      name={`members[${index}].firstName`}
                                      type="text"
                                      className={
                                        " form-control" +
                                        (errors.members?.[index]?.firstName &&
                                        touched.members?.[index]?.firstName
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter member's first name"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].firstName`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="4">
                                    <Label
                                      for={`members[${index}].middleName`}
                                      className="form-label"
                                    >
                                      Middle name
                                    </Label>
                                    <Field
                                      name={`members[${index}].middleName`}
                                      type="text"
                                      className={
                                        " form-control" +
                                        (errors.members?.[index]?.middleName &&
                                        touched.members?.[index]?.middleName
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter member's middle name"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].middleName`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="4">
                                    <Label
                                      for={`members[${index}].lastName`}
                                      className="form-label"
                                    >
                                      Last name
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Field
                                      name={`members[${index}].lastName`}
                                      type="text"
                                      className={
                                        " form-control" +
                                        (errors.members?.[index]?.lastName &&
                                        touched.members?.[index]?.lastName
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter member's last name"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].lastName`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3 mb-3">
                                  <Col md="8">
                                    <Label
                                      for={`members[${index}].email`}
                                      className="form-label"
                                    >
                                      Email
                                    </Label>
                                    <Field
                                      name={`members[${index}].email`}
                                      type="text"
                                      className={
                                        " form-control" +
                                        (errors.members?.[index]?.email &&
                                        touched.members?.[index]?.email
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter member's email address"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].email`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="4">
                                    <Label
                                      for={`members[${index}].phoneNumber`}
                                      className="form-label"
                                    >
                                      Phone number
                                    </Label>
                                    {/* <Field
                                      name={`members[${index}].phoneNumber`}
                                      type="text"
                                      className={
                                        " form-control" +
                                        (errors.members?.[index]?.phoneNumber &&
                                        touched.members?.[index]?.phoneNumber
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter member's phone number"
                                    /> */}
                                    <PhoneInput
                                      containerStyle={{ marginTop: "10px" }}
                                      inputStyle={{
                                        width: "600px",
                                        maxHeight: "100%",
                                      }}
                                      value={
                                        values.members?.[index]?.phoneNumber
                                      }
                                      onChange={phoneNumber => {
                                        setFieldValue(
                                          `members[${index}].phoneNumber`,
                                          phoneNumber
                                        );
                                      }}
                                      className={
                                        errors.members?.[index]?.phoneNumber &&
                                        touched.members?.[index]?.phoneNumber
                                          ? " is-invalid"
                                          : ""
                                      }
                                      disableDialCodePrefill={true}
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].phoneNumber`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row></Row>
                                <Row md="8" className="mb-3">
                                  <Col md="4">
                                    <Label
                                      for={`members[${index}].gender`}
                                      className="form-label"
                                    >
                                      Gender
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                      id={`members[${index}].gender`}
                                      name={`members[${index}].gender`}
                                      options={gender}
                                      value={gender.find(
                                        option =>
                                          option.value ===
                                          values?.members[index]?.gender
                                      )}
                                      onChange={selectedOption => {
                                        setFieldValue(
                                          `members[${index}].gender`,
                                          selectedOption.value
                                        );
                                      }}
                                      className={
                                        errors.members?.[index]?.gender &&
                                        touched.members?.[index]?.gender
                                          ? "is-invalid"
                                          : ""
                                      }
                                      placeholder="Select member's gender"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].gender`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="4">
                                    <Label
                                      for={`members[${index}].dateOfBirth`}
                                      className="form-label"
                                    >
                                      Date Of birth
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Field
                                      name={`members[${index}].dateOfBirth`}
                                      type="date"
                                      max={new Date().toLocaleDateString(
                                        "en-CA",
                                        { timeZone: "America/Toronto" }
                                      )}
                                      className={
                                        "form-control" +
                                        (errors.members?.[index]?.dateOfBirth &&
                                        touched.members?.[index]?.dateOfBirth
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Enter member's date of birth"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].dateOfBirth`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <Label
                                      for={`members[${index}].memberSelect`}
                                      className="form-label"
                                    >
                                      Languages spoken
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                      name={`members[${index}].memberSelect`}
                                      value={
                                        values?.members[index]?.memberSelect
                                      }
                                      isMulti={true}
                                      onChange={value => {
                                        // Update Formik field value after state update
                                        setFieldValue(
                                          `members[${index}].memberSelect`,
                                          value.map(option => option)
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                          errors.members?.[index]
                                            ?.memberSelect &&
                                        touched.members?.[index]?.memberSelect
                                          ? " is-invalid"
                                          : ""
                                      }
                                      options={languages}
                                      placeholder="Select members"
                                    />

                                    <ErrorMessage
                                      name={`members[${index}].memberSelect`}
                                      component="div"
                                      className="invalid-feedback"
                                    />

                                    {values?.members[index]?.memberSelect?.some(
                                      lang => lang.value === "Other"
                                    ) && (
                                      <div className="col-md-12 mb-3 mt-3">
                                        <Label
                                          for={`members[${index}].memberSelectOtherText`}
                                          className="form-label"
                                        >
                                          Other text
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Field
                                          type="text"
                                          name={`members[${index}].memberSelectOtherText`}
                                          onChange={e => {
                                            setFieldValue(
                                              `members[${index}].memberSelectOtherText`,
                                              e.target.value
                                            );
                                          }}
                                          value={
                                            values?.members[index]
                                              ?.memberSelectOtherText
                                          }
                                          className={
                                            "form-control" +
                                            (errors.members?.[index]
                                              ?.memberSelectOtherText &&
                                            touched.members?.[index]
                                              ?.memberSelectOtherText
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Enter other text"
                                        />
                                        <ErrorMessage
                                          name={`members[${index}].memberSelectOtherText`}
                                          component="div"
                                          className={`invalid-feedback text-danger ${
                                            errors.members?.[index]
                                              ?.memberSelectOtherText
                                              ? "d-block"
                                              : ""
                                          }`}
                                        />
                                      </div>
                                    )}
                                  </Col>

                                  <Col md="6">
                                    <SingleSelectWithOther
                                      name={`members[${index}].memberMotherTongue`}
                                      label="Select member's mother tongue"
                                      options={languages}
                                      values={values}
                                      value={member?.memberMotherTongue}
                                      setFieldValue={setFieldValue}
                                      errors={errors}
                                      touched={touched}
                                      otherLabel="Other mother tongue"
                                    />

                                    <ErrorMessage
                                      name={`members[${index}].memberMotherTongue`}
                                      component="div"
                                      className={`invalid-feedback text-danger ${
                                        errors.members?.[index]
                                          ?.memberMotherTongue
                                          ? "d-block"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].memberMotherTongueOtherText`}
                                      component="div"
                                      className={`invalid-feedback text-danger ${
                                        errors.members?.[index]
                                          ?.memberMotherTongueOtherText
                                          ? "d-block"
                                          : ""
                                      }`}
                                    />
                                  </Col>
                                </Row>

                                {calculateAge(member.dateOfBirth) < 18 && (
                                  <>
                                    <Row>
                                      <div className="col-md-6 mt-3">
                                        <SingleSelectWithOther
                                          name={`members[${index}].parentName`}
                                          label="Select parent/guardian"
                                          options={[
                                            ...allMembers
                                              .filter(
                                                m =>
                                                  calculateAge(m.dateOfBirth) >=
                                                    18 && m !== member
                                              )
                                              .map(filteredMember => ({
                                                value: `${filteredMember.firstName} ${filteredMember.middleName} ${filteredMember.lastName}`,
                                                label: `${filteredMember.firstName} ${filteredMember.middleName} ${filteredMember.lastName}`,
                                              })),
                                            { value: "Other", label: "Other" },
                                          ]}
                                          values={values}
                                          value={member?.parentName}
                                          setFieldValue={setFieldValue}
                                          errors={errors}
                                          touched={touched}
                                          otherLabel="Other text"
                                        />
                                        <ErrorMessage
                                          name={`members[${index}].parentName`}
                                          component="div"
                                          className={`invalid-feedback text-danger ${
                                            errors.members?.[index]?.parentName
                                              ? "d-block"
                                              : ""
                                          }`}
                                        />
                                      </div>
                                    </Row>
                                    <Row>
                                      <ErrorMessage
                                        name={`members[${index}].parentNameOtherText`}
                                        component="div"
                                        className={`invalid-feedback text-danger ${
                                          errors.members?.[index]
                                            ?.parentNameOtherText &&
                                          touched.members?.[index]
                                            ?.parentNameOtherText
                                            ? "d-block"
                                            : ""
                                        }`}
                                      />
                                    </Row>
                                  </>
                                )}
                                <Row className="mt-3">
                                  <div className="col-md-6 mb-3">
                                    <Label
                                      htmlFor={`members[${index}].relation`}
                                      className="form-label"
                                    >
                                      Relation to lead client
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                      name={`members[${index}].relation`}
                                      options={relation}
                                      value={relation.find(
                                        option =>
                                          option.value ===
                                          values?.members[index]?.relation
                                      )}
                                      onChange={option => {
                                        setFieldValue(
                                          `members[${index}].relation`,
                                          option.value
                                        );
                                      }}
                                      className={
                                        errors.members?.[index]?.relation &&
                                        touched.members?.[index]?.relation
                                          ? " is-invalid"
                                          : ""
                                      }
                                      placeholder="Enter relation with member"
                                    />
                                    <ErrorMessage
                                      name={`members[${index}].relation`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Row>

                                <Row className="mt-2">
                                  <Label className="form-label">
                                    Do you practice a particular religion?
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="3">
                                    <fieldset
                                      className={
                                        errors.members?.[index]
                                          ?.particularReligion &&
                                        touched.members?.[index]
                                          ?.particularReligion
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`members[${index}].particularReligion`}
                                              value="Yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`members[${index}].particularReligion`}
                                              value="No"
                                              onClick={() => {
                                                setFieldValue(
                                                  `members[${index}].religionText`,
                                                  ""
                                                );
                                                setFieldValue(
                                                  `members[${index}].particularReligion`,
                                                  "No"
                                                );
                                              }}
                                              className="me-1"
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`members[${index}].particularReligion`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  {values.members[index].particularReligion ===
                                    "Yes" && (
                                    <div className="col-md-6 mt-3">
                                      <Label
                                        for={`members[${index}].religionText`}
                                        className="form-label fw-normal"
                                      >
                                        Please enter your particular religion
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Field
                                        name={`members[${index}].religionText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.members?.[index]
                                            ?.religionText &&
                                          touched.members?.[index]?.religionText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your religion"
                                      />
                                      <ErrorMessage
                                        name={`members[${index}].religionText`}
                                        component="div"
                                        className="invalid-feedback text-danger"
                                      />
                                    </div>
                                  )}
                                </Row>

                                {index !== values.members.length - 1 && (
                                  <div className="col-md-16">
                                    <hr />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div className="contact-info mt-3">
                      <Row>
                        <Col md="3">
                          <h4>Contact Information</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="contactNumber" className="form-label">
                                Phone number
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                name="contactNumber"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.contactNumber && touched.contactNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your phone number"
                              /> */}
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.contactNumber}
                                onChange={contactNumber => {
                                  setFieldValue("contactNumber", contactNumber);
                                }}
                                className={
                                  errors.contactNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="contactNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="email" className="form-label">
                                Email Id<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your email address"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div className="arrival-details">
                      <Row>
                        <Col md="3">
                          <h4>Arrival Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="d-flex gap-3 align-items-start">
                            <div className="col-md-5 mb-3">
                              <Label for="canadaDate" className="form-label">
                                When did you arrive in Canada?
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="canadaDate"
                                type="date"
                                max={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                                className={
                                  "form-control" +
                                  (errors.canadaDate && touched.canadaDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter the date"
                              />
                              <ErrorMessage
                                name="canadaDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-5 mb-3">
                              <SingleSelectWithOther
                                name="canadaEntry"
                                label="Where did you enter Canada?"
                                options={canadaEntry}
                                value={values.canadaEntry}
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other Entry Point"
                              />
                            </div>
                          </div>

                          <Row>
                            <div className="col-md-5 mb-3">
                              <SingleSelectWithOther
                                name="singleSelectedOption"
                                label="Organization name"
                                options={orgName}
                                values={values}
                                value={values.singleSelectedOption}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other organization"
                              />
                            </div>
                          </Row>
                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3">
                              <Label
                                for="refPhoneNumber"
                                className="form-label"
                              >
                                Phone number
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                name="refPhoneNumber"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.refPhoneNumber &&
                                  touched.refPhoneNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter phone number"
                              /> */}
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.refPhoneNumber}
                                onChange={refPhoneNumber => {
                                  setFieldValue(
                                    "refPhoneNumber",
                                    refPhoneNumber
                                  );
                                }}
                                className={
                                  errors.refPhoneNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="refPhoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-5 mb-3">
                              <Label for="refEmail" className="form-label">
                                Email<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="refEmail"
                                type="email"
                                placeholder="Enter your email"
                                className={
                                  "form-control" +
                                  (errors.refEmail && touched.refEmail
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="refEmail"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div className="emergency-info">
                      <Row>
                        <Col md="3">
                          <h4>Emergency Information</h4>
                        </Col>
                        <Col md="9">
                          <Row md="auto">
                            <div className="col-md-8 mb-3">
                              <Label for="emergencyName" className="form-label">
                                Name<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="emergencyName"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.emergencyName && touched.emergencyName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter emergency person's name"
                              />
                              <ErrorMessage
                                name="emergencyName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <div className="d-flex gap-3">
                            <div className="col-md-5 mb-3">
                              <Label
                                for="emergencyContactNumber"
                                className="form-label"
                              >
                                Phone number
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                name="emergencyContactNumber"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.emergencyContactNumber &&
                                  touched.emergencyContactNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter phone number"
                              /> */}
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.emergencyContactNumber}
                                onChange={emergencyContactNumber => {
                                  setFieldValue(
                                    "emergencyContactNumber",
                                    emergencyContactNumber
                                  );
                                }}
                                className={
                                  errors.emergencyContactNumber
                                    ? " is-invalid"
                                    : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="emergencyContactNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-5 mb-3">
                              <Label
                                for="emergencyEmail"
                                className="form-label"
                              >
                                Email Id<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="emergencyEmail"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.emergencyEmail &&
                                  touched.emergencyEmail
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter email address"
                              />
                              <ErrorMessage
                                name="emergencyEmail"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <Row>
                            <div className="col-md-5 mb-3">
                              <Label
                                for="emergencyRelation"
                                className="form-label"
                              >
                                Relation to lead client
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="emergencyRelation"
                                name="emergencyRelation"
                                options={relation}
                                value={relation.find(
                                  option =>
                                    option.value === values.emergencyRelation
                                )}
                                onChange={option => {
                                  setFieldValue(
                                    "emergencyRelation",
                                    option.value
                                  );
                                }}
                                onBlur={() => {
                                  // This ensures touched is set when the select is blurred
                                  if (!touched.emergencyRelation) {
                                    setFieldValue(
                                      "emergencyRelation",
                                      values.emergencyRelation
                                    );
                                  }
                                }}
                                className={
                                  errors.emergencyRelation &&
                                  touched.emergencyRelation
                                    ? " is-invalid"
                                    : ""
                                }
                                placeholder="Enter relation with emergency person"
                              />

                              <ErrorMessage
                                name="emergencyRelation"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <div>
                            <div>
                              <Label
                                for="emergencyHomeCountryAddress"
                                className="form-label"
                              >
                                Home country address
                              </Label>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyHomeCountryAddress.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyHomeCountryAddress.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyHomeCountryAddress
                                      ?.addressLine1 &&
                                    touched?.emergencyHomeCountryAddress
                                      ?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="emergencyHomeCountryAddress.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyHomeCountryAddress.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="emergencyHomeCountryAddress.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyHomeCountryAddress
                                      ?.addressLine2 &&
                                    touched?.emergencyHomeCountryAddress
                                      ?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="emergencyHomeCountryAddress.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-3">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyHomeCountryAddress.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyHomeCountryAddress.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyHomeCountryAddress
                                      ?.province &&
                                    touched?.emergencyHomeCountryAddress
                                      ?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="emergencyHomeCountryAddress.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyHomeCountryAddress.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyHomeCountryAddress.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyHomeCountryAddress
                                      ?.city &&
                                    touched?.emergencyHomeCountryAddress?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="emergencyHomeCountryAddress.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyHomeCountryAddress.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyHomeCountryAddress.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyHomeCountryAddress
                                      ?.country &&
                                    touched?.emergencyHomeCountryAddress
                                      ?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="emergencyHomeCountryAddress.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyHomeCountryAddress.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyHomeCountryAddress.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyHomeCountryAddress
                                      ?.postalCode &&
                                    touched?.emergencyHomeCountryAddress
                                      ?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your postal code"
                                />
                                <ErrorMessage
                                  name="emergencyHomeCountryAddress.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <div>
                              <Label
                                for="emergencyMailAddress"
                                className="form-label"
                              >
                                Mail address
                              </Label>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyMailAddress.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyMailAddress.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyMailAddress
                                      ?.addressLine1 &&
                                    touched?.emergencyMailAddress?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="emergencyMailAddress.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyMailAddress.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="emergencyMailAddress.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyMailAddress
                                      ?.addressLine2 &&
                                    touched?.emergencyMailAddress?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="emergencyMailAddress.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyMailAddress.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyMailAddress.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyMailAddress?.province &&
                                    touched?.emergencyMailAddress?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="emergencyMailAddress.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyMailAddress.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyMailAddress.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyMailAddress?.city &&
                                    touched?.emergencyMailAddress?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="emergencyMailAddress.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyMailAddress.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyMailAddress.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyMailAddress?.country &&
                                    touched?.emergencyMailAddress?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="emergencyMailAddress.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="emergencyMailAddress.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="emergencyMailAddress.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.emergencyMailAddress?.postalCode &&
                                    touched?.emergencyMailAddress?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your postal code"
                                />
                                <ErrorMessage
                                  name="emergencyMailAddress.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>

                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Sign the form</h4>
                        </Col>
                        <Col md="9">
                          {values.signFormForMembers.map(
                            (member, index) => (
                              (this.memberRefs[index] = {
                                irb: React.createRef(),
                                chores: React.createRef(),
                                house: React.createRef(),
                                rules: React.createRef(),
                              }),
                              (
                                <div
                                  key={`${index}-${member.uci}`}
                                  className="mb-1"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex col-md-6 mb-3 align-items-center justify-content-between">
                                      <h5 className="mb-0">
                                        {member.memberName}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="sign-the-form">
                                    <Row>
                                      <Col md="9">
                                        <div className="d-flex align-items-start mb-4">
                                          <div className="gap-consent-sign">
                                            <Row md="auto">
                                              <div className="col-md-12">
                                                <Label
                                                  for={`signFormForMembers[${index}].rulesSigned`}
                                                  className="form-label"
                                                >
                                                  Rules and forms signed ?
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Input
                                                  type="checkbox"
                                                  id={`signFormForMembers[${index}].rulesSigned`}
                                                  name={`signFormForMembers[${index}].rulesSigned`}
                                                  className={
                                                    "form-check-input ms-2 mt-1" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.rulesSigned &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.rulesSigned
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  required
                                                />
                                              </div>
                                            </Row>

                                            <div className="d-flex gap-3 align-items-center">
                                              <div>
                                                <Label className="form-label mt-2">
                                                  MH staff initials
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                {intakeData?.signFormForMembers &&
                                                values?.signFormForMembers[
                                                  index
                                                ]
                                                  ?.mhStaffInitials_rulesSigned ? (
                                                  <img
                                                    src={
                                                      values
                                                        ?.signFormForMembers[
                                                        index
                                                      ]
                                                        ?.mhStaffInitials_rulesSigned
                                                    }
                                                    alt="MH Staff Initials"
                                                    className="canvas"
                                                    onError={event => {
                                                      // Handle potential errors if the image fails to load
                                                      event.target.style.display =
                                                        "none"; // Hide the broken image
                                                      console.error(
                                                        "Failed to load MH Staff initials image:",
                                                        values
                                                          ?.signFormForMembers[
                                                          index
                                                        ]
                                                          ?.mhStaffInitials_rulesSigned
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <Field
                                                    name={`signFormForMembers[${index}].mhStaffInitials_rulesSigned`}
                                                  >
                                                    {({ field }) => (
                                                      <>
                                                        <SignatureCanvas
                                                          ref={
                                                            this?.memberRefs[
                                                              index
                                                            ]?.rules
                                                          }
                                                          canvasProps={{
                                                            className: "canvas",
                                                            width: 150,
                                                            height: 55,
                                                          }}
                                                          onEnd={() => {
                                                            const data =
                                                              this?.memberRefs[
                                                                index
                                                              ]?.rules?.current?.toDataURL();
                                                            if (data) {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_rulesSigned`,
                                                                data
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_rulesSigned`,
                                                                ""
                                                              );
                                                            }
                                                          }}
                                                          {...field}
                                                        />
                                                        <ErrorMessage
                                                          name={`signFormForMembers[${index}].mhStaffInitials_rulesSigned`}
                                                          component="div"
                                                          className={`invalid-feedback text-danger ${
                                                            errors
                                                              .signFormForMembers?.[
                                                              index
                                                            ]
                                                              ?.mhStaffInitials_rulesSigned
                                                              ? "d-block"
                                                              : ""
                                                          }`}
                                                        />
                                                      </>
                                                    )}
                                                  </Field>
                                                )}
                                                <div className="mt-2">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary p-1 pl-2 font-sm"
                                                    onClick={() => {
                                                      // Clear the image and show the signature canvas
                                                      setFieldValue(
                                                        `signFormForMembers[${index}].mhStaffInitials_rulesSigned`,
                                                        "" // Set the field value to an empty string
                                                      );
                                                      this?.memberRefs[
                                                        index
                                                      ]?.rules?.current?.clear(); // Clear the signature canvas
                                                    }}
                                                  >
                                                    Clear
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <Row md="auto">
                                              <div className="col-md-10 mb-2 mt-2">
                                                <Label
                                                  for={`signFormForMembers[${index}].signedDate_rulesSigned`}
                                                  className="form-label fw-normal"
                                                >
                                                  Date
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Field
                                                  name={`signFormForMembers[${index}].signedDate_rulesSigned`}
                                                  type="date"
                                                  max={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  min={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  className={
                                                    "form-control" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.signedDate_rulesSigned &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.signedDate_rulesSigned
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Enter the date"
                                                />
                                                <ErrorMessage
                                                  name={`signFormForMembers[${index}].signedDate_rulesSigned`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </Row>
                                          </div>
                                          <div>
                                            <Row md="auto">
                                              <div className="col-md-12">
                                                <Label
                                                  for={`signFormForMembers[${index}].irbChange`}
                                                  className="form-label"
                                                >
                                                  IRB Change of address
                                                  complete?
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Input
                                                  defaultValue={
                                                    values.signFormForMembers?.[
                                                      index
                                                    ]?.irbChange
                                                  }
                                                  type="checkbox"
                                                  id={`signFormForMembers[${index}].irbChange`}
                                                  name={`signFormForMembers[${index}].irbChange`}
                                                  className={
                                                    "form-check-input ms-2 mt-1" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.irbChange &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.irbChange
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  required
                                                />
                                              </div>
                                            </Row>
                                            <div className="d-flex gap-3 align-items-center">
                                              <div>
                                                <Label className="form-label mt-2">
                                                  MH staff initials
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                {intakeData?.signFormForMembers &&
                                                values?.signFormForMembers[
                                                  index
                                                ]?.mhStaffInitials_irbChange ? (
                                                  <img
                                                    src={
                                                      values
                                                        ?.signFormForMembers[
                                                        index
                                                      ]
                                                        ?.mhStaffInitials_irbChange
                                                    }
                                                    alt="MH Staff Initials"
                                                    className="canvas"
                                                    onError={event => {
                                                      event.target.style.display =
                                                        "none";
                                                      console.error(
                                                        "Failed to load MH Staff initials image:",
                                                        values
                                                          ?.signFormForMembers[
                                                          index
                                                        ]
                                                          ?.mhStaffInitials_irbChange
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <Field
                                                    name={`signFormForMembers[${index}].mhStaffInitials_irbChange`}
                                                  >
                                                    {({ field }) => (
                                                      <>
                                                        <SignatureCanvas
                                                          ref={
                                                            this?.memberRefs[
                                                              index
                                                            ]?.irb
                                                          }
                                                          canvasProps={{
                                                            className: "canvas",
                                                            width: 150,
                                                            height: 55,
                                                          }}
                                                          onEnd={() => {
                                                            const data =
                                                              this?.memberRefs[
                                                                index
                                                              ]?.irb?.current?.toDataURL();
                                                            if (data) {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_irbChange`,
                                                                data
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_irbChange`,
                                                                ""
                                                              );
                                                            }
                                                          }}
                                                          {...field}
                                                        />
                                                        <ErrorMessage
                                                          name={`signFormForMembers[${index}].mhStaffInitials_irbChange`}
                                                          component="div"
                                                          className={`invalid-feedback text-danger ${
                                                            errors
                                                              .signFormForMembers?.[
                                                              index
                                                            ]
                                                              ?.mhStaffInitials_irbChange
                                                              ? "d-block"
                                                              : ""
                                                          }`}
                                                        />
                                                      </>
                                                    )}
                                                  </Field>
                                                )}
                                                <div className="mt-2">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary p-1 pl-2 font-sm"
                                                    onClick={() => {
                                                      // Clear the image and show the signature canvas
                                                      setFieldValue(
                                                        `signFormForMembers[${index}].mhStaffInitials_irbChange`,
                                                        "" // Set the field value to an empty string
                                                      );
                                                      this?.memberRefs[
                                                        index
                                                      ]?.irb?.current?.clear(); // Clear the signature canvas
                                                    }}
                                                  >
                                                    Clear
                                                  </button>
                                                </div>
                                              </div>
                                            </div>

                                            <Row md="auto">
                                              <div className="col-md-8 mb-2 mt-2">
                                                <Label
                                                  for={`signFormForMembers[${index}].signedDate_irbChange`}
                                                  className="form-label fw-normal"
                                                >
                                                  Date
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Field
                                                  name={`signFormForMembers[${index}].signedDate_irbChange`}
                                                  type="date"
                                                  max={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  min={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  className={
                                                    "form-control" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.signedDate_irbChange &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.signedDate_irbChange
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Enter the date"
                                                />
                                                <ErrorMessage
                                                  name={`signFormForMembers[${index}].signedDate_irbChange`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </Row>
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-start mb-4">
                                          <div className="gap-consent-sign-1">
                                            <Row md="auto">
                                              <div className="col-md-12">
                                                <Label
                                                  for={`signFormForMembers[${index}].houseOrientation`}
                                                  className="form-label"
                                                >
                                                  House orientation completed?
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Input
                                                  type="checkbox"
                                                  id={`signFormForMembers[${index}].houseOrientation`}
                                                  name={`signFormForMembers[${index}].houseOrientation`}
                                                  className={
                                                    "form-check-input ms-2 mt-1" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.houseOrientation &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.houseOrientation
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  required
                                                />
                                              </div>
                                            </Row>

                                            <div className="d-flex gap-3 align-items-center">
                                              <div>
                                                <Label className="form-label mt-2">
                                                  MH staff initials
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                {intakeData?.signFormForMembers &&
                                                values?.signFormForMembers[
                                                  index
                                                ]
                                                  ?.mhStaffInitials_houseOrientation &&
                                                values?.signFormForMembers[
                                                  index
                                                ]
                                                  ?.mhStaffInitials_houseOrientation ? (
                                                  <img
                                                    src={
                                                      values
                                                        ?.signFormForMembers[
                                                        index
                                                      ]
                                                        ?.mhStaffInitials_houseOrientation
                                                    }
                                                    alt="MH Staff Initials"
                                                    className="canvas"
                                                    onError={event => {
                                                      event.target.style.display =
                                                        "none";
                                                      console.error(
                                                        "Failed to load MH Staff initials image:",
                                                        values
                                                          ?.signFormForMembers[
                                                          index
                                                        ]
                                                          ?.mhStaffInitials_houseOrientation
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <Field
                                                    name={`signFormForMembers[${index}].mhStaffInitials_houseOrientation`}
                                                  >
                                                    {({ field }) => (
                                                      <>
                                                        <SignatureCanvas
                                                          ref={
                                                            this?.memberRefs[
                                                              index
                                                            ]?.house
                                                          }
                                                          canvasProps={{
                                                            className: "canvas",
                                                            width: 150,
                                                            height: 55,
                                                          }}
                                                          onEnd={() => {
                                                            const data =
                                                              this?.memberRefs[
                                                                index
                                                              ]?.house?.current?.toDataURL();
                                                            if (data) {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_houseOrientation`,
                                                                data
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_houseOrientation`,
                                                                ""
                                                              );
                                                            }
                                                          }}
                                                          {...field}
                                                        />
                                                        <ErrorMessage
                                                          name={`signFormForMembers[${index}].mhStaffInitials_houseOrientation`}
                                                          component="div"
                                                          className={`invalid-feedback text-danger ${
                                                            errors
                                                              .signFormForMembers?.[
                                                              index
                                                            ]
                                                              ?.mhStaffInitials_houseOrientation
                                                              ? "d-block"
                                                              : ""
                                                          }`}
                                                        />
                                                      </>
                                                    )}
                                                  </Field>
                                                )}
                                                <div className="mt-2">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary p-1 pl-2 font-sm"
                                                    onClick={() => {
                                                      // Clear the image and show the signature canvas
                                                      setFieldValue(
                                                        `signFormForMembers[${index}].mhStaffInitials_houseOrientation`,
                                                        "" // Set the field value to an empty string
                                                      );
                                                      this?.memberRefs[
                                                        index
                                                      ]?.house?.current?.clear(); // Clear the signature canvas
                                                    }}
                                                  >
                                                    Clear
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            {/* Add signedDate for rulesSigned */}
                                            <Row md="auto">
                                              <div className="col-md-8 mb-2 mt-2">
                                                <Label
                                                  for={`signFormForMembers[${index}].signedDate_houseOrientation`}
                                                  className="form-label fw-normal"
                                                >
                                                  Date
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Field
                                                  name={`signFormForMembers[${index}].signedDate_houseOrientation`}
                                                  type="date"
                                                  max={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  min={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  className={
                                                    "form-control" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]
                                                      ?.signedDate_houseOrientation &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]
                                                      ?.signedDate_houseOrientation
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Enter the date"
                                                />
                                                <ErrorMessage
                                                  name={`signFormForMembers[${index}].signedDate_houseOrientation`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </Row>
                                          </div>
                                          <div>
                                            <Row md="auto">
                                              <div className="col-md-12">
                                                <Label
                                                  for={`signFormForMembers[${index}].choresCompleted`}
                                                  className="form-label"
                                                >
                                                  Chores completely explained?
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Input
                                                  type="checkbox"
                                                  id={`signFormForMembers[${index}].choresCompleted`}
                                                  name={`signFormForMembers[${index}].choresCompleted`}
                                                  className={
                                                    "form-check-input ms-2 mt-1" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.choresCompleted &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]?.choresCompleted
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  required
                                                />
                                                <ErrorMessage
                                                  name={`signFormForMembers[${index}].choresCompleted`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </Row>
                                            <div className="d-flex gap-3 align-items-center">
                                              <div>
                                                <Label className="form-label mt-2">
                                                  MH staff initials
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                {intakeData?.signFormForMembers &&
                                                values?.signFormForMembers[
                                                  index
                                                ]
                                                  ?.mhStaffInitials_choresCompleted ? (
                                                  <img
                                                    src={
                                                      values
                                                        ?.signFormForMembers[
                                                        index
                                                      ]
                                                        ?.mhStaffInitials_choresCompleted
                                                    }
                                                    className="canvas"
                                                    alt="MH Staff Initials canvas"
                                                    onError={event => {
                                                      // Handle potential errors if the image fails to load
                                                      event.target.style.display =
                                                        "none"; // Hide the broken image
                                                      console.error(
                                                        "Failed to load MH Staff initials image:",
                                                        values
                                                          ?.signFormForMembers[
                                                          index
                                                        ]
                                                          ?.mhStaffInitials_choresCompleted
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <Field
                                                    name={`signFormForMembers[${index}].mhStaffInitials_choresCompleted`}
                                                  >
                                                    {({ field }) => (
                                                      <>
                                                        <SignatureCanvas
                                                          ref={
                                                            this?.memberRefs[
                                                              index
                                                            ]?.chores
                                                          }
                                                          canvasProps={{
                                                            className: "canvas",
                                                            width: 150,
                                                            height: 55,
                                                          }}
                                                          onEnd={() => {
                                                            const data =
                                                              this?.memberRefs[
                                                                index
                                                              ]?.chores?.current?.toDataURL();
                                                            if (data) {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_choresCompleted`,
                                                                data
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                `signFormForMembers[${index}].mhStaffInitials_choresCompleted`,
                                                                ""
                                                              );
                                                            }
                                                          }}
                                                          {...field}
                                                        />
                                                        <ErrorMessage
                                                          name={`signFormForMembers[${index}].mhStaffInitials_choresCompleted`}
                                                          component="div"
                                                          className={`invalid-feedback text-danger ${
                                                            errors
                                                              .signFormForMembers?.[
                                                              index
                                                            ]
                                                              ?.mhStaffInitials_choresCompleted
                                                              ? "d-block"
                                                              : ""
                                                          }`}
                                                        />
                                                      </>
                                                    )}
                                                  </Field>
                                                )}
                                                <div className="mt-2">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary p-1 pl-2 font-sm"
                                                    onClick={() => {
                                                      // Clear the image and show the signature canvas
                                                      setFieldValue(
                                                        `signFormForMembers[${index}].mhStaffInitials_choresCompleted`,
                                                        "" // Set the field value to an empty string
                                                      );
                                                      this?.memberRefs[
                                                        index
                                                      ]?.chores?.current?.clear(); // Clear the signature canvas
                                                    }}
                                                  >
                                                    Clear
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            {/* Add signedDate for rulesSigned */}
                                            <Row md="auto">
                                              <div className="col-md-8 mb-2 mt-2">
                                                <Label
                                                  for={`signFormForMembers[${index}].signedDate_choresCompleted`}
                                                  className="form-label fw-normal"
                                                >
                                                  Date
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Label>
                                                <Field
                                                  name={`signFormForMembers[${index}].signedDate_choresCompleted`}
                                                  type="date"
                                                  max={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  min={new Date().toLocaleDateString(
                                                    "en-CA",
                                                    {
                                                      timeZone:
                                                        "America/Toronto",
                                                    }
                                                  )}
                                                  className={
                                                    "form-control" +
                                                    (errors
                                                      .signFormForMembers?.[
                                                      index
                                                    ]
                                                      ?.signedDate_choresCompleted &&
                                                    touched
                                                      .signFormForMembers?.[
                                                      index
                                                    ]
                                                      ?.signedDate_choresCompleted
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Enter the date"
                                                />
                                                <ErrorMessage
                                                  name={`signFormForMembers[${index}].signedDate_choresCompleted`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </Row>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    {index !==
                                      values.signFormForMembers.length - 1 && (
                                      <div className="col-md-16">
                                        <hr />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            )
                          )}
                        </Col>
                      </Row>
                    </div>

                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Field
                                name="referenceNotes"
                                as="textarea"
                                className={
                                  "form-control" +
                                  (errors.referenceNotes &&
                                  touched.referenceNotes
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ resize: "none" }}
                                placeholder="Enter any notes or references"
                              />
                              <ErrorMessage
                                name="referenceNotes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" color="primary">
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PersonalInfo));
