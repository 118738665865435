import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import * as Yup from "yup";
import {
  canadaEntry,
  formKey,
  formStatus,
  status,
  subFormKeyIntake,
} from "constants/constants";
import { countries } from "countries-list";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import moment from "moment/moment";

import {
  setShowPageLoader,
  togglePersonalInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHealthInfo,
  toggleIntakeHousingSection,
  toggleIntakeImmigrationInfo,
} from "store/actions";
import { PhoneInput } from "react-international-phone";
import formatDateToYYYYMMDD from "utils/formatDate";

class IntakeImmigrationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
  }

  componentDidMount = async () => {
    // const { clientId } = this.props;
    // const clientId = "6621026b0a2650f264dddbb7";
    const { clientData, referenceNote } = this.props;

    try {
      const leadClientData = {
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };

  handleSubmit = async values => {
    const {
      clientId,
      togglePersonalInfo,
      toggleIntakeEducationalBackground,
      toggleIntakeEmploymentHistory,
      toggleIntakeHealthInfo,
      toggleIntakeHousingSection,
      toggleIntakeImmigrationInfo,
      personalInfo,
      intakeImmigrationInfo,
      intakeHealthInfo,
      intakeEducationalBackground,
      intakeEmploymentHistory,
      intakeHousingSection,
      getClientData,
      setShowPageLoader,
    } = this.props;

    if (clientId) {
      try {
        setShowPageLoader(true);
        const requestBody = {
          type: {
            is_completed: true,
            form_key: formKey.INTAKE,
          },
          intakeImmigrationForm: {
            type: {
              subForm_key: subFormKeyIntake.INTAKE_IMMIGRATION,
              formStatus: status.COMPLETED,
            },
            permitInfoForMembers: values.permitInfoForMembers.map(member => ({
              uci: member.uci,
              memberName: member.memberName,
              workPermit: member.workPermit,
              workText: member.workText,
              studyPermit: member.studyPermit,
              studyText: member.studyText,
            })),
            address: values.address,
            arrivalPlace: values.arrivalPlace,
            bocDate: values.bocDate,
            bocText: values.bocText,
            canadaEntry: values.canadaEntry,
            canadaEntryOtherText: values.canadaEntryOtherText,
            categoryClaim: values.categoryClaim,
            doctorAddress: values.doctorAddress,
            doctorEmail: values.doctorEmail,
            doctorName: values.doctorName,
            doctorTelNumber: values.doctorTelNumber,
            eligibilityHearing: values.eligibilityHearing,
            eligibilityText: values.eligibilityText,
            email: values.email,
            CE: values.CE,
            CLT: values.CLT,
            exitEmail: values.exitEmail,
            exitTelNumber: values.exitTelNumber,
            hearingDate: values.hearingDate,
            hearingResult: values.hearingResult,
            lawyerName: values.lawyerName,
            legalAid: values.legalAid,
            volunteer: values.volunteer,
            exitAddress: values.exitAddress,
            exitChangedAddress: values.exitChangedAddress,
            letterGivenDate: values.letterGivenDate,
            telNumber: values.telNumber,
            officeOfClaim: values.officeOfClaim,
            officeOfClaimText: values.officeOfClaimText,
            welfareIntakeAppointmentDate: values.welfareIntakeAppointmentDate,
            lawyerAppointmentDate: values.lawyerAppointmentDate,
            immigrationMedicalExamAppointmentDate:
              values.immigrationMedicalExamAppointmentDate,
          },
        };
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/immigration`,
          {
            requestBody: requestBody,
            referenceNotes: values?.referenceNotes,
          }
        );
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          if (personalInfo) {
            togglePersonalInfo();
          }
          if (intakeImmigrationInfo) {
            toggleIntakeImmigrationInfo();
          }
          if (!intakeHealthInfo) {
            toggleIntakeHealthInfo();
          }
        } else {
          setShowPageLoader(false);
          toast.error("handleSubmit API error", response?.message);
        }
      } catch (error) {
        toast.error("handleSubmit API error", error);
      }
    }
  };

  mergeMemberData = (intakeDataMembers, allMembers) => {
    const mergedMembers = allMembers.map(member => {
      const intakeMember = intakeDataMembers.find(
        intakeMember => intakeMember.uci === member.uci
      );
      return {
        uci: member?.uci || "",
        memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
        workPermit: intakeMember?.workPermit || "",
        studyPermit: intakeMember?.studyPermit || "",
        workText: formatDateToYYYYMMDD(intakeMember?.workText) || "",
        studyText: formatDateToYYYYMMDD(intakeMember?.studyText) || "",
      };
    });

    return mergedMembers;
  };

  render() {
    const { allMembers } = this.state;
    const { immigrationData, clientData, intakeData, referenceNote } =
      this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.INTAKE &&
          note.subForm === subFormKeyIntake.INTAKE_IMMIGRATION &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const initialValues = {
      arrivalPlace: intakeData?.arrivalPlace || "",
      categoryClaim: intakeData?.categoryClaim || "",
      eligibilityHearing: intakeData?.eligibilityHearing || "",
      eligibilityText:
        (intakeData?.eligibilityText
          ? new Date(intakeData?.eligibilityText).toISOString().split("T")[0]
          : "") || "",
      legalAid: intakeData?.legalAid || "",
      lawyerName: intakeData?.lawyerName || "",
      email: intakeData?.email || "",
      referenceNotes: refNote || "",
      telNumber: intakeData?.telNumber || "",
      doctorName: intakeData?.doctorName || "",
      doctorEmail: intakeData?.doctorEmail || "",
      doctorTelNumber: intakeData?.doctorTelNumber || "",
      hearingDate:
        (intakeData?.hearingDate
          ? new Date(intakeData?.hearingDate).toISOString().split("T")[0]
          : "") || "",
      hearingResult: intakeData?.hearingResult || "",
      exitTelNumber: intakeData?.exitTelNumber || "",
      exitEmail: intakeData?.exitEmail || clientData.email || "",
      officeOfClaim: intakeData?.officeOfClaim || "",
      officeOfClaimText: intakeData?.officeOfClaimText || "",
      welfareIntakeAppointmentDate:
        (intakeData?.welfareIntakeAppointmentDate
          ? new Date(intakeData?.welfareIntakeAppointmentDate)
              .toISOString()
              .split("T")[0]
          : "") || "",
      lawyerAppointmentDate:
        (intakeData?.lawyerAppointmentDate
          ? new Date(intakeData?.lawyerAppointmentDate)
              .toISOString()
              .split("T")[0]
          : "") || "",
      immigrationMedicalExamAppointmentDate:
        (intakeData?.immigrationMedicalExamAppointmentDate
          ? new Date(intakeData?.immigrationMedicalExamAppointmentDate)
              .toISOString()
              .split("T")[0]
          : "") || "",
      CLT: intakeData?.CLT || "",
      CE: intakeData?.CE || "" || "",
      volunteer: intakeData?.volunteer || "",
      letterGivenDate:
        (intakeData?.letterGivenDate
          ? new Date(intakeData?.letterGivenDate).toISOString().split("T")[0]
          : "") || "",
      bocDate:
        (intakeData?.bocDate
          ? new Date(intakeData?.bocDate).toISOString().split("T")[0]
          : "") || "",
      bocText: intakeData?.bocText || "",
      canadaEntry:
        intakeData?.canadaEntry || immigrationData?.canadaEntry || "",
      canadaEntryOtherText:
        intakeData?.canadaEntryOtherText ||
        immigrationData?.canadaEntryOtherText ||
        "",
      permitInfoForMembers:
        (intakeData?.permitInfoForMembers.length > 0 &&
          this.mergeMemberData(intakeData?.permitInfoForMembers, allMembers)) ||
        allMembers.map(member => ({
          uci: member.uci,
          memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
          workPermit: "",
          studyPermit: "",
          workText: "",
          studyText: "",
        })),
      address: intakeData?.address || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      doctorAddress: intakeData?.doctorAddress || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      exitAddress: intakeData?.exitAddress || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
      exitChangedAddress: intakeData?.exitChangedAddress || {
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
    };
    const phoneRegExp =
      /^\+?\d{1,4}?[-.\s]?\(?(?:\d{2,5}|\d{4})\)?[-.\s]?\d\d\d?[-.\s]?\d\d\d?[-.\s]?\d\d\d$/;
    const validationSchema = Yup.object().shape({
      arrivalPlace: Yup.string().required("Arrival place is required"),
      categoryClaim: Yup.string().required("Please select any one option"),
      referenceNotes: Yup.string("Notes should be in form of string."),
      eligibilityHearing: Yup.string().required("Please select any one option"),
      eligibilityText: Yup.date().when("eligibilityHearing", {
        is: "Yes",
        then: Yup.date().required("Please enter a date"),
      }),
      bocDate: Yup.date().when("eligibilityHearing", {
        is: "No",
        then: Yup.date().required("Please enter date"),
      }),
      bocText: Yup.string().when("eligibilityHearing", {
        is: "No",
        then: Yup.string().required("Please enter your response"),
      }),
      address: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      exitAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      exitChangedAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      doctorAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Address line 1 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("Province is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),

      legalAid: Yup.string().required("Please select any one option"),
      volunteer: Yup.string().required("Please select any one option"),
      CLT: Yup.string().when("legalAid", {
        is: "Yes",
        then: Yup.string().required("CLT is required"),
        otherwise: Yup.string(),
      }),
      CE: Yup.string().when("legalAid", {
        is: "Yes",
        then: Yup.string().required("CE is required"),
        otherwise: Yup.string(),
      }),
      lawyerName: Yup.string().required("Please enter lawyer name"),
      telNumber: Yup.string()
        .matches(phoneRegExp, "Invalid phone number format")
        .required("Please enter telephone number"),
      exitTelNumber: Yup.string()
        .matches(phoneRegExp, "Invalid phone number format")
        .required("Please enter telephone number"),
      email: Yup.string().required("Please enter your email"),
      exitEmail: Yup.string().required("Please enter your email"),
      doctorName: Yup.string().required("Please enter doctor name"),
      doctorTelNumber: Yup.string()
        .matches(phoneRegExp, "Invalid phone number format")
        .required("Please enter telephone number"),
      doctorEmail: Yup.string().required("Please enter your email"),
      officeOfClaim: Yup.string().required("Please select any one option"),
      officeOfClaimText: Yup.string().required(
        "Please enter your office location"
      ),
      canadaEntry: Yup.string().required("Please select an entry point"),
      canadaEntryOtherText: Yup.string().when("canadaEntry", {
        is: "Other",
        then: Yup.string().required("Please enter the other value"),
        otherwise: Yup.string().notRequired(),
      }),
      hearingDate: Yup.date().required("Please enter the date"),
      welfareIntakeAppointmentDate: Yup.date().required(
        "Please enter the date"
      ),
      immigrationMedicalExamAppointmentDate: Yup.date().required(
        "Please enter the date"
      ),
      lawyerAppointmentDate: Yup.date().required("Please enter the date"),
      hearingResult: Yup.string().required("Please enter result of hearing"),
      letterGivenDate: Yup.date().required("Letter given date is required"),
      permitInfoForMembers: Yup.array().of(
        Yup.object().shape({
          workPermit: Yup.string().required("Work permit is required"),
          workText: Yup.string().when("workPermit", {
            is: "Yes",
            then: Yup.string().required("Date of receiving is required"),
          }),
          studyPermit: Yup.string().required("Study permit is required"),
          studyText: Yup.string().when("studyPermit", {
            is: "Yes",
            then: Yup.string().required("Date of receiving is required"),
          }),
        })
      ),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Appointment Dates</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-4 mb-3 mt-2">
                              <Label
                                for="welfareIntakeAppointmentDate"
                                className="form-label"
                              >
                                Welfare intake appointment date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="welfareIntakeAppointmentDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.welfareIntakeAppointmentDate &&
                                  touched.welfareIntakeAppointmentDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter the date"
                                value={new Date(
                                  values.welfareIntakeAppointmentDate
                                ).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="welfareIntakeAppointmentDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-4 mb-3 mt-2">
                              <Label
                                for="immigrationMedicalExamAppointmentDate"
                                className="form-label"
                              >
                                Immigration medical exam appointment date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="immigrationMedicalExamAppointmentDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.immigrationMedicalExamAppointmentDate &&
                                  touched.immigrationMedicalExamAppointmentDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter the date"
                                value={new Date(
                                  values.immigrationMedicalExamAppointmentDate
                                ).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="immigrationMedicalExamAppointmentDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-4 mb-3 mt-2">
                              <Label
                                for="lawyerAppointmentDate"
                                className="form-label"
                              >
                                Lawyer appointment date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="lawyerAppointmentDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.lawyerAppointmentDate &&
                                  touched.lawyerAppointmentDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter the date"
                                value={new Date(
                                  values.lawyerAppointmentDate
                                ).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="lawyerAppointmentDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Arrival Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="d-flex gap-3 align-items-start">
                            <div className="col-md-4 mb-3">
                              <Label for="arrivalPlace" className="form-label">
                                Place of arrival in Canada
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="arrivalPlace"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.arrivalPlace && touched.arrivalPlace
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter place of arrival"
                              />
                              <ErrorMessage
                                name="arrivalPlace"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <SingleSelectWithOther
                                name="canadaEntry"
                                label="Where did you enter Canada?"
                                options={canadaEntry}
                                values={values}
                                value={values.canadaEntry}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other Entry Point"
                              />
                            </div>
                          </div>

                          <Row className="mb-3">
                            <Label className="form-label">
                              Office of Claim
                              <span className="text-danger">*</span>
                            </Label>
                            <Col md="7">
                              <fieldset
                                className={
                                  errors.officeOfClaim && touched.officeOfClaim
                                    ? " is-invalid"
                                    : ""
                                }
                              >
                                <Row>
                                  <Col xs="3">
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="officeOfClaim"
                                        value="Port of Entry"
                                        onClick={() => {
                                          setFieldValue(
                                            "officeOfClaimText",
                                            ""
                                          );
                                        }}
                                        className="me-1"
                                      />
                                      Port of Entry
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="officeOfClaim"
                                        value="Inland"
                                        onClick={() => {
                                          setFieldValue(
                                            "officeOfClaimText",
                                            ""
                                          );
                                        }}
                                        className="me-1"
                                      />
                                      Inland
                                    </Label>
                                  </Col>
                                </Row>
                              </fieldset>
                              <ErrorMessage
                                name="officeOfClaim"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </Col>
                          </Row>
                          {values.officeOfClaim && (
                            <Row className="mt-3">
                              <div className="col-md-4 mb-3">
                                <Label
                                  for="officeOfClaimText"
                                  className="form-label"
                                >
                                  {values.officeOfClaim === "Port of Entry"
                                    ? "Port of Entry"
                                    : "Inland"}{" "}
                                  Details
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="officeOfClaimText"
                                  id="officeOfClaimText"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.officeOfClaimText &&
                                    touched.officeOfClaimText
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder={`Enter ${
                                    values.officeOfClaim === "Port of Entry"
                                      ? "Port of Entry"
                                      : "Inland"
                                  } Details`}
                                />
                                <ErrorMessage
                                  name="officeOfClaimText"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </Row>
                          )}

                          <Row>
                            <Label className="form-label">
                              Category of claim
                              <span className="text-danger">*</span>
                            </Label>
                            <Col md="7">
                              <fieldset
                                className={
                                  errors.categoryClaim && touched.categoryClaim
                                    ? " is-invalid"
                                    : ""
                                }
                              >
                                <Row>
                                  <Col xs="3">
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="categoryClaim"
                                        value="DCO"
                                        className="me-1"
                                      />
                                      DCO
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="categoryClaim"
                                        value="Non-DCO"
                                        className="me-1"
                                      />
                                      Non-DCO
                                    </Label>
                                  </Col>
                                </Row>
                              </fieldset>
                              <ErrorMessage
                                name="categoryClaim"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Label className="form-label">
                              Eligibility hearing completed?
                              <span className="text-danger">*</span>
                            </Label>
                            <Col md="7">
                              <fieldset
                                className={
                                  errors.eligibilityHearing &&
                                  touched.eligibilityHearing
                                    ? " is-invalid"
                                    : ""
                                }
                              >
                                <Row>
                                  <Col xs="3">
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal mb-2"
                                    >
                                      <Field
                                        type="radio"
                                        name="eligibilityHearing"
                                        value="Yes"
                                        onClick={() => {
                                          setFieldValue("eligibilityText", "");
                                        }}
                                        className="me-1"
                                      />
                                      Yes
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="eligibilityHearing"
                                        value="No"
                                        onClick={() => {
                                          setFieldValue("eligibilityText", "");
                                        }}
                                        className="me-1"
                                      />
                                      No
                                    </Label>
                                  </Col>
                                </Row>
                              </fieldset>
                              <ErrorMessage
                                name="eligibilityHearing"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </Col>
                          </Row>
                          <Row>
                            {values.eligibilityHearing === "Yes" && (
                              <div className="d-flex gap-3 align-items-start">
                                <div className="col-md-3 mt-1 mb-3">
                                  <Label
                                    for="eligibilityText"
                                    className="form-label fw-normal"
                                  >
                                    Eligibility hearing date
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    name="eligibilityText"
                                    id="eligibilityText"
                                    type="date"
                                    min={new Date().toLocaleDateString(
                                      "en-CA",
                                      {
                                        timeZone: "America/Toronto",
                                      }
                                    )}
                                    className={
                                      " form-control" +
                                      (errors.eligibilityText &&
                                      touched.eligibilityText
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Enter your response"
                                  />
                                  <ErrorMessage
                                    name="eligibilityText"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            )}
                          </Row>
                          <Row>
                            {values.eligibilityHearing === "No" && (
                              <div className="d-flex gap-3 align-items-start">
                                <div className="col-md-3 mb-3 mt-1">
                                  <Label
                                    for="bocDate"
                                    className="form-label fw-normal"
                                  >
                                    Due date of BOC/PIF
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    name="bocDate"
                                    type="date"
                                    className={
                                      "form-control" +
                                      (errors.bocDate && touched.bocDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Enter the date"
                                    value={new Date(
                                      values.bocDate
                                    ).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto",
                                    })}
                                  />
                                  <ErrorMessage
                                    name="bocDate"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-md-3 mb-3 mt-1">
                                  <Label
                                    for="bocText"
                                    className="form-label fw-normal"
                                  >
                                    BOC/ PIF submitted
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Field
                                    name="bocText"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.bocText && touched.bocText
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Enter your response"
                                  />
                                  <ErrorMessage
                                    name="bocText"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            )}
                          </Row>

                          <Row>
                            <Label className="form-label width-radio">
                              Application for legal aid
                              <span className="text-danger">*</span>
                            </Label>
                            <Col md="7">
                              <fieldset
                                className={
                                  errors.legalAid && touched.legalAid
                                    ? " is-invalid"
                                    : ""
                                }
                              >
                                <Row>
                                  <Col xs="3">
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="legalAid"
                                        value="Yes"
                                        className="me-1"
                                      />
                                      Yes
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="legalAid"
                                        value="No"
                                        className="me-1"
                                      />
                                      No
                                    </Label>
                                  </Col>
                                </Row>
                              </fieldset>
                              <ErrorMessage
                                name="legalAid"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </Col>
                          </Row>
                          {values.legalAid === "Yes" && (
                            <div className="d-flex gap-3 align-items-start">
                              <div className="mt-3 col-md-3">
                                <Label
                                  for="CLT"
                                  className="form-label fw-normal"
                                >
                                  CLT
                                  <span className="text-danger">*</span>
                                </Label>

                                <Field
                                  type="text"
                                  name="CLT"
                                  className={
                                    "form-control" +
                                    (errors.CLT && touched.CLT
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter CLT number"
                                />
                                <ErrorMessage
                                  name="CLT"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mt-3  col-md-3">
                                <Label
                                  for="CE"
                                  className="form-label fw-normal"
                                >
                                  CE
                                  <span className="text-danger">*</span>
                                </Label>

                                <Field
                                  type="text"
                                  name="CE"
                                  className={
                                    " form-control" +
                                    (errors.CE && touched.CE
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter CE number"
                                />
                                <ErrorMessage
                                  name="CE"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Claim Information</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="d-flex col-md-10">
                              <h6> Lawyer Information</h6>
                              <span className="text-danger">*</span>
                            </div>
                          </Row>
                          <div className="d-flex gap-3 align-items-start">
                            <div className="col-md-6 mb-3">
                              <Label
                                for="lawyerName"
                                className="form-label fw-normal"
                              >
                                Name<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="lawyerName"
                                type="text"
                                className={
                                  " form-control" +
                                  (errors.lawyerName && touched.lawyerName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter lawyer's name"
                              />
                              <ErrorMessage
                                name="lawyerName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="d-flex gap-3 align-items-start">
                            <div className="col-md-6 mb-3">
                              <Label
                                for="telNumber"
                                className="form-label fw-normal"
                              >
                                Telephone number
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                name="telNumber"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.telNumber && touched.telNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your lawyer's telephone number"
                              /> */}
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.telNumber}
                                onChange={telNumber => {
                                  setFieldValue("telNumber", telNumber);
                                }}
                                className={
                                  errors.telNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="telNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <Label
                                for="email"
                                className="form-label fw-normal"
                              >
                                Email Id<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your lawyer's email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="">
                              <Label for="address" className="form-label">
                                Lawyer Address
                              </Label>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="address.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="address.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.address?.addressLine1 &&
                                    touched?.address?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="address.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="address.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="address.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.address?.addressLine2 &&
                                    touched?.address?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="address.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="address.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="address.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.address?.province &&
                                    touched?.address?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="address.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="address.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="address.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.address?.city &&
                                    touched?.address?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="address.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="address.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="address.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.address?.country &&
                                    touched?.address?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="address.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="address.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="address.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.address?.postalCode &&
                                    touched?.address?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter postal code"
                                />
                                <ErrorMessage
                                  name="address.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>

                          <Row>
                            <div className="d-flex col-md-10">
                              <h6> Medical Doctor Information</h6>
                              <span className="text-danger">*</span>
                            </div>
                          </Row>
                          <div className="">
                            <div className="col-md-6 mb-3">
                              <Label
                                for="doctorName"
                                className="form-label fw-normal"
                              >
                                Name<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="doctorName"
                                type="text"
                                className={
                                  " form-control" +
                                  (errors.doctorName && touched.doctorName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter doctor's name"
                              />
                              <ErrorMessage
                                name="doctorName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <Row>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorTelNumber"
                                  className="form-label fw-normal"
                                >
                                  Telephone number
                                  <span className="text-danger">*</span>
                                </Label>
                                {/* <Field
                                  name="doctorTelNumber"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.doctorTelNumber &&
                                    touched.doctorTelNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your doctor's telephone number"
                                /> */}
                                <PhoneInput
                                  containerStyle={{ marginTop: "10px" }}
                                  inputStyle={{
                                    width: "600px",
                                    maxHeight: "100%",
                                  }}
                                  value={values.doctorTelNumber}
                                  onChange={doctorTelNumber => {
                                    setFieldValue(
                                      "doctorTelNumber",
                                      doctorTelNumber
                                    );
                                  }}
                                  className={
                                    errors.doctorTelNumber ? " is-invalid" : ""
                                  }
                                  disableDialCodePrefill={true}
                                />
                                <ErrorMessage
                                  name="doctorTelNumber"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorEmail"
                                  className="form-label fw-normal"
                                >
                                  Email Id<span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="doctorEmail"
                                  type="email"
                                  className={
                                    "form-control" +
                                    (errors.doctorEmail && touched.doctorEmail
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your doctor's email"
                                />
                                <ErrorMessage
                                  name="doctorEmail"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </Row>

                          <div>
                            <div>
                              <Label for="doctorAddress" className="form-label">
                                Doctor address
                              </Label>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorAddress.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="doctorAddress.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.doctorAddress?.addressLine1 &&
                                    touched?.doctorAddress?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="doctorAddress.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorAddress.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="doctorAddress.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.doctorAddress?.addressLine2 &&
                                    touched?.doctorAddress?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="doctorAddress.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorAddress.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="doctorAddress.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.doctorAddress?.province &&
                                    touched?.doctorAddress?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="doctorAddress.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorAddress.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="doctorAddress.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.doctorAddress?.city &&
                                    touched?.doctorAddress?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="doctorAddress.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorAddress.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="doctorAddress.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.doctorAddress?.country &&
                                    touched?.doctorAddress?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="doctorAddress.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="doctorAddress.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="doctorAddress.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.doctorAddress?.postalCode &&
                                    touched?.doctorAddress?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your postal code"
                                />
                                <ErrorMessage
                                  name="doctorAddress.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                          <Row>
                            <div className="col-md-6 mb-3 mt-2">
                              <Label for="hearingDate" className="form-label">
                                Hearing date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="hearingDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.hearingDate && touched.hearingDate
                                    ? " is-invalid"
                                    : "")
                                }
                                value={new Date(
                                  values.hearingDate
                                ).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="hearingDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="hearingResult" className="form-label">
                                Result of hearing
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="hearingResult"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.hearingResult && touched.hearingResult
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter result of hearing"
                              />
                              <ErrorMessage
                                name="hearingResult"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Permit Information</h4>
                        </Col>
                        <Col md="9">
                          {values.permitInfoForMembers.map((member, index) => (
                            <div
                              key={`${index}-${member.uci}`}
                              className="mb-1"
                            >
                              <div className="d-flex align-items-center">
                                <div className="d-flex col-md-6 mb-3 align-items-center justify-content-between">
                                  <h5 className="mb-0">{member.memberName}</h5>
                                </div>
                              </div>
                              <div>
                                <Row className="mt-1 mb-2">
                                  <Label className="form-label fw-normal">
                                    Work permit{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="7">
                                    <fieldset
                                      className={
                                        errors.permitInfoForMembers?.[index]
                                          ?.workPermit &&
                                        touched.permitInfoForMembers?.[index]
                                          ?.workPermit
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col xs="3">
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`permitInfoForMembers[${index}].workPermit`}
                                              value="Yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`permitInfoForMembers[${index}].workPermit`}
                                              value="No"
                                              onClick={() =>
                                                setFieldValue(
                                                  `permitInfoForMembers[${index}].workText`,
                                                  ""
                                                )
                                              }
                                              className="me-1"
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`permitInfoForMembers[${index}].workPermit`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values?.permitInfoForMembers[index]
                                    ?.workPermit === "Yes" && (
                                    <Row>
                                      <Col md="3" className="mb-2">
                                        <Label className="form-label fw-normal">
                                          Date of receiving{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Field
                                          name={`permitInfoForMembers[${index}].workText`}
                                          type="date"
                                          min={new Date().toLocaleDateString(
                                            "en-CA",
                                            {
                                              timeZone: "America/Toronto",
                                            }
                                          )}
                                          className={
                                            "form-control" +
                                            (errors.permitInfoForMembers?.[
                                              index
                                            ]?.workText &&
                                            touched.permitInfoForMembers?.[
                                              index
                                            ]?.workText
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Enter your response"
                                        />
                                        <ErrorMessage
                                          name={`permitInfoForMembers[${index}].workText`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </Row>
                                <Row className="">
                                  <Label className="form-label fw-normal">
                                    Study permit{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="7">
                                    <fieldset
                                      className={
                                        errors.permitInfoForMembers?.[index]
                                          ?.studyPermit &&
                                        touched.permitInfoForMembers?.[index]
                                          ?.studyPermit
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col xs="3">
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`permitInfoForMembers[${index}].studyPermit`}
                                              value="Yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`permitInfoForMembers[${index}].studyPermit`}
                                              value="No"
                                              onClick={() => {
                                                setFieldValue(
                                                  `permitInfoForMembers[${index}].studyText`,
                                                  ""
                                                );
                                              }}
                                              className="me-1"
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`permitInfoForMembers[${index}].studyPermit`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values?.permitInfoForMembers[index]
                                    ?.studyPermit === "Yes" && (
                                    <Row>
                                      <Col md="3" className="mb-2 mt-2">
                                        <Label className="form-label fw-normal">
                                          Date of receiving{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Field
                                          name={`permitInfoForMembers[${index}].studyText`}
                                          type="date"
                                          min={new Date().toLocaleDateString(
                                            "en-CA",
                                            {
                                              timeZone: "America/Toronto",
                                            }
                                          )}
                                          className={
                                            "form-control" +
                                            (errors.permitInfoForMembers?.[
                                              index
                                            ]?.studyText &&
                                            touched.permitInfoForMembers?.[
                                              index
                                            ]?.studyText
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Enter your response"
                                        />
                                        <ErrorMessage
                                          name={`permitInfoForMembers[${index}].studyText`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </Row>
                                {index !==
                                  values.permitInfoForMembers.length - 1 && (
                                  <div className="col-md-16">
                                    <hr />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Exit Procedures</h4>
                        </Col>
                        <Col md="9">
                          <div>
                            <div>
                              <Label for="exitAddress" className="form-label">
                                Forwarding address
                              </Label>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitAddress.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitAddress.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.exitAddress?.addressLine1 &&
                                    touched?.exitAddress?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="exitAddress.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitAddress.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="exitAddress.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.exitAddress?.addressLine2 &&
                                    touched?.exitAddress?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="exitAddress.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitAddress.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitAddress.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitAddress?.province &&
                                    touched?.exitAddress?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="exitAddress.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitAddress.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitAddress.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitAddress?.city &&
                                    touched?.exitAddress?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="exitAddress.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitAddress.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitAddress.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitAddress?.country &&
                                    touched?.exitAddress?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="exitAddress.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitAddress.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitAddress.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitAddress?.postalCode &&
                                    touched?.exitAddress?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your postal code"
                                />
                                <ErrorMessage
                                  name="exitAddress.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="d-flex gap-3 align-items-start">
                            <div className="col-md-6 mb-3">
                              <Label for="exitTelNumber" className="form-label">
                                Telephone number
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                name="exitTelNumber"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.exitTelNumber && touched.exitTelNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your telephone number"
                              /> */}
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.exitTelNumber}
                                onChange={exitTelNumber => {
                                  setFieldValue("exitTelNumber", exitTelNumber);
                                }}
                                className={
                                  errors.exitTelNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="exitTelNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <Label for="exitEmail" className="form-label">
                                Email Id<span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="exitEmail"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.exitEmail && touched.exitEmail
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter your email"
                              />
                              <ErrorMessage
                                name="exitEmail"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <Label
                                for="exitChangedAddress"
                                className="form-label"
                              >
                                Change of address for immigration
                              </Label>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitChangedAddress.addressLine1"
                                  className="form-label fw-normal"
                                >
                                  Address line 1
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitChangedAddress.addressLine1"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.exitChangedAddress?.addressLine1 &&
                                    touched?.exitChangedAddress?.addressLine1
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 1"
                                />
                                <ErrorMessage
                                  name="exitChangedAddress.addressLine1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitChangedAddress.addressLine2"
                                  className="form-label fw-normal"
                                >
                                  Address line 2
                                </Label>
                                <Field
                                  name="exitChangedAddress.addressLine2"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors?.exitChangedAddress?.addressLine2 &&
                                    touched?.exitChangedAddress?.addressLine2
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter address line 2"
                                />
                                <ErrorMessage
                                  name="exitChangedAddress.addressLine2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitChangedAddress.province"
                                  className="form-label fw-normal"
                                >
                                  Province
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitChangedAddress.province"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitChangedAddress?.province &&
                                    touched?.exitChangedAddress?.province
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter province"
                                />
                                <ErrorMessage
                                  name="exitChangedAddress.province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitChangedAddress.city"
                                  className="form-label fw-normal"
                                >
                                  City
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitChangedAddress.city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitChangedAddress?.city &&
                                    touched?.exitChangedAddress?.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="exitChangedAddress.city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-3 align-items-start">
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitChangedAddress.country"
                                  className="form-label fw-normal"
                                >
                                  Country
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitChangedAddress.country"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitChangedAddress?.country &&
                                    touched?.exitChangedAddress?.country
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="exitChangedAddress.country"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <Label
                                  for="exitChangedAddress.postalCode"
                                  className="form-label fw-normal"
                                >
                                  Postal code
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  name="exitChangedAddress.postalCode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.exitChangedAddress?.postalCode &&
                                    touched?.exitChangedAddress?.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter your postal code"
                                />
                                <ErrorMessage
                                  name="exitChangedAddress.postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>

                          <Row className="width-hearing">
                            <div className="col-md-4 mb-3 mt-2">
                              <Label
                                for="letterGivenDate"
                                className="form-label"
                              >
                                Date of exit letter given
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                name="letterGivenDate"
                                type="date"
                                min={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                                className={
                                  "form-control" +
                                  (errors.letterGivenDate &&
                                  touched.letterGivenDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="letterGivenDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <Label className="form-label">
                              Volunteer - Employed?
                              <span className="text-danger">*</span>
                            </Label>
                            <Col md="7">
                              <fieldset
                                className={
                                  errors.volunteer && touched.volunteer
                                    ? " is-invalid"
                                    : ""
                                }
                              >
                                <Row>
                                  <Col xs="3">
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="volunteer"
                                        value="Yes"
                                        className="me-1"
                                      />
                                      Yes
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="volunteer"
                                        value="No"
                                        className="me-1"
                                      />
                                      No
                                    </Label>
                                  </Col>
                                </Row>
                              </fieldset>
                              <ErrorMessage
                                name="volunteer"
                                component="div"
                                className="invalid-feedback text-danger"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div>
                        <Row>
                          <Col md="3">
                            <h4>Reference Notes</h4>
                          </Col>
                          <Col md="9">
                            <Row>
                              <div className="col-md-6 mb-3">
                                <Field
                                  name="referenceNotes"
                                  as="textarea"
                                  className={
                                    "form-control" +
                                    (errors.referenceNotes &&
                                    touched.referenceNotes
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ resize: "none" }}
                                  placeholder="Enter any notes or references"
                                />
                                <ErrorMessage
                                  name="referenceNotes"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" color="primary">
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeImmigrationInfo));
