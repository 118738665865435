import PropTypes from "prop-types";
import React, { Component } from "react";

// SimpleBar
import SimpleBar from "simplebar-react";

// withRouter and Link
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

// i18n
import { withTranslation } from "react-i18next";

import { UserContext } from "components/UserProvider/UserProvider";
import { capitalizeFirstLetter, userRoles } from "constants/constants";

class SidebarContent extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      departmentObj: {
        shelter: "shelter",
        settlement: "settlement",
        orientation: "orientation",
      },
    };
    this.refDiv = React.createRef();
  }

  hasActiveChild = childLinks => {
    const { pathname } = this.props.location;
    return childLinks.some(link => pathname.startsWith(link));
  };

  render() {
    const { userData, departments } = this.context;
    const { departmentObj } = this.state;

    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              <li className="">
                <NavLink
                  to="#"
                  activeClassName={
                    this.hasActiveChild([
                      "/add-clients",
                      "/admin/clients/",
                      "/admin/referrals",
                    ])
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="mdi mdi-clipboard-account" />
                  <span>{this.props.t("Client Management")}</span>
                </NavLink>
                <ul className="sub-menu mm-show" aria-expanded="true">
                  <li>
                    <NavLink to="/add-clients" activeClassName="mm-active">
                      <span>{this.props.t("Add Clients")}</span>
                    </NavLink>
                  </li>
                  <li className="mm-show">
                    <NavLink
                      to="#"
                      activeClassName={
                        this.hasActiveChild(["/admin/clients/"])
                          ? "mm-active"
                          : ""
                      }
                    >
                      <span>{this.props.t("Client List")}</span>
                    </NavLink>
                    <ul className="sub-menu mm-show">
                      {userData?.role === userRoles.ADMIN && (
                        <li>
                          <NavLink
                            to="/admin/clients/review"
                            activeClassName="mm-active"
                          >
                            <span>{this.props.t("Review Clients")}</span>
                          </NavLink>
                        </li>
                      )}
                      <li>
                        <NavLink
                          to="/admin/clients/approved"
                          activeClassName="mm-active"
                        >
                          <span>{this.props.t("Approved Clients")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/clients/declined"
                          activeClassName="mm-active"
                        >
                          <span>{this.props.t("Declined Clients")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/clients/draft"
                          activeClassName="mm-active"
                        >
                          <span>{this.props.t("Draft Clients")}</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink to="/admin/referrals" activeClassName="mm-active">
                      <span>{this.props.t("Referrals")}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="">
                <NavLink
                  to="/admin/reference-notes"
                  activeClassName="mm-active"
                >
                  <i className="mdi mdi-note" />
                  <span>{this.props.t("Client Notes")}</span>
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="#"
                  activeClassName={
                    this.hasActiveChild(["/department/"]) ? "mm-active" : ""
                  }
                >
                  <i className="mdi mdi-equal-box" />
                  <span>{this.props.t("Departments")}</span>
                </NavLink>
                <ul className="sub-menu mm-show" aria-expanded="true">
                  {userData.role !== userRoles.ADMIN
                    ? userData?.departments?.map((department, index) => {
                        return (
                          <li key={"tempfefde" + index}>
                            <NavLink
                              to={`/department/${
                                departmentObj[department?.name]
                              }`}
                              activeClassName="mm-active"
                            >
                              {this.props.t(
                                capitalizeFirstLetter(
                                  departmentObj[department?.name]
                                ) + " Services"
                              )}
                            </NavLink>
                          </li>
                        );
                      })
                    : departments?.map((department, index) => {
                        return (
                          <li key={"tempfefde" + index}>
                            <NavLink
                              to={`/department/${
                                departmentObj[department?.name]
                              }`}
                              activeClassName="mm-active"
                            >
                              {this.props.t(
                                capitalizeFirstLetter(
                                  departmentObj[department?.name]
                                ) + " Services"
                              )}
                            </NavLink>
                          </li>
                        );
                      })}
                </ul>
              </li>
              <li className="">
                <NavLink
                  to="/admin/client-departure"
                  activeClassName="mm-active"
                >
                  <i className="mdi mdi-calendar-clock" />
                  <span>{this.props.t("Client Departure")}</span>
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/admin/follow-ups" activeClassName="mm-active">
                  <i className="mdi mdi-calendar-check" />
                  <span>{this.props.t("Follow-ups")}</span>
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/admin/notifications" activeClassName="mm-active">
                  <i className="mdi mdi-bell" />
                  <span>{this.props.t("Notifications")}</span>
                </NavLink>
              </li>
            </ul>
            {userData?.role === userRoles.ADMIN && (
              <ul className="list-unstyled" id="side-menu">
                <li className="menu-title">
                  {this.props.t("User Management")}
                </li>
                <li className="">
                  <NavLink
                    to="/admin/users"
                    className={`${
                      this.props.location.pathname === "/admin/users"
                        ? "active-link"
                        : ""
                    }`}
                    activeClassName="mm-active"
                  >
                    <i className="mdi mdi-account-group" />
                    <span>{this.props.t("Users")}</span>
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
