export const subFormKeyScreening = {
    GENERAL: "general",
    IMMIGRATION: "immigration",
    INQUIRY: "inquiry",
};

export const subFormKeyIntake = {
    PERSONAL: "personal",
    INTAKE_IMMIGRATION: "intakeImmigration",
    HEALTH: "health",
    EDUCATION: "education",
    EMPLOYMENT: "employment",
    HOUSING: "housing",
    APPROVAL: "approval",
    // HEALTH_CONSENT: "healthConsent",
    // MH_CONSENT: "mhConsent",
    // MH_RELEASE_CONSENT: "mhReleaseConsent",
};

export const formKey = {
    SCREENING: "screening",
    INTAKE: "intake",
};