import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { connect } from "react-redux";
// import { conversationType, inboxTabTypes } from "constants/constants";
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import { updateUnreadCount } from "store/actions";

//i18n
import { withTranslation } from "react-i18next";
import { axiosGet } from "helpers/api_helpers";
class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // Fetch unread notification count
    this.fetchUnreadNotifications();
  }

  fetchUnreadNotifications = async () => {
    try {
      // Make API call to fetch unread notifications
      const response = await axiosGet("/notification/unread-notifications");
      if (response.status) {
        const unreadCount = response.data.totalResults;
        this.props.updateUnreadCount(unreadCount);
        // Update unread count in Redux store
      } else {
        console.error("Failed to fetch unread notifications");
      }
    } catch (error) {
      console.error("Error fetching unread notifications:", error);
    }
  };

  render() {
    const { unreadCount } = this.props;
    return (
      <React.Fragment>
        <div className="dropdown d-inline-block">
          <Link
            to="/admin/notifications"
            className="btn header-item noti-icon mt-3"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell bx-tada" />
            {unreadCount > 0 && (
              <span className="badge bg-danger rounded-pill">
                {unreadCount}
              </span>
            )}
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

NotificationDropdown.propTypes = {
  updateUnreadCount: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  unreadCount: state.Notifications.unreadCount || 0,
});

const mapDispatchToProps = (dispatch) => ({
  updateUnreadCount: (count) => dispatch(updateUnreadCount(count)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NotificationDropdown));