import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import { FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";
import moment from "moment";
import Breadcrumbs from "components/Common/Breadcrumb";
import { connect } from "react-redux";
import {
  PAGE,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
  capitalizeEachWord,
  screeningFormStatus,
  userRoles,
} from "constants/constants";
import { setShowPageLoader } from "store/actions";
import Limit from "components/pagination/Limit";
import Pagination from "components/pagination/Pagination";
import { axiosGet, axiosPatch } from "helpers/api_helpers";
import { UserContext } from "components/UserProvider/UserProvider";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class FollowUpsDashboard extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "threeMonths",
      userData: {
        threeMonths: [],
        sixMonths: [],
        twelveMonths: [],
      },
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      searchTerm: "",
    };
    this.timer = null;
  }

  componentDidMount() {
    this.handleGetAllClients();
  }

  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllClients();
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetAllClients();
    });
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ userData: {} }, function () {
        this.handleGetAllClients();
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  handleGetAllClients = async () => {
    const { size, page, searchTerm, activeTab } = this.state;
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const { userData } = this.context;
      const response = await axiosGet(
        `/add-client/screening?months=${
          activeTab === "threeMonths" ? 3 : activeTab === "sixMonths" ? 6 : 12
        }&page=${page}&limit=${size}&searchString=${searchTerm}&screeningApprovalStatus=${
          userData.role === userRoles.ADMIN
            ? screeningFormStatus.APPROVED
            : userData.role === userRoles.STAFF
            ? `${screeningFormStatus.APPROVED}`
            : `${screeningFormStatus.APPROVED}`
        }`
      );
      if (response.status) {
        this.setState({
          userData: {
            ...this.state.userData,
            [activeTab]: response.data.results,
          },
          totalSize: response?.data?.totalResults,
        });
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("Error at handleGetAllClients:", error);
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, () => {
        this.handleGetAllClients();
      });
    }
  }

  handleSubmit = async (clientId, activeTab) => {
    const { userData } = this.state;
    const client = userData[activeTab].find(data => data._id === clientId);

    const { type, ...restOfIntake } = client.shelter.intake;
    const data = {
      intakeInfo: {
        ...restOfIntake,
        followUpStatus:
          activeTab === "threeMonths"
            ? "3 months"
            : activeTab === "sixMonths"
            ? "6 months"
            : "12 months",
      },
    };
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/intake/${clientId}`,
        data
      );
      if (response?.status) {
        toast.success("Success!");
        this.handleGetAllClients();
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  renderTable(data = []) {
    const { activeTab } = this.state;

    const tabInNumeric =
      activeTab === "threeMonths"
        ? "3 months"
        : activeTab === "sixMonths"
        ? "6 months"
        : "12 months";

    const columns = [
      {
        dataField: "clientName",
        text: "Client Name",
        sort: true,
        formatter: (cellContent, row) => {
          const fullName = `${row?.screeningForm?.generalInformation?.firstName} ${row?.screeningForm?.generalInformation?.middleName} ${row?.screeningForm?.generalInformation?.lastName}`;
          return <div>{capitalizeEachWord(fullName)}</div>;
        },
      },
      {
        dataField: "shelter.intake.moveOutDate",
        text: "Move Out Date",
        sort: true,
        formatter: cell => moment(cell).format("MM-DD-YYYY"),
      },
      {
        dataField: "daysSinceMoveOut",
        text: "Days Since Moved Out",
        sort: true,
        formatter: (cellContent, row) => {
          const currentDate = moment().startOf("day");
          const moveOutDate = moment(row?.shelter?.intake?.moveOutDate).startOf(
            "day"
          );
          const daysSinceMoveOut = currentDate?.diff(moveOutDate, "days");
          return <div>{daysSinceMoveOut}</div>;
        },
      },
      {
        dataField: "followupStatusAction",
        text: "Action",
        formatter: (cell, row, rowIndex) => (
          <Button
            color="primary"
            disabled={row?.shelter?.intake?.followUpStatus === tabInNumeric}
            onClick={() => this.handleSubmit(row._id, activeTab)}
          >
            {row?.shelter?.intake?.followUpStatus === tabInNumeric ? (
              <span>
                <i className="bx bx-check-double fs-14" /> Mark as Done
              </span>
            ) : (
              <span className="d-flex justify-content-center align-items-center">
                <i className="bx bx-check fs-5 me-1" /> Mark as Done
              </span>
            )}
          </Button>
        ),
      },
      {
        dataField: "followUpStatus",
        text: "Status",
        formatter: (cell, row) => {
          const isDone = row?.shelter?.intake?.followUpStatus === tabInNumeric;
          return isDone ? (
            <div className="d-flex align-items-center text-primary gap-1">
              <i className="bx bx-check-double fs-5"></i>
              <span>Done</span>
            </div>
          ) : (
            <div className="d-flex align-items-center text-primary gap-1 text-opacity-75">
              <i className="bx bx-check fs-5"></i>
              <span>Pending</span>
            </div>
          );
        },
      },
      {
        dataField: "Action",
        isDummyField: true,
        editable: false,
        text: "Edit",
        formatter: (cellContent, row) => (
          <div className="d-flex gap-3">
            <Link className="text-success" to={`/add-clients/${row?._id}`}>
              <i
                className="bx bx-edit font-size-20 text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
              ></i>
            </Link>
          </div>
        ),
      },
    ];

    return (
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Col sm="3">
                <div className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <FormControl
                      className="search-form-control"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      value={this.state.searchTerm}
                      onChange={e => {
                        this.searchUsers(e);
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          this.searchUsers(e);
                        }
                      }}
                    />
                    <span className="search-icon bx bx-search-alt" />
                  </div>
                </div>
              </Col>
              <div
                className={`table-responsive table-striped ${
                  data?.length === 0 ? "display-none" : ""
                }`}
              >
                <BootstrapTable
                  keyField="_id"
                  data={data}
                  columns={columns}
                  bordered={false}
                  striped={false}
                  responsive
                  // selectRow={{ mode: "checkbox", clickToSelect: true }}
                  classes={"table align-middle table-nowrap"}
                />
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Limit
                    value={this.state.size}
                    onChange={this.handleLimitChange}
                  />
                  <Pagination
                    totalSize={this.state.totalSize}
                    handlePageClick={this.handlePageClick}
                    currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                    currentSize={this.state.size} // Use selectedLimit for current size
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  render() {
    const { userData, activeTab } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Follow-ups" breadcrumbItem="Follow-ups List" />
            <div>
              <Nav tabs fill>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "threeMonths",
                    })}
                    onClick={() => {
                      this.toggleTab("threeMonths");
                    }}
                  >
                    3 Months
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "sixMonths",
                    })}
                    onClick={() => {
                      this.toggleTab("sixMonths");
                    }}
                  >
                    6 Months
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "twelveMonths",
                    })}
                    onClick={() => {
                      this.toggleTab("twelveMonths");
                    }}
                  >
                    12 Months
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={activeTab}
                className="mt-3 ms-2"
                style={{ maxHeight: "50vh" }}
              >
                <TabPane tabId="threeMonths">
                  {this.renderTable(userData.threeMonths)}
                </TabPane>
                <TabPane tabId="sixMonths">
                  {this.renderTable(userData.sixMonths)}
                </TabPane>
                <TabPane tabId="twelveMonths">
                  {this.renderTable(userData.twelveMonths)}
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  // unreadCount: state.Notifications.unreadCount || 0,
});

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpsDashboard);
