import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";

const validationSchema = Yup.object().shape({
  check3Months: Yup.boolean().required("Required"),
  check6Months: Yup.boolean().required("Required"),
  check12Months: Yup.boolean().required("Required"),
  date3Months: Yup.date().required("Required"),
  date6Months: Yup.date().required("Required"),
  date12Months: Yup.date().required("Required"),
  referenceNote: Yup.string().required("Required"),
});

const initialValues = {};

class FollowUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        check3Months: false,
        check6Months: false,
        check12Months: false,
        date3Months: "",
        date6Months: "",
        date12Months: "",
        referenceNote: "",
      },
    };
  }

  componentDidMount = async () => {
    // this.setState({
    //   initialValues: {},
    // });
  };

  handleSubmit = async values => {};

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              setFieldTouched,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Follow-ups</h4>
                        </Col>
                        <Col md="6">
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="check3Months" className="form-label">
                                <Field
                                  id="check3Months"
                                  name="check3Months"
                                  type="checkbox"
                                  className={
                                    "form-check-input mr-2" +
                                    (errors?.check3Months &&
                                    touched?.check3Months
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {" "}3 months
                              </Label>
                              <Field
                                id="date3Months"
                                name="date3Months"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.date3Months && touched?.date3Months
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter 3 months date"
                              />
                              <ErrorMessage
                                name="date3Months"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="check6Months" className="form-label">
                                <Field
                                  id="check6Months"
                                  name="check6Months"
                                  type="checkbox"
                                  className={
                                    "form-check-input" +
                                    (errors?.check6Months &&
                                    touched?.check6Months
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {" "}6 months
                              </Label>
                              <Field
                                id="date6Months"
                                name="date6Months"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.date6Months && touched?.date6Months
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter 6 months date"
                              />
                              <ErrorMessage
                                name="date6Months"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label for="check12Months" className="form-label">
                                <Field
                                  id="check12Months"
                                  name="check12Months"
                                  type="checkbox"
                                  className={
                                    "form-check-input mr-2" +
                                    (errors?.check12Months &&
                                    touched?.check12Months
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {" "}12 months
                              </Label>
                              <Field
                                id="date12Months"
                                name="date12Months"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.date12Months && touched?.date12Months
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter 12 months date"
                              />
                              <ErrorMessage
                                name="date12Months"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="9">
                          <div className="mb-3">
                            <Label for="referenceNote" className="form-label">
                              Reference Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referenceNote"
                              name="referenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referenceNote && touched?.referenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              cols={100}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FollowUps));
