import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { countries } from "countries-list";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { setShowPageLoader } from "store/actions";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import formatDateToYYYYMMDD from "utils/formatDate";

const validationSchema = Yup.object().shape({
  details: Yup.array().of(
    Yup.object().shape({
      type: Yup.object().nullable().required("Required"),
      other: Yup.string().when("type.value", {
        is: "Other",
        then: Yup.string().required("Please mention type"),
        otherwise: Yup.string().nullable(),
      }),
      date: Yup.date().required("Required"),
      agency: Yup.string().required("Required"),
    })
  ),
});

const initialValues = {};

const REFER_TYPES = [
  { label: "Faith", value: "Faith" },
  { label: "Culture", value: "Culture" },
  { label: "Community", value: "Community" },
  { label: "Other", value: "Other" },
];

class Referrals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        details: [
          {
            type: null,
            other: "",
            date: "",
            agency: "",
          },
        ],
      },
    };
  }

  componentDidMount = async () => {
    const { clientData } = this.props;
    this.setState({
      initialValues: {
        details:
          clientData?.shelter?.referrals?.details.map(detail => ({
            type: {
              label: detail?.type || "",
              value: detail?.type || "",
            },
            other: detail?.other || "",
            date: formatDateToYYYYMMDD(detail?.date) || "",
            agency: detail?.agency || "",
          })) || [],
      },
    });
  };

  handleAddReferralsData = async referralsInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/referrals/${clientData?.id}`,
        referralsInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Referrals information stored successfully!"
        );
        handleGetClient();
        toggle("");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      referralsInfo: {
        details: values?.details.map(detail => ({
          type: detail?.type.value,
          ...(detail?.other && { other: detail.other }),
          date: detail?.date,
          agency: detail?.agency,
        })),
      },
    };

    this.handleAddReferralsData(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              setFieldTouched,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Referral Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="mb-3">
                            <FieldArray name="details">
                              {({ insert, remove, push }) => (
                                <div>
                                  {values?.details?.length > 0 &&
                                    values?.details?.map((item, index) => (
                                      <React.Fragment key={index}>
                                        <div className="d-flex">
                                          <div className="col-md-4 mb-3 me-3">
                                            <label
                                              htmlFor={`type${index}`}
                                              className="form-label"
                                            >
                                              Type
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Select
                                              id={`typeOptionLabel${index}`}
                                              name={`details.${index}.type`}
                                              options={REFER_TYPES}
                                              value={values.details[index].type}
                                              className={`${
                                                errors.details?.[index]?.type &&
                                                touched.details?.[index]?.type
                                                  ? " is-invalid"
                                                  : ""
                                              }`}
                                              onChange={selectedOption => {
                                                setFieldValue(
                                                  `details.${index}.type`,
                                                  selectedOption
                                                );
                                                setFieldTouched(
                                                  `details.${index}.type`,
                                                  true
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`details.${index}.type`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                          {values.details[index].type?.value ===
                                            "Other" && (
                                            <Col md="4">
                                              <label
                                                htmlFor={`other${index}`}
                                                className="form-label"
                                              >
                                                Other
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                id={`other${index}`}
                                                name={`details.${index}.other`}
                                                type="text"
                                                className={`form-control${
                                                  errors.details?.[index]
                                                    ?.other &&
                                                  touched.details?.[index]
                                                    ?.other
                                                    ? " is-invalid"
                                                    : ""
                                                }`}
                                              />
                                              <ErrorMessage
                                                name={`details.${index}.other`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </Col>
                                          )}
                                        </div>
                                        <Col md="12">
                                          <div className="d-flex">
                                            <div className="col-md-4 mb-3 me-3">
                                              <label
                                                htmlFor={`date${index}`}
                                                className="form-label"
                                              >
                                                Refer Date
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                id={`date${index}`}
                                                name={`details.${index}.date`}
                                                type="date"
                                                className={`form-control${
                                                  errors.details?.[index]
                                                    ?.date &&
                                                  touched.details?.[index]?.date
                                                    ? " is-invalid"
                                                    : ""
                                                }`}
                                              />
                                              <ErrorMessage
                                                name={`details.${index}.date`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>

                                            <div className="col-md-4 mb-3">
                                              <label
                                                htmlFor={`agency${index}`}
                                                className="form-label"
                                              >
                                                Agency
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                id={`agency${index}`}
                                                name={`details.${index}.agency`}
                                                type="text"
                                                className={`form-control${
                                                  errors.details?.[index]
                                                    ?.agency &&
                                                  touched.details?.[index]
                                                    ?.agency
                                                    ? " is-invalid"
                                                    : ""
                                                }`}
                                              />
                                              <ErrorMessage
                                                name={`details.${index}.agency`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>

                                            <div className="d-flex align-items-center ms-3 mt-2">
                                              <button
                                                type="button"
                                                className="btn btn-danger p-1"
                                                onClick={() => remove(index)}
                                              >
                                                <i className="fs-5 bx bx-trash-alt"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </Col>
                                      </React.Fragment>
                                    ))}
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      push({ type: null, date: "", agency: "" })
                                    }
                                  >
                                    Add Refer Details
                                  </button>
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Referrals));
