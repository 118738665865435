import { TOGGLE_GENERAL_INFO , TOGGLE_IMMIGRATION_INFO, TOGGLE_INQUIRY_INFO,TOGGLE_INTAKE_EMPLOYMENT_HISTORY,TOGGLE_INTAKE_IMMIGRATION_INFO,TOGGLE_PERSONAL_INFO,TOGGLE_INTAKE_HEALTH_INFO, TOGGLE_INTAKE_EDUCATIONAL_BACKGROUND, TOGGLE_INTAKE_HOUSING_SECTION } from "./actionType";

const INIT_STATE = {
  generalInfo: false,
  immigrationInfo: false,
  inquiryInfo: false,
  personalInfo:false,
  intakeImmigrationInfo:false,
  intakeHealthInfo:false,
  intakeEducationalBackground:false,
  intakeEmploymentHistory:false,
  intakeHousingSection:false,
};

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_GENERAL_INFO:
      return { ...state, generalInfo: !state.generalInfo };
    case TOGGLE_IMMIGRATION_INFO:
      return { ...state, immigrationInfo: !state.immigrationInfo };
    case TOGGLE_INQUIRY_INFO:
      return { ...state, inquiryInfo: !state.inquiryInfo };
    case TOGGLE_PERSONAL_INFO:
      return { ...state, personalInfo: !state.personalInfo };
    case TOGGLE_INTAKE_IMMIGRATION_INFO:
      return { ...state, intakeImmigrationInfo: !state.intakeImmigrationInfo };
    case TOGGLE_INTAKE_HEALTH_INFO:
        return { ...state, intakeHealthInfo: !state.intakeHealthInfo };
    case TOGGLE_INTAKE_EDUCATIONAL_BACKGROUND:
          return { ...state, intakeEducationalBackground: !state.intakeEducationalBackground };   
    case TOGGLE_INTAKE_EMPLOYMENT_HISTORY:
            return { ...state, intakeEmploymentHistory: !state.intakeEmploymentHistory }; 
    case TOGGLE_INTAKE_HOUSING_SECTION:
              return { ...state, intakeHousingSection: !state.intakeHousingSection };            
    default:
      return state;
  }
};

export default Clients;