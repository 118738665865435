import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
import { facebook, google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { showToast } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import { axiosPatch } from "helpers/api_helpers";
import { SOMETHING_WENT_WRONG } from "constants/constants";

import Breadcrumb from "../../components/Common/Breadcrumb";
import { UserContext } from "components/UserProvider/UserProvider";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenNotFound: false,
      showOldPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      error: "",
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (!this.props?.match?.params?.token) {
      this.setState({ tokenNotFound: true });
    }
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    }
  };

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google");
  };

  //handleTwitterLoginResponse
  twitterResponse = () => {};

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook");
  };

  handleChangePassword = async values => {
    try {
      const { userData } = this.context;
      const response = await axiosPatch(
        `/users/change-password/${userData?.id}`,
        {
          oldPassword: values?.oldPassword,
          password: values?.password,
        }
      );

      if (response?.status) {
        this.props.showToast({
          type: "success",
          message: response?.message || "Change password successfully",
          title: "Change Password",
          duration: 3000,
        });
        this.props.history.push("/add-clients");
      } else {
        console.error("ChangePassword API error: ", response?.message);
        this.setState({ error: response?.message || SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      console.error("ChangePassword error: ", error);
      this.setState({ error: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  handleStates = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { showConfirmPassword, showPassword, showOldPassword, error } =
      this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Cloudmate" breadcrumbItem="Change Password" />
            <Row className="justify-content-center mt-4">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0 mt-4">
                    <div className="p-2">
                      {error && error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          oldPassword: this.state && this.state.oldPassword,
                          password: this.state && this.state.password,
                          confirmPassword:
                            this.state && this.state.confirmPassword,
                        }}
                        validationSchema={Yup.object().shape({
                          oldPassword: Yup.string().required(
                            "Old password is required"
                          ),
                          password: Yup.string()
                            .notOneOf(
                              [Yup.ref("oldPassword"), null],
                              "New Password cannot be the same as Old Password"
                            )
                            .required("New Password is required"),
                          confirmPassword: Yup.string()
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            )
                            .required("Confirm Password is required"),
                        })}
                        onSubmit={this.handleChangePassword}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="oldPassword" className="form-label">
                                Old Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="oldPassword"
                                  type={showOldPassword ? "text" : "password"}
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.oldPassword && touched.oldPassword
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    this.handleStates(
                                      "showOldPassword",
                                      !showOldPassword
                                    )
                                  }
                                >
                                  {showOldPassword ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                <ErrorMessage
                                  name="oldPassword"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="mb-3 mt-4">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    this.handleStates(
                                      "showPassword",
                                      !showPassword
                                    )
                                  }
                                >
                                  {showPassword ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Label
                                for="confirmPassword"
                                className="form-label"
                              >
                                Confirm Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="confirmPassword"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.confirmPassword &&
                                    touched.confirmPassword
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    this.handleStates(
                                      "showConfirmPassword",
                                      !showConfirmPassword
                                    )
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <i className="mdi mdi-eye-outline"></i>
                                  ) : (
                                    <i className="mdi mdi-eye-off-outline"></i>
                                  )}
                                </button>
                                <ErrorMessage
                                  name="confirmPassword"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Change Password
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ChangePassword.propTypes = {};

const mapStateToProps = state => {};

export default withRouter(
  connect(mapStateToProps, { showToast })(ChangePassword)
);
