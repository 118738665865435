import React from "react";
import { Redirect } from "react-router-dom";

// // User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import ResetPassword from "pages/Authentication/ResetPassword";
import ChangePassword from "pages/Authentication/ChangePassword";
// import CustomerTable from "pages/Customers/CustomerTable";
import CustomerTableNew from "pages/Customers/CustomerTableNew";
import CommonPage from "pages/Clients/AddClients/CommonPage"
import Dashboard from "pages/UserManagement/Clients/ReviewDashboard"
import Users from "pages/UserManagement/User/Users";
import ReviewDashboard from "pages/UserManagement/Clients/ReviewDashboard";
import ApprovedDashboard from "pages/UserManagement/Clients/ApprovedDashboard";
import DeclinedDashboard from "pages/UserManagement/Clients/DeclinedDashboard";
import DraftDashboard from "pages/UserManagement/Clients/DraftDashboard";
import Shelter from "pages/Departments/Shelter";
import Shettlement from "pages/Departments/Shettlement";
import Orientation from "pages/Departments/Orientation";
import NotificationDashboard from "pages/NotificationManagement/NotificationDashboard";
import ReferenceNotesDashboard from "pages/ReferenceNotes/ReferenceNotesDashboard";
import ReferralDepartment from "pages/ReferralDepartment/ReferralDepartment";
import OrientationForm from "pages/Departments/DepartmentForms/Orientation/OrientationForm";
import ShelterForm from "pages/Departments/DepartmentForms/Shelter/ShelterForm";
import SettlementForm from "pages/Departments/DepartmentForms/Settlememt/SettlementForm";
import FollowUpsDashboard from "pages/FollowUps/FollowUpsDashboard";
import ClientDepartureDashboard from "pages/ClientDeparture/ClientDepartureDashboard";


const authProtectedRoutes = [
  // Customers routes
  { path: "/", component: CommonPage },
  { path: "/add-clients", component: CommonPage },
  { path: "/add-clients", component: CommonPage },
  { path: "/add-clients/:clientId", component: CommonPage },
  { path: "/search-clients", component: CommonPage },

  // Super Admin
  { path: "/admin/clients", component: Dashboard },
  { path: "/admin/users", component: Users },
  { path: "/admin/clients/review", component: ReviewDashboard },
  { path: "/admin/clients/approved", component: ApprovedDashboard },
  { path: "/admin/clients/declined", component: DeclinedDashboard },
  { path: "/admin/clients/draft", component: DraftDashboard },
  { path: "/admin/notifications", component: NotificationDashboard },
  { path: "/admin/reference-notes", component: ReferenceNotesDashboard },
  { path: "/admin/referrals", component: ReferralDepartment },
  { path: "/admin/follow-ups", component: FollowUpsDashboard },
  { path: "/admin/client-departure", component: ClientDepartureDashboard },
  
  //Departments
  { path: "/department/shelter", component: Shelter },
  { path: "/department/settlement", component: Shettlement },
  { path: "/department/orientation", component: Orientation },
  { path: "/department/settlement/:clientId", component: SettlementForm },
  { path: "/department/shelter/:clientId", component: ShelterForm },
  { path: "/department/orientation/:clientId", component: OrientationForm },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/change-password", component: ChangePassword },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/auth-email-verification/:token", component: EmailVerification },
  { path: "/auth-email-verification", component: EmailVerification },
];

export { authProtectedRoutes, publicRoutes };
