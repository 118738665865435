import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { countries } from "countries-list";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { formKey, settlementSubFormKey } from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  referrenceNote: Yup.string().required("Required"),
  eligibilityHearingCompleted: Yup.string().required("Required"),
  hearingDate: Yup.date().when("eligibilityHearingCompleted", {
    is: "yes",
    then: Yup.date().required("Required"),
  }),
  hearingResult: Yup.string().when("eligibilityHearingCompleted", {
    is: "yes",
    then: Yup.string().required("Required"),
  }),
  bocPifDueDate: Yup.string().when("eligibilityHearingCompleted", {
    is: "no",
    then: Yup.string().required("Required"),
  }),
  bocPifSubmission: Yup.string().when("eligibilityHearingCompleted", {
    is: "no",
    then: Yup.string().required("Required"),
  }),
});

const initialValues = {};

class Lawyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        eligibilityHearingCompleted: "",
        hearingDate: "",
        hearingResult: "",
        bocPifSubmission: "",
        bocPifDueDate: "",
        referrenceNote: "",
      },
    };
  }

  formatDateToYYYYMMDD = date => {
    if (!date) return "";
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SETTLEMENT &&
          note.subForm === settlementSubFormKey.LAWYER &&
          (!latestNote || new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    const hearingDate = this.formatDateToYYYYMMDD(
      clientData?.settlement?.lawyer?.hearingDate
    );
    const bocPifDueDate = this.formatDateToYYYYMMDD(
      clientData?.settlement?.lawyer?.bocPifDueDate
    );
    this.setState({
      initialValues: {
        eligibilityHearingCompleted:
          clientData?.settlement?.lawyer?.eligibilityHearingCompleted || "",
        hearingDate: hearingDate || "",
        hearingResult: clientData?.settlement?.lawyer?.resultOfHearing || "",
        bocPifSubmission:
          clientData?.settlement?.lawyer?.bocPifSubmitted ||
          clientData?.intakeImmigrationForm?.bocText ||
          "",
        bocPifDueDate:
          bocPifDueDate ||
          this.formatDateToYYYYMMDD(
            clientData?.intakeImmigrationForm?.bocDate
          ) ||
          "",
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddSettlementLawyerInfo = async data => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/settlement/lawyer/${clientData?.id}`,
        data
      );
      if (response?.status) {
        toast.success(
          response?.message || "Lawyer information stored successfully!"
        );
        handleGetClient();
        toggle("3");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error handleAddSettlementLawyerInfo: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      lawyerInfo: {
        eligibilityHearingCompleted: values?.eligibilityHearingCompleted,
        ...(values?.bocPifDueDate && { bocPifDueDate: values.bocPifDueDate }),
        ...(values?.bocPifSubmission && {
          bocPifSubmitted: values.bocPifSubmission,
        }),
        ...(values?.hearingDate && { hearingDate: values.hearingDate }),
        ...(values?.hearingResult && { resultOfHearing: values.hearingResult }),
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddSettlementLawyerInfo(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Lawyer</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-9 mb-3 me-3 d-flex">
                            <label
                              htmlFor="eligibilityHearingCompleted"
                              className="form-label me-3"
                            >
                              Eligibility Hearing Completed?
                              <span className="text-danger"> *</span>
                            </label>
                            <div
                              role="group"
                              aria-labelledby="eligibilityHearingCompleted"
                              className="form-check"
                            >
                              <label className="me-5">
                                <Field
                                  id="eligibilityHearingCompleted"
                                  name="eligibilityHearingCompleted"
                                  type="radio"
                                  value="yes"
                                  className="form-check-input"
                                />
                                Yes
                              </label>
                              <label>
                                <Field
                                  id="eligibilityHearingCompleted"
                                  name="eligibilityHearingCompleted"
                                  type="radio"
                                  value="no"
                                  className="form-check-input"
                                />
                                No
                              </label>
                            </div>
                            <ErrorMessage
                              name="eligibilityHearingCompleted"
                              component="div"
                              className={
                                values?.eligibilityHearingCompleted
                                  ? "invalid-feedback"
                                  : "text-danger ms-4"
                              }
                            />
                          </div>
                          {values?.eligibilityHearingCompleted === "yes" ? (
                            <div className="d-flex mb-3">
                              <div className="col-md-4 me-3">
                                <Label for="hearingDate" className="form-label">
                                  Hearing Date
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  id="hearingDate"
                                  name="hearingDate"
                                  type="date"
                                  className={
                                    "form-control" +
                                    (errors?.hearingDate && touched?.hearingDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                  //   max={new Date().toLocaleDateString("en-CA", {
                                  //     timeZone: "America/Toronto",
                                  //   })}
                                />
                                <ErrorMessage
                                  name="hearingDate"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-4 me-3">
                                <Label
                                  for="hearingResult"
                                  className="form-label"
                                >
                                  Result of hearing
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  id="hearingResult"
                                  name="hearingResult"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.hearingResult &&
                                    touched?.hearingResult
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                />
                                <ErrorMessage
                                  name="hearingResult"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          ) : null}
                          {values?.eligibilityHearingCompleted === "no" ? (
                            <div className="d-flex">
                              <div className="col-md-4 me-3">
                                <Label
                                  for="bocPifDueDate"
                                  className="form-label"
                                >
                                  Due date of BOC/PIF
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  id="bocPifDueDate"
                                  name="bocPifDueDate"
                                  type="date"
                                  className={
                                    "form-control" +
                                    (errors?.bocPifDueDate &&
                                    touched?.bocPifDueDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                  //   max={new Date().toLocaleDateString("en-CA", {
                                  //     timeZone: "America/Toronto",
                                  //   })}
                                />
                                <ErrorMessage
                                  name="bocPifDueDate"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-4 me-3">
                                <Label
                                  for="bocPifSubmission"
                                  className="form-label"
                                >
                                  BOC/ PIF Submission Date
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  id="bocPifSubmission"
                                  name="bocPifSubmission"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors?.bocPifSubmission &&
                                    touched?.bocPifSubmission
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                  max={new Date().toLocaleDateString("en-CA", {
                                    timeZone: "America/Toronto",
                                  })}
                                />
                                <ErrorMessage
                                  name="bocPifSubmission"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                              max={new Date().toLocaleDateString("en-CA", {
                                timeZone: "America/Toronto",
                              })}
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Lawyer));
