import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { countries } from "countries-list";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import formatDateToYYYYMMDD from "utils/formatDate";

const validationSchema = Yup.object().shape({
  chrAppDate: Yup.date().required("Required"),
  appStatus: Yup.string().required("Required"),
  marketReferralDate: Yup.date().required("Required"),
  landLordName: Yup.string().required("Required"),
});

class Housing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        chrAppDate: "",
        appStatus: "",
        marketReferralDate: "",
        landLordName: "",
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;

    this.setState({
      initialValues: {
        chrAppDate: formatDateToYYYYMMDD(clientData?.shelter?.housing?.chrAppDate) || "",
        appStatus: clientData?.shelter?.housing?.appStatus || "",
        marketReferralDate: formatDateToYYYYMMDD(clientData?.shelter?.housing?.marketReferralDate) || "",
        landLordName: clientData?.shelter?.housing?.landLordName || "",
      },
    });
  };

  handleAddHousingData = async housingInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } = this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/housing/${clientData?.id}`,
        housingInfo
      );
      if (response?.status) {
        toast.success(response?.message || "Housing information stored successfully!");
        handleGetClient();
        toggle("7");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };
  
  handleSubmit = async values => {
    const data = {
      housingInfo: {
        chrAppDate: values?.chrAppDate,
        appStatus: values?.appStatus,
        marketReferralDate: values?.marketReferralDate,
        landLordName: values?.landLordName,
      },
    };
  
    this.handleAddHousingData(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Housing Information</h4>
                        </Col>
                        <Col md="9">
                          <div className="d-flex mb-3">
                            <div className="col-md-4 me-3">
                              <Label for="chrAppDate" className="form-label">
                                CHR-App Date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="chrAppDate"
                                name="chrAppDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.chrAppDate && touched?.chrAppDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                max={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="chrAppDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4">
                              <Label for="appStatus" className="form-label">
                                Status
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="appStatus"
                                name="appStatus"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.appStatus && touched?.appStatus
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name="appStatus"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label
                                for="marketReferralDate"
                                className="form-label"
                              >
                                Market Referral Date
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="marketReferralDate"
                                name="marketReferralDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.marketReferralDate &&
                                  touched?.marketReferralDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                max={new Date().toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              />
                              <ErrorMessage
                                name="marketReferralDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label for="landLordName" className="form-label">
                                Landlord Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="landLordName"
                                name="landLordName"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.landLordName && touched?.landLordName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                              />
                              <ErrorMessage
                                name="landLordName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Housing));
