import {
  ADD_NOTIFICATION,
  SET_SELECTED_NOTIFICATION,
  UPDATE_NOTIFICATION,
  SET_ACTIVE_TAB,
  // UPDATE_NOTIFICATION_DROPDOWN,
  // UPDATE_NOTIFICATION_DASHBOARD,
  UPDATE_UNREAD_COUNT
} from "./actionTypes";

const initialState = {
  notifications: [],
  selectedNotification: null,
  activeTab: null,
  unreadCount: 0
};

const Notifications = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload
      };
    case SET_SELECTED_NOTIFICATION:
      return { ...state, selectedNotification: action.payload };
    case UPDATE_NOTIFICATION:
      return { ...state, notifications: action.payload };
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
      case UPDATE_UNREAD_COUNT:
        return { ...state, unreadCount: action.payload };
    default:
      return state;
  }
};

export default Notifications;
