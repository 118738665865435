import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formKey, status, subFormKeyIntake } from "constants/constants";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment/moment";
import oldlogo from "../../assets/images/oldlogo.png";
import Select from "react-select";
import { formStatus } from "constants/constants";

class IntakeMHReleaseConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.memberRefs = [];
    this.signatureRef = React.createRef();
    this.signatureRefWitness = React.createRef();
    this.signatureRefNurse = React.createRef();
  }
  componentDidMount = async () => {
    const { clientData } = this.props;
    try {
      const leadClientData = {
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
        email: clientData.email,
        phoneNumber: clientData.phoneNumber,
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };
  handleSubmit = async values => {
    const { clientId, getClientData } = this.props;

    if (clientId) {
      try {
        const requestBody = {
          type: {
            is_completed: true,
            form_key: formKey.INTAKE,
          },
          intakeMHReleaseConsentInformation: {
            type: {
              subForm_key: subFormKeyIntake.MH_RELEASE_CONSENT,
              formStatus: status.COMPLETED,
            },
            agreeToTerms: values.agreeToTerms,
            clientSignature: values.clientSignature,
            witnessSignature: values.witnessSignature,
            nurseSignature: values.nurseSignature,
            primaryName: values.primaryName,
            witnessName: values.witnessName,
            agencyName: values.agencyName,
            clientDate: values.clientDate,
            witnessDate: values.witnessDate,
            nurseDate: values.nurseDate,
          },
        };

        const response = await axiosPatch(
          `/add-client/intake/${clientId}/mhReleaseConsent`,
          requestBody
        );
        if (response?.status) {
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
        } else {
          toast.error("handleSubmit API error", response?.message);
        }
      } catch (error) {
        toast.error("handleSubmit API error", error);
      }
    }
  };

  render() {
    const { intakeData, clientData } = this.props;
    const initialValues = {
      clientSignature: intakeData?.clientSignature || "",
      witnessSignature: intakeData?.witnessSignature || "",
      nurseSignature: intakeData?.nurseSignature || "",
      primaryName:
        intakeData?.primaryName ||
        `${clientData.firstName} ${clientData.middleName} ${clientData.lastName}` ||
        "",
      witnessName: intakeData?.witnessName || "",
      agencyName: intakeData?.agencyName || "",
      clientDate:
        (intakeData?.clientDate
          ? new Date(intakeData?.clientDate).toISOString().split("T")[0]
          : "") || "",
      witnessDate:
        (intakeData?.witnessDate
          ? new Date(intakeData?.witnessDate).toISOString().split("T")[0]
          : "") || "",
      nurseDate:
        (intakeData?.nurseDate
          ? new Date(intakeData?.nurseDate).toISOString().split("T")[0]
          : "") || "",
      agreeToTerms: intakeData?.agreeToTerms || "",
    };

    const validationSchema = Yup.object().shape({
      witnessSignature: Yup.string().required("Signature  is required"),
      clientSignature: Yup.string().required("Signature  is required"),
      nurseSignature: Yup.string().required("Signature  is required"),
      primaryName: Yup.string().required("Primary name is required"),
      agencyName: Yup.string().required("Agency name is required"),
      witnessName: Yup.string().required("Witness name is required"),
      clientDate: Yup.string().required("Client date  is required"),
      nurseDate: Yup.string().required("Nurse signed date  is required"),
      witnessDate: Yup.string().required("Witness date  is required"),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div className="consent-text-release">
                      <div className="d-flex justify-content-center align-items-center mb-4">
                        <img src={oldlogo} alt="old-logo" className="ml-4" />
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <h6 className="form-label fw-normal mt-0">
                          Newcomer Health Clinic
                        </h6>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <h6 className="form-label fw-normal">
                          3185 Forest Glade Dr., Windsor, ON N8R 1W7
                        </h6>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <h6 className="form-label fw-normal">
                          Phone: (519) 945-7627
                        </h6>
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                        <h4 className="mt-5">
                          Authorization for Release of Information
                        </h4>
                      </div>

                      <div className="consent-text mt-4">
                        <Label className="form-label fw-normal">
                          I,{" "}
                          <b>
                            <u>{values?.primaryName}</u>
                          </b>{" "}
                          , the undersigned, hereby authorize the following
                          individuals or organizations, to release medical
                          information, currently held confidential. and{" "}
                          <b>
                            <u>{values?.agencyName}.</u>
                          </b>
                          <br />
                          <p className="mt-2">
                            Note: I understand that this release is valid until{" "}
                            <b>
                              <u>
                                {new Date(
                                  values?.clientDate
                                ).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto",
                                })}
                              </u>
                            </b>
                            . I further understand that I may cancel or revoke
                            this authorization at any time in writing.
                          </p>
                          <p className="top-m">
                            By signing below, I authorize the above agencies to
                            share relevant information for the following
                            purpose:
                          </p>
                          <div className="d-flex flex-column">
                            <div className="d-flex gap-4 mt-2 flex-row align-items-center">
                              <div className="">
                                Client Signature: <br />
                                {intakeData?.clientSignature ? (
                                  <img
                                    src={values?.clientSignature}
                                    alt="clientSignature"
                                    className="canvas mt-3"
                                  />
                                ) : (
                                  "__________________"
                                )}
                              </div>
                              <div>
                                Date:{" "}
                                <b>
                                  <u>
                                    {new Date(
                                      values?.clientDate
                                    ).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto",
                                    })}
                                  </u>
                                </b>
                              </div>
                            </div>
                            <div className="d-flex gap-4  align-items-start mt-2">
                              <div className="">
                                Witness Signature: <br />
                                <br />
                                {intakeData?.witnessSignature ? (
                                  <img
                                    src={values?.witnessSignature}
                                    alt="witnessSignature"
                                    className="canvas"
                                  />
                                ) : (
                                  "__________________"
                                )}
                              </div>
                              <div>
                                <div>
                                  Witness Name:{" "}
                                  <b>
                                    <u>{values?.witnessName}</u>
                                    <br></br>

                                    <br></br>
                                  </b>
                                </div>
                                <div>
                                  Date:{"   "}
                                  <b>
                                    <u>
                                      {new Date(
                                        values?.witnessDate
                                      ).toLocaleDateString("en-CA", {
                                        timeZone: "America/Toronto",
                                      })}
                                    </u>
                                  </b>
                                </div>
                              </div>

                              <div></div>
                            </div>

                            <div className="d-flex flex-row gap-0 align-items-center mt-2">
                              <div>
                                Nurse Practitioner Signature: {"  "}
                                <br />
                                <br></br>
                                {intakeData?.nurseSignature ? (
                                  <img
                                    src={values?.nurseSignature}
                                    alt="nurseSignature"
                                    className="canvas"
                                  />
                                ) : (
                                  "__________________"
                                )}
                              </div>
                              <div className="ms-n2">
                                Date: {"    "}{" "}
                                <b>
                                  <u>
                                    {new Date(
                                      values?.nurseDate
                                    ).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto",
                                    })}
                                  </u>
                                </b>
                              </div>
                            </div>
                          </div>
                        </Label>
                      </div>
                    </div>
                    <hr />
                    <div className="pad-left">
                      <Row>
                        <Col>
                          <div className="d-flex gap-2 align-items-start">
                            <div>
                              <Field
                                type="checkbox"
                                name="agreeToTerms"
                                id="agreeToTerms1"
                                className={
                                  errors.agreeToTerms && touched.agreeToTerms
                                    ? " is-invalid"
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <Label
                                htmlFor="agreeToTerms1"
                                style={{ cursor: "pointer" }}
                              >
                                I understand and agree with the foregoing
                                information and release
                              </Label>
                            </div>
                          </div>

                          <ErrorMessage
                            name="agreeToTerms"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                      </Row>
                      <div className="d-flex gap-1 align-items-start mt-3">
                        <div className="col-md-3">
                          <Label for="primaryName" className="form-label">
                            Primary resident name {"     "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            type="text"
                            name="primaryName"
                            className={
                              "form-control width-gap" +
                              (errors.primaryName && touched.primaryName
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter primary resident name"
                          ></Field>
                          <ErrorMessage
                            name="primaryName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                        <div className="col-md-3">
                          <Label className="form-label">
                            Person/Agency name/Others {"     "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            type="text"
                            name="agencyName"
                            className={
                              "form-control width-gap" +
                              (errors.agencyName && touched.agencyName
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter the name"
                          ></Field>
                          <ErrorMessage
                            name="agencyName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                        <div className="col-md-3 ">
                          <Label className="form-label">
                            Witness name {"     "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            type="text"
                            name="witnessName"
                            className={
                              "form-control width-gap" +
                              (errors.witnessName && touched.witnessName
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter witness name"
                          ></Field>
                          <ErrorMessage
                            name="witnessName"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-start mt-3">
                        <div className="gap-consent-sign-1">
                          <div className="d-flex gap-3 align-items-center">
                            <div>
                              <Label
                                className="form-label"
                                for="clientSignature"
                              >
                                Client signature
                                <span className="text-danger">*</span>
                              </Label>

                              {values?.clientSignature ? (
                                <img
                                  src={values?.clientSignature}
                                  alt="clientSignature"
                                  className="canvas"
                                  onError={event => {
                                    // Handle potential errors if the image fails to load
                                    event.target.style.display = "none";
                                    console.error(
                                      "Failed to Signature image:",
                                      values?.clientSignature
                                    );
                                  }}
                                />
                              ) : (
                                <Field name="clientSignature">
                                  {({ field }) => (
                                    <>
                                      <SignatureCanvas
                                        ref={this?.signatureRef}
                                        canvasProps={{
                                          className: "canvas",
                                          width: 150,
                                          height: 55,
                                        }}
                                        onEnd={() => {
                                          const data =
                                            this?.signatureRef?.current?.toDataURL();
                                          if (data) {
                                            setFieldValue(
                                              `clientSignature`,
                                              data
                                            );
                                          } else {
                                            setFieldValue(
                                              `clientSignature`,
                                              ""
                                            );
                                          }
                                        }}
                                        {...field}
                                      />
                                      <ErrorMessage
                                        name="clientSignature"
                                        component="div"
                                        className={`invalid-feedback text-danger ${
                                          errors.clientSignature
                                            ? "d-block"
                                            : ""
                                        }`}
                                      />
                                    </>
                                  )}
                                </Field>
                              )}
                            </div>
                            <div>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-secondary p-1 pl-2 font-sm"
                                  onClick={() => {
                                    // Clear the image and show the signature canvas
                                    setFieldValue(
                                      `clientSignature`,
                                      "" // Set the field value to an empty string
                                    );
                                    this?.signatureRef?.current?.clear(); // Clear the signature canvas
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="gap-consent-sign-1">
                          <div className="d-flex gap-3 align-items-center">
                            <div>
                              <Label className="form-label">
                                Witness signature
                                <span className="text-danger">*</span>
                              </Label>
                              {values?.witnessSignature ? (
                                <img
                                  src={values?.witnessSignature}
                                  alt="witnessSignature"
                                  className="canvas"
                                  onError={event => {
                                    // Handle potential errors if the image fails to load
                                    event.target.style.display = "none";
                                    console.error(
                                      "Failed to Signature image:",
                                      values?.witnessSignature
                                    );
                                  }}
                                />
                              ) : (
                                <Field name="witnessSignature">
                                  {({ field }) => (
                                    <>
                                      <SignatureCanvas
                                        ref={this?.signatureRefWitness}
                                        canvasProps={{
                                          className: "canvas",
                                          width: 150,
                                          height: 55,
                                        }}
                                        onEnd={() => {
                                          const data =
                                            this?.signatureRefWitness?.current?.toDataURL();
                                          if (data) {
                                            setFieldValue(
                                              `witnessSignature`,
                                              data
                                            );
                                          } else {
                                            setFieldValue(
                                              `witnessSignature`,
                                              ""
                                            );
                                          }
                                        }}
                                        {...field}
                                      />
                                      <ErrorMessage
                                        name="witnessSignature"
                                        component="div"
                                        className={`invalid-feedback text-danger ${
                                          errors.witnessSignature
                                            ? "d-block"
                                            : ""
                                        }`}
                                      />
                                    </>
                                  )}
                                </Field>
                              )}
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-secondary p-1 pl-2 font-sm"
                                onClick={() => {
                                  // Clear the image and show the signature canvas
                                  setFieldValue(
                                    `witnessSignature`,
                                    "" // Set the field value to an empty string
                                  );
                                  this?.signatureRefWitness?.current?.clear(); // Clear the signature canvas
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="gap-consent">
                          <div className="d-flex gap-3 align-items-center">
                            <div>
                              <Label className="form-label">
                                Nurse signature
                                <span className="text-danger">*</span>
                              </Label>
                              {values?.nurseSignature ? (
                                <img
                                  src={values?.nurseSignature}
                                  alt="nurseSignature"
                                  className="canvas"
                                  onError={event => {
                                    // Handle potential errors if the image fails to load
                                    event.target.style.display = "none";
                                    console.error(
                                      "Failed to Signature image:",
                                      values?.nurseSignature
                                    );
                                  }}
                                />
                              ) : (
                                <Field name="nurseSignature">
                                  {({ field }) => (
                                    <>
                                      <SignatureCanvas
                                        ref={this?.signatureRefNurse}
                                        canvasProps={{
                                          className: "canvas",
                                          width: 150,
                                          height: 55,
                                        }}
                                        onEnd={() => {
                                          const data =
                                            this?.signatureRefNurse?.current?.toDataURL();
                                          if (data) {
                                            setFieldValue(
                                              `nurseSignature`,
                                              data
                                            );
                                          } else {
                                            setFieldValue(`nurseSignature`, "");
                                          }
                                        }}
                                        {...field}
                                      />
                                      <ErrorMessage
                                        name="nurseSignature"
                                        component="div"
                                        className={`invalid-feedback text-danger ${
                                          errors.nurseSignature ? "d-block" : ""
                                        }`}
                                      />
                                    </>
                                  )}
                                </Field>
                              )}
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn btn-secondary p-1 pl-2 font-sm"
                                onClick={() => {
                                  // Clear the image and show the signature canvas
                                  setFieldValue(
                                    `nurseSignature`,
                                    "" // Set the field value to an empty string
                                  );
                                  this?.signatureRefNurse?.current?.clear(); // Clear the signature canvas
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Row className="mt-2">
                        <Col md="3">
                          <Label className="form-label mt-2">
                            Client signed date
                            <span className="text-danger">*</span>
                          </Label>

                          <Field
                            type="date"
                            name="clientDate"
                            className={
                              "form-control" +
                              (errors.clientDate && touched.clientDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter the date"
                            value={new Date(
                              values.clientDate
                            ).toLocaleDateString("en-CA", {
                              timeZone: "America/Toronto",
                            })}
                          />
                          <ErrorMessage
                            name="clientDate"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                        <Col md="3">
                          <Label className="form-label mt-2">
                            Witness signed date
                            <span className="text-danger">*</span>
                          </Label>

                          <Field
                            type="date"
                            name="witnessDate"
                            className={
                              "form-control" +
                              (errors.witnessDate && touched.witnessDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter the date"
                            value={new Date(
                              values.witnessDate
                            ).toLocaleDateString("en-CA", {
                              timeZone: "America/Toronto",
                            })}
                          />
                          <ErrorMessage
                            name="witnessDate"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                        <Col md="3">
                          <Label className="form-label mt-2">
                            Nurse signed date
                            <span className="text-danger">*</span>
                          </Label>

                          <Field
                            type="date"
                            name="nurseDate"
                            className={
                              "form-control" +
                              (errors.nurseDate && touched.nurseDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter the date"
                            value={new Date(
                              values.nurseDate
                            ).toLocaleDateString("en-CA", {
                              timeZone: "America/Toronto",
                            })}
                          />
                          <ErrorMessage
                            name="nurseDate"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button
                            type="submit"
                            color="primary"
                            disabled={!values.agreeToTerms}
                          >
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeMHReleaseConsent));
