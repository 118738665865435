import React, { Component } from "react";
import { Formik, Field, FieldArray, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Row, Col, Label, Card, CardBody } from "reactstrap";
import Select from "react-select";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import {
  formKey,
  formStatus,
  orientationSubFormKey,
  relation,
} from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { setShowPageLoader } from "store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


const validationSchema = Yup.object().shape({
  dayCampMembers: Yup.array().of(
    Yup.object().shape({
      uci: Yup.string().required(),
      name: Yup.string().required("Please enter the name"),
      age: Yup.number().required("Please enter age").min(5).max(12),
      dateOfBirth: Yup.date().required("Please enter date of birth"),
      parentName: Yup.string().required("Please enter the parent name"),
      parentPhone: Yup.string().required("Please enter the phone number"),
      parentEmail: Yup.string()
        .email("Invalid email")
        .required("Please enter email address"),
      childrenInterest: Yup.string(),
      authorizedPeople: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Please enter the name"),
          phoneNumber: Yup.string().required("Please enter the phone number"),
          relationship: Yup.string().required("Please enter relation to person"),
        })
      ),
      allergies: Yup.object().shape({
        food: Yup.string("Enter food allergy"),
        material: Yup.string("Enter material allergy"),
        other: Yup.string("Enter other allergy"),
        medicine: Yup.string("Enter medicine allergy"),
      }),
      otherInfo: Yup.string(),
      address: Yup.object().shape({
        addressLine1: Yup.string().required("Address Line 1 is required"),
        addressLine2: Yup.string(),
        province: Yup.string().required("Province is required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        postalCode: Yup.string().required("Postal code is required"),
      }),
      medicalCondition: Yup.string(),
      faithReligionConsent: Yup.string().required(
        "Please select an option"
      ),
      safetyConsent: Yup.string().required("Please select an option"),
      behaviorConsent: Yup.string().required("Please select an option"),
      consent: Yup.string().required("Please select an option"),
    })
  ),
  referenceNote: Yup.string(), // Ensure this matches the structure in initialValues
});
class DayCampProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredMembers: [],
      memberSelect: [],
      initialValues: {
        dayCampMembers: [],
        referenceNote: "",
      },
    };
  }

  componentDidMount = async () => {
    const { clientData } = this.props;
    console.log('clientData: ', clientData);
    const { memberSelect } = this.state;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.ORIENTATION &&
          note.subForm === orientationSubFormKey.DAYCAMPPROGRAM &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const generalMembers = clientData?.screeningForm?.generalInformation?.members || [];
    let memberPersonalInfo = clientData?.intakeForm?.personalInformation?.members || [];
    
    // Update memberPersonalInfo by matching uci with generalMembers
    if (generalMembers.length !== memberPersonalInfo.length) {
      memberPersonalInfo = memberPersonalInfo.filter(member => 
        generalMembers.some(genMember => genMember.uci === member.uci)
      );
    }
    
    const memberAddress =
      clientData?.intakeForm?.personalInformation?.mailAddress;
  
    const memberMedicalInfo =
      clientData?.intakeForm?.healthInformation?.healthDetails;
    const allergiesInfo =
      clientData?.intakeForm?.healthInformation?.dietAndMedicalRestrictions;
    const filteredMembers = this.filterMembersByAge(
      memberPersonalInfo,
      memberAddress,
      memberMedicalInfo,
      allergiesInfo
    );
    console.log('filteredMembers: ', filteredMembers);
   
    this.setState({ filteredMembers: filteredMembers });
    const dayCampProgram = clientData?.orientation?.dayCampProgram;

    const initialValues = {
      dayCampMembers: dayCampProgram?.members
        ? dayCampProgram?.members?.map(member => ({
            uci: member?.uci,
            name: member?.name,
            age: member?.age,
            dateOfBirth: new Date(member.dateOfBirth)
              .toISOString()
              .split("T")[0],
            parentName: member?.parentName,
            parentEmail: member?.parentEmail,
            parentPhone: member?.parentPhone,
            address: {
              addressLine1: member.address.addressLine1,
              addressLine2: member.address.addressLine2,
              province: member.address.province,
              city: member.address.city,
              country: member.address.country,
              postalCode: member.address.postalCode,
            },
            allergies: {
              food: member.allergies.food,
              material: member.allergies.material,
              other: member.allergies.other,
              medicine: member.allergies.medicine,
            },
            medicalCondition: member.medicalCondition,
            authorizedPeople:member?.authorizedPeople ? member?.authorizedPeople?.map(person => ({
              name: person.name,
              phoneNumber: person.phoneNumber,
              relationship: person.relationship,
            })) : [],
            faithReligionConsent: 
               member?.faithReligionConsent
              || "",
            safetyConsent: member?.safetyConsent || "",
            behaviorConsent: member?.behaviorConsent || "",
            consent: member?.consent || "",
            otherInfo: member?.otherInfo || "",
            childrenInterest: member?.childrenInterest || "",
          }))
        : [],
      referenceNote: refNote || "",
    };
    this.setState({
      filteredMembers: filteredMembers,
      initialValues: initialValues,
    });
  };

  componentDidUpdate(prevProps, prevState) {
   
    if (
      prevState.filteredMembers !== this.state.filteredMembers ||
      prevState.memberSelect !== this.state.memberSelect
    ) {
      const initialValues = {
        dayCampMembers:
          prevProps?.clientData?.orientation?.dayCampProgram?.type?.formStatus ===
          formStatus.COMPLETED
            ? prevProps.clientData.orientation.dayCampProgram.members
            : this.state.memberSelect.length > 0
            ? this.state.memberSelect
            : [],
        referenceNote: this.state.referenceNote,
      };
      this.setState({ initialValues });
    }
  }
  calculateAge = dateOfBirth => {
    const birthDate = new Date(dateOfBirth);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  filterMembersByAge = (
    members,
    memberAddress,
    memberMedicalInfo,
    allergiesInfo
  ) => {
    return members?.filter(member => {
        const age = this.calculateAge(member?.dateOfBirth);
        return age >= 5 && age <= 12;
      })
      .map(member => {
        const matchedMedicalInfo = memberMedicalInfo.find(
          info => info.uci === member.uci
        );
        const matchedAllergiesInfo = allergiesInfo.find(
          info => info.uci === member.uci
        );
        return {
          uci: member.uci,
          name: `${member.firstName} ${member.middleName} ${member.lastName}`,
          age: this.calculateAge(member.dateOfBirth),
          dateOfBirth: new Date(member.dateOfBirth).toISOString().split("T")[0],
          parentEmail: member.email,
          parentPhone: member.phoneNumber,
          parentName:
            member.parentName === "Other"
              ? member.parentNameOtherText
              : member.parentName,
          address: {
            addressLine1: memberAddress?.addressLine1,
            addressLine2: memberAddress?.addressLine2,
            province: memberAddress?.province,
            city: memberAddress?.city,
            country: memberAddress?.country,
            postalCode: memberAddress?.postalCode,
          },
          childrenInterest: "",
          medicalCondition: "",
          faithReligionConsent: "",
          safetyConsent: "",
          behaviorConsent: "",
          consent: "",
          authorizedPeople: [],
          otherInfo: "",
          medicalCondition: matchedMedicalInfo
            ? matchedMedicalInfo.medConditionText
            : "",
          allergies: matchedAllergiesInfo
            ? {
                food: matchedAllergiesInfo.foodAllergies || "",
                material: matchedAllergiesInfo.materialsAllergies || "",
                medicine: matchedAllergiesInfo.medicineAllergies || "",
                other: matchedAllergiesInfo.otherAllergies || "",
              }
            : { food: "", material: "", medicine: "", other: "" },
        };
      });
  };

  handleMemberSelect = (
    selectedOption,
    setFieldValue,
    values,
    initialValues
  ) => {
 
    const { filteredMembers } = this.state;
    const selectedMembers = selectedOption.map(option => {
      return filteredMembers.find(member => member.uci === option.value);
    });
 
    // Filter out members that are no longer selected
    const updatedMembers = values.dayCampMembers.filter(member =>
      selectedMembers.find(selected => selected.uci === member.uci)
    );

    // Add new selected members
    selectedMembers.forEach(member => {
      if (!updatedMembers.find(m => m.uci === member.uci)) {
        updatedMembers.push(member);
      }
    });
   
    this.setState({ memberSelect: updatedMembers }, () => {
      // Update the form values
      setFieldValue("dayCampMembers", updatedMembers);
    });

    if (updatedMembers.length === 0) {
      setFieldValue("dayCampMembers", []);
    }
  };

  handleAddOrientationDayCampData = async dayCampInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/orientation/dayCampProgram/${clientData?.id}`,
        dayCampInfo
      );
      if (response?.status) {
        toast.success(
          response?.message ||
            "Day camp program information stored successfully!"
        );
        handleGetClient();
        toggle("4");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      dayCampProgramInfo: {
        members: values.dayCampMembers.map(member => ({
          uci: member.uci,
          name: member.name,
          age: member.age,
          dateOfBirth: member.dateOfBirth,
          parentName: member.parentName,
          parentEmail: member.parentEmail,
          parentPhone: member.parentPhone,
          address: {
            addressLine1: member.address.addressLine1,
            addressLine2: member.address.addressLine2,
            province: member.address.province,
            city: member.address.city,
            country: member.address.country,
            postalCode: member.address.postalCode,
          },
          authorizedPeople: member.authorizedPeople.map(person => ({
            name: person.name,
            phoneNumber: person.phoneNumber,
            relationship: person.relationship,
          })),
          allergies: {
            food: member.allergies.food,
            material: member.allergies.material,
            other: member.allergies.other,
            medicine: member.allergies.medicine,
          },
          otherInfo: member.otherInfo,
          childrenInterest: member.childrenInterest,
          medicalCondition: member.medicalCondition,
          faithReligionConsent: member.faithReligionConsent,
          safetyConsent: member.safetyConsent,
          behaviorConsent: member.behaviorConsent,
          consent: member.consent,
        })),
      },
      referenceNotes: values.referenceNote,
    };
    this.handleAddOrientationDayCampData(data);
  };

  mergeMemberData = (orientationDataMembers, filteredMembers) => {
    const mergedMembers = filteredMembers.map(filterMember => {
      const dayCampMember = orientationDataMembers.find(
        member => member.uci === filterMember.uci
      );
   
      return {
        uci: filterMember.uci,
        name: filterMember.name,
        age: filterMember.age,
        dateOfBirth: filterMember.dateOfBirth,
        parentName: filterMember.parentName,
        parentEmail: filterMember.parentEmail,
        parentPhone: filterMember.parentPhone,
        address: {
          addressLine1: filterMember.address.addressLine1,
          addressLine2: filterMember.address.addressLine2,
          province: filterMember.address.province,
          city: filterMember.address.city,
          country: filterMember.address.country,
          postalCode: filterMember.address.postalCode,
        },
        allergies: {
          food: filterMember.allergies.food,
          material: filterMember.allergies.material,
          other: filterMember.allergies.other,
          medicine: filterMember.allergies.medicine,
        },
        medicalCondition: filterMember.medicalCondition,
        authorizedPeople: dayCampMember?.authorizedPeople?.map(person => ({
          name: person.name,
          phoneNumber: person.phoneNumber,
          relationship: person.relationship,
        })),
        faithReligionConsent:
          dayCampMember?.faithReligionConsent.toString() || "",
        safetyConsent: dayCampMember?.safetyConsent.toString() || "",
        behaviorConsent: dayCampMember?.behaviorConsent || "",
        consent: dayCampMember?.consent.toString() || "",
        otherInfo: dayCampMember?.otherInfo.toString() || "",
        childrenInterest: dayCampMember?.childrenInterest || "",
      };
    });

    return mergedMembers;
  };

  render() {
    const { filteredMembers, initialValues, memberSelect } = this.state;
   
    const { clientData } = this.props;
    const dayCampProgram = clientData?.orientation?.dayCampProgram;
    const defaultValues = dayCampProgram?.members.map(member => ({
      label: member.name,
      value: member.uci,
    }));

    return (
      <React.Fragment>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h3>Personal Information</h3>
                        </Col>
                        <Col md="9">
                          <Row className="mb-3">
                            <Col md="4">
                              <Label>Select Members</Label>
                              <Select
                                id="memberSelect"
                                name="memberSelect"
                                options={filteredMembers?.map(member => ({
                                  value: member.uci,
                                  label: member.name,
                                }))}
                                defaultValue={defaultValues}
                                onChange={selectedOption =>
                                  this.handleMemberSelect(
                                    selectedOption,
                                    setFieldValue,
                                    values
                                  )
                                }
                                isMulti
                              />
                            </Col>
                          </Row>
                          <FieldArray name="dayCampMembers">
                            {({}) => (
                              <div>
                          
                                {values?.dayCampMembers?.length > 0 &&
                                  values?.dayCampMembers?.map(
                                    (member, index) => (
                                      <div key={index}>
                                        <h4>{member.name}</h4>
                                        <Row className="mb-3">
                                          <Col md={4}>
                                            <Label
                                              for={`dayCampMembers.${index}.name`}
                                            >
                                              Name{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.name`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .name
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter your name"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.name`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col md={4}>
                                            <Label
                                              for={`dayCampMembers.${index}.age`}
                                            >
                                              Age{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.age`}
                                              type="number"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index].age
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter your age"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.age`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col md={4}>
                                            <Label
                                              for={`dayCampMembers.${index}.dateOfBirth`}
                                            >
                                              Date of birth{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.dateOfBirth`}
                                              type="date"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .dateOfBirth
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="enter your date of birth"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.dateOfBirth`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col md={4}>
                                            <Label
                                              for={`dayCampMembers.${index}.parentName`}
                                            >
                                              Parent name{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.parentName`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .parentName
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter parent name"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.parentName`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>

                                          <Col md={4}>
                                            <Label
                                              for={`dayCampMembers.${index}.parentPhone`}
                                            >
                                              Parent phone number{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <PhoneInput
                                              containerStyle={{
                                                marginTop: "10px",
                                              }}
                                              inputStyle={{
                                                width: "600px",
                                                maxHeight: "100%",
                                              }}
                                              disableDialCodePrefill="true"
                                              value={
                                                values.dayCampMembers[index]
                                                  .parentPhone
                                              }
                                              onChange={parentPhone => {
                                                setFieldValue(
                                                  `dayCampMembers.${index}.parentPhone`,
                                                  parentPhone
                                                );
                                              }}
                                              className={
                                                errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .parentPhone
                                                  ? " is-invalid"
                                                  : ""
                                              }
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.parentPhone`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col md={4}>
                                            <Label
                                              for={`dayCampMembers.${index}.parentEmail`}
                                            >
                                              Parent email{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.parentEmail`}
                                              type="email"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .parentEmail
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter parent email"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.parentEmail`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col md="6">
                                            <Label
                                              for={`dayCampMembers.${index}.address.addressLine1`}
                                            >
                                              Address Line 1{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              id={`dayCampMembers.${index}.address.addressLine1`}
                                              name={`dayCampMembers.${index}.address.addressLine1`}
                                              as="textarea"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .address &&
                                                errors.dayCampMembers[index]
                                                  .address.addressLine1
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter address line 1"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.address.addressLine1`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col md="6">
                                            <Label
                                              for={`dayCampMembers.${index}.address.addressLine2`}
                                            >
                                              Address Line 2{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              id={`dayCampMembers.${index}.address.addressLine2`}
                                              name={`dayCampMembers.${index}.address.addressLine2`}
                                              as="textarea"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .address &&
                                                errors.dayCampMembers[index]
                                                  .address.addressLine2
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter address line 2"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.address.addressLine2`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col md="6">
                                            <Label
                                              for={`dayCampMembers.${index}.address.province`}
                                            >
                                              Province{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              id={`dayCampMembers.${index}.address.province`}
                                              name={`dayCampMembers.${index}.address.province`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .address &&
                                                errors.dayCampMembers[index]
                                                  .address.province
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter province"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.address.province`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col md="6">
                                            <Label
                                              for={`dayCampMembers.${index}.address.city`}
                                            >
                                              City{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              id={`dayCampMembers.${index}.address.city`}
                                              name={`dayCampMembers.${index}.address.city`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .address &&
                                                errors.dayCampMembers[index]
                                                  .address.city
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter city"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.address.city`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col md="6">
                                            <Label
                                              for={`dayCampMembers.${index}.address.country`}
                                            >
                                              Country{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              id={`dayCampMembers.${index}.address.country`}
                                              name={`dayCampMembers.${index}.address.country`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .address &&
                                                errors.dayCampMembers[index]
                                                  .address.country
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter country"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.address.country`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col md="6">
                                            <Label
                                              for={`dayCampMembers.${index}.address.postalCode`}
                                            >
                                              Postal Code{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              id={`dayCampMembers.${index}.address.postalCode`}
                                              name={`dayCampMembers.${index}.address.postalCode`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .address &&
                                                errors.dayCampMembers[index]
                                                  .address.postalCode
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter postal code"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.address.postalCode`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Label>
                                            People authorized to pick up your
                                            child from day camp/after school
                                          </Label>
                                          <FieldArray
                                            name={`dayCampMembers.${index}.authorizedPeople`}
                                          >
                                            {({ push, remove  }) => (
                                              <div>
                                                { values?.dayCampMembers[
                                                  index
                                                ]?.authorizedPeople?.map(
                                                  (person, personIndex) => (
                                                    <div
                                                      key={personIndex}
                                                      className="mb-3"
                                                    >
                                                      <Row className="mb-3">
                                                        <Col md={4}>
                                                          <Label
                                                            for={`dayCampMembers.${index}.authorizedPeople.${personIndex}.name`}
                                                          >
                                                            Name{" "}
                                          <span className="text-danger">*</span>
                                                          </Label>
                                                          <Field
                                                            name={`dayCampMembers.${index}.authorizedPeople.${personIndex}.name`}
                                                            type="text"
                                                            className={`
                                                              form-control${
                                                              errors?.dayCampMembers?.[index].authorizedPeople?.[personIndex]?.name && touched?.dayCampMembers?.[index].authorizedPeople?.[personIndex]?.name
                                                                ? " is-invalid"
                                                                : ""
                                                            }`}
                                                            placeholder="Enter person name"
                                                           
                                                          />
                                                          <ErrorMessage
                                                            name={`dayCampMembers.${index}.authorizedPeople.${personIndex}.name`}
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </Col>
                                                        <Col md="4">
                                                          <Label
                                                            for={`dayCampMembers.${index}.authorizedPeople.${personIndex}.phoneNumber`}
                                                          >
                                                            Phone number{" "}
                                          <span className="text-danger">*</span>
                                                          </Label>
                                                          <PhoneInput
                                                            containerStyle={{
                                                              marginTop: "10px",
                                                            }}
                                                            inputStyle={{
                                                              width: "600px",
                                                              maxHeight: "100%",
                                                            }}
                                                            disableDialCodePrefill="true"
                                                            value={
                                                              values
                                                                ?.dayCampMembers[
                                                                index
                                                              ]
                                                                ?.authorizedPeople[
                                                                  personIndex
                                                              ]?.phoneNumber
                                                            }
                                                            onChange={phone =>
                                                              setFieldValue(
                                                                `dayCampMembers.${index}.authorizedPeople.${personIndex}.phoneNumber`,
                                                                phone
                                                              )
                                                            }
                                                            name={`dayCampMembers.${index}.authorizedPeople.${personIndex}.phoneNumber`}
                                                            className={`
                                                              ${
                                                              errors?.dayCampMembers?.[index].authorizedPeople?.[personIndex]?.phoneNumber && touched?.dayCampMembers?.[index].authorizedPeople?.[personIndex]?.phoneNumber
                                                                ? " is-invalid"
                                                                : ""
                                                            }`}
                                                          />
                                                          <ErrorMessage
                                                            name={`dayCampMembers.${index}.authorizedPeople.${personIndex}.phoneNumber`}
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </Col>
                                                        <Col md="4">
                                                          <Label
                                                            for={`dayCampMembers.${index}.authorizedPeople.${personIndex}.relationship`}
                                                          >
                                                            Relationship{" "}
                                          <span className="text-danger">*</span>
                                                          </Label>
                                                          <Select
                                                            name={`dayCampMembers.${index}.authorizedPeople.${personIndex}.relationship`}
                                                            options={relation}
                                                            className={`
                                                            ${
                                                            errors?.dayCampMembers?.[index].authorizedPeople?.[personIndex]?.relationship && touched?.dayCampMembers?.[index].authorizedPeople?.[personIndex]?.relationship
                                                              ? " is-invalid"
                                                              : ""
                                                          }`}
                                                            classNamePrefix="select"
                                                            value={
                                                              relation.find(
                                                                option =>
                                                                  option.value ===
                                                                  values
                                                                    .dayCampMembers[
                                                                    index
                                                                  ]
                                                                    .authorizedPeople[
                                                                    personIndex
                                                                  ].relationship
                                                              ) || ""
                                                            }
                                                            onChange={selectedOption =>
                                                              setFieldValue(
                                                                `dayCampMembers.${index}.authorizedPeople.${personIndex}.relationship`,
                                                                selectedOption.value
                                                              )
                                                            }
                                                            placeholder="Enter relation with member"
                                                          />
                                                          <ErrorMessage
                                                            name={`dayCampMembers.${index}.authorizedPeople.${personIndex}.relationship`}
                                                            component="div"
                                                            className="invalid-feedback"
                                                          />
                                                        </Col>
                                                      </Row>
                                                     
                                                      <Button
                                                        color="danger"
                                                        type="button"
                                                        onClick={() =>
                                                          remove(personIndex)
                                                        }
                                                      >
                                                        <i className="mdi mdi-trash-can me-1"></i>
                                                        Remove
                                                      </Button>
                                                      <hr></hr>
                                                    </div>
                                                  )
                                                )}
                                                
                                                <Button
                                                  color="primary"
                                                  type="button"
                                                  onClick={() =>
                                                    push({
                                                      name: "",
                                                      phoneNumber: "",
                                                      relationship: "",
                                                    })
                                                  }
                                                >
                                                  <i className="mdi mdi-account-plus me-1"></i>
                                                  Add authorized person
                                                </Button>
                                              </div>
                                            )}
                                          </FieldArray>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col md={6}>
                                            <Label
                                              for={`dayCampMembers.${index}.childrenInterest`}
                                            >
                                              Children interest
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.childrenInterest`}
                                              as="textarea"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .childrenInterest
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter any children intrests"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.childrenInterest`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <fieldset
                                          className={
                                            errors.dayCampMembers &&
                                            errors.dayCampMembers[index] &&
                                            errors.dayCampMembers[index]
                                              .behaviorConsent
                                              ? " is-invalid"
                                              : ""
                                          }
                                        >
                                          <Row className="mb-3">
                                            <Col md={12}>
                                              <Label>
                                                Matthew House and our partners
                                                do not tolerate inappropriate
                                                behaviour, disrespectful, or
                                                violent behaviourand will ask
                                                parent to pick up their child
                                                should their behaviour become
                                                unsafe for others{" "}
                                          <span className="text-danger">*</span>
                                              </Label>
                                              <div
                                                role="group"
                                                aria-labelledby={`dayCampMembers.${index}.behaviorConsent`} 
                                              >
                                                <Label className="d-flex align-items-center fw-normal">
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.behaviorConsent`}
                                                    value="true"
                                                    className="me-1"
                                                    onClick={() => setFieldValue(`dayCampMembers.${index}.behaviorConsent`, "true")}
                                                  />{" "}
                                                  I agree
                                                </Label>{" "}
                                                <Label className="d-flex align-items-center fw-normal">
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.behaviorConsent`}
                                                    value="false"
                                                    className="me-1"
                                                    onClick={() => setFieldValue(`dayCampMembers.${index}.behaviorConsent`, "false")}
                                                  />{" "}
                                                  I disagree
                                                </Label>
                                              </div>
                                            </Col>
                                          </Row>
                                        </fieldset>
                                        <ErrorMessage
                                          name={`dayCampMembers.${index}.behaviorConsent`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                        <fieldset
                                          className={
                                            errors.dayCampMembers &&
                                            errors.dayCampMembers[index] &&
                                            errors.dayCampMembers[index]
                                              .faithReligionConsent
                                              ? " is-invalid"
                                              : ""
                                          }
                                        >
                                          <Row className="mb-3">
                                            <Col md={12}>
                                              <Label>
                                                I understand that Monumental Day
                                                Campis beingpresented in
                                                partnership with Parkwood Gospel
                                                Church and that faith and
                                                religion may be discussed in the
                                                program.{" "}
                                          <span className="text-danger">*</span>
                                              </Label>
                                              <div
                                                role="group"
                                                aria-labelledby={`dayCampMembers.${index}.faithReligionConsent`}
                                              >
                                                <Label className="d-flex align-items-center fw-normal" check>
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.faithReligionConsent`}
                                                    value="true"
                                                    className="me-1"
                                                  />{" "}
                                                  I understand
                                                </Label>{" "}
                                                <Label className="d-flex align-items-center fw-normal" check>
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.faithReligionConsent`}
                                                    value="false"
                                                    className="me-1"
                                                  />{" "}
                                                  I do not agree
                                                </Label>
                                              </div>
                                            </Col>
                                          </Row>
                                        </fieldset>
                                        <ErrorMessage
                                          name={`dayCampMembers.${index}.faithReligionConsent`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                        <fieldset
                                          className={
                                            errors.dayCampMembers &&
                                            errors.dayCampMembers[index] &&
                                            errors.dayCampMembers[index]
                                              .safetyConsent
                                              ? " is-invalid"
                                              : ""
                                          }
                                        >
                                          <Row className="mb-3">
                                            <Col md={12}>
                                              <Label>
                                                I understand that my child will
                                                be supervised and reasonable
                                                safety precaution taken.Further
                                                that if my child is injuried
                                                Matthew House will seek medical
                                                care. However, i release Matthew
                                                House, theirprogram partners,
                                                staff, students, and volnteers
                                                from any liability arising from
                                                my child&apos;s participation in
                                                the program{" "}
                                          <span className="text-danger">*</span>
                                              </Label>
                                              <div
                                                role="group"
                                                aria-labelledby={`dayCampMembers.${index}.safetyConsent`}
                                              >
                                                <Label className="d-flex align-items-center fw-normal" check>
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.safetyConsent`}
                                                    value="true"
                                                    className="me-1"
                                                  />{" "}
                                                  I agree
                                                </Label>{" "}
                                                <Label className="d-flex align-items-center fw-normal" check>
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.safetyConsent`}
                                                    value="false"
                                                    className="me-1"
                                                  />{" "}
                                                  I disagree
                                                </Label>
                                              </div>
                                            </Col>
                                          </Row>
                                        </fieldset>
                                        <ErrorMessage
                                          name={`dayCampMembers.${index}.safetyConsent`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                        <fieldset
                                          className={
                                            errors.dayCampMembers &&
                                            errors.dayCampMembers[index] &&
                                            errors.dayCampMembers[index].consent
                                              ? " is-invalid"
                                              : ""
                                          }
                                        >
                                          <Row className="mb-3">
                                            <Col md={12}>
                                              <Label>
                                                I give permission for Matthew
                                                House and its program partners
                                                to take photos and video of my
                                                child and to post such images in
                                                print or online{" "}
                                          <span className="text-danger">*</span>
                                              </Label>
                                              <div
                                                role="group"
                                                aria-labelledby={`dayCampMembers.${index}.consent`}
                                              >
                                                <Label className="d-flex align-items-center fw-normal" check>
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.consent`}
                                                    value="true"
                                                    className="me-1"
                                                    onClick={() => setFieldValue(`dayCampMembers.${index}.consent`, "true")}
                                                    
                                                  />{" "}
                                                  Yes
                                                </Label>{" "}
                                                <Label className="d-flex align-items-center fw-normal" check>
                                                  <Field
                                                    type="radio"
                                                    name={`dayCampMembers.${index}.consent`}
                                                    value="false"
                                                    className="me-1"
                                                    onClick={() => setFieldValue(`dayCampMembers.${index}.consent`, "false")}
                                                  />{" "}
                                                  No
                                                </Label>
                                              </div>
                                            </Col>
                                          </Row>
                                        </fieldset>
                                        <ErrorMessage
                                          name={`dayCampMembers.${index}.consent`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                        <Row className="mb-3">
                                          <Col md="6">
                                            <Label
                                              for={`dayCampMembers.${index}.medicalCondition`}
                                              className="form-label"
                                            >
                                              Medical Condition{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              type="text"
                                              name={`dayCampMembers.${index}.medicalCondition`}
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .medicalCondition
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter any medical condition"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.medicalCondition`}
                                              component="div"
                                              className="addressLine2"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col>
                                            <Label
                                              for={`dayCampMembers.${index}.allergies.food`}
                                            >
                                              Food Allergy{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.allergies.food`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .allergies &&
                                                errors.dayCampMembers[index]
                                                  .allergies.food
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter any food allergies"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.allergies.food`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col>
                                            <Label
                                              for={`dayCampMembers.${index}.allergies.medicine`}
                                            >
                                              Medicine Allergy{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.allergies.medicine`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .allergies &&
                                                errors.dayCampMembers[index]
                                                  .allergies.medicine
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter any medicine allergies"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.allergies.medicine`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col>
                                            <Label
                                              for={`dayCampMembers.${index}.allergies.material`}
                                            >
                                              Material Allergy{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.allergies.material`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .allergies &&
                                                errors.dayCampMembers[index]
                                                  .allergies.material
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter any material allergies"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.allergies.material`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col>
                                            <Label
                                              for={`dayCampMembers.${index}.allergies.other`}
                                            >
                                              Other Allergy{" "}
                                          <span className="text-danger">*</span>
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.allergies.other`}
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .allergies &&
                                                errors.dayCampMembers[index]
                                                  .allergies.other
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter any material allergies"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.allergies.other`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col md={12}>
                                            <Label
                                              for={`dayCampMembers.${index}.otherInfo`}
                                            >
                                              Any other info
                                            </Label>
                                            <Field
                                              name={`dayCampMembers.${index}.otherInfo`}
                                              as="textarea"
                                              className={
                                                "form-control" +
                                                (errors.dayCampMembers &&
                                                errors.dayCampMembers[index] &&
                                                errors.dayCampMembers[index]
                                                  .otherInfo
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Enter any other information"
                                            />
                                            <ErrorMessage
                                              name={`dayCampMembers.${index}.otherInfo`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </Row>
                                        <hr></hr>
                                      </div>
                                    )
                                  )}
                              </div>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="9">
                          <div className="mb-3">
                            <Field
                              id="referenceNote"
                              name="referenceNote"
                              as="textarea"
                              style={{ resize: "none" }}
                              className={
                                "form-control" +
                                (errors?.referenceNote && touched?.referenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              cols={100}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referenceNote"
                              component="div"
                              className="addressLine2"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="text-end">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DayCampProgram));
