import { ADD_NOTIFICATION , SET_SELECTED_NOTIFICATION , UPDATE_NOTIFICATION , SET_ACTIVE_TAB, UPDATE_UNREAD_COUNT} from "./actionTypes";


export const addNotification = data => ({
    type: ADD_NOTIFICATION,
    payload: data
  });
  
  export const setSelectedNotification = data => ({
    type: SET_SELECTED_NOTIFICATION,
    payload: data
  });
  
  export const updateNotification = data => ({
    type: UPDATE_NOTIFICATION,
    payload: data
  });
  
  export const setActiveTab = index => ({
    type: SET_ACTIVE_TAB,
    payload: index
  });

  export const updateUnreadCount = count => ({
    type: UPDATE_UNREAD_COUNT,
    payload: count
  })